import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';

import { createOutSourceCenter } from '../../Store/ActionCreators';

import {
  Grid,
  Container,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  Checkbox,
  Button,
} from '@material-ui/core';

import classes from '../../App.module.css';

class AddOutsourceCentres extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      interface_type: 'Unidirectional',
      center_relation: 'Intra Center',
      outsourcecenter_name: '',
      center_type: '',
      center_sharing_key: '',
      is_share_report_automatically: '0',
      is_report_submit: '0'
    }
    this.reset = this.state;
  }

  handleCheckBox = (e) => {
    this.setState({ [e.target.name]: e.target.checked === true ? '1' : '0' })
  }

  handleRadioButton = (e) => {
    if (e.target !== undefined && e.target.value !== undefined) {
      this.setState({ [e.target.name]: e.target.value })
    } else this.setState({ [e.target.name]: '' })
  }

  handleChange = (e) => {
    console.log(e.target.name)
    this.setState({ [e.target.name]: e.target.value })
  }

  submitForm = async() =>{
    await this.props.createOutSourceCenter(this.state);
    if (this.props.createOutSourceCenterSuccess) {
      this.setState(this.reset)
    }
  }

  render() {
    const { interface_type, center_relation, outsourcecenter_name, center_type, center_sharing_key, is_share_report_automatically, is_report_submit} = this.state;
    return (
      <Container maxWidth='md'>
        <Grid container direction='row' alignItems='center' spacing={2}>
          <Grid item xs={3}>
            <Grid container justify='flex-end'>
              Outsource Centre
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              variant='outlined'
              margin='dense'
              onChange={this.handleChange}
              value={outsourcecenter_name}
              name={'outsourcecenter_name'}
              placeholder='Enter outsource centre name'
            />
          </Grid>

          <Grid item xs={3}>
            <Grid container justify='flex-end'>
              Centre Type
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              variant='outlined'
              margin='dense'
              placeholder='Enter centre type'
              onChange={this.handleChange}
              value={center_type}
              name={'center_type'}
            />
          </Grid>

          <Grid item xs={3}>
            <Grid container justify='flex-end'>
              Centre Sharing Key
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              variant='outlined'
              margin='dense'
              placeholder='Enter center sharing key'
              onChange={this.handleChange}
              value={center_sharing_key}
              name={'center_sharing_key'}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={3}>
                <Grid container justify='flex-end' className={classes.headerContainer}>
                  Centre Interfacing Type
                </Grid>
              </Grid>
              <Grid item xs={9}>
                <RadioGroup
                  aria-label="interfacingType"
                  name="interface_type"
                  value={interface_type}
                  onChange={this.handleRadioButton}>
                  <FormControlLabel
                    value="Unidirectional"
                    checked={interface_type === 'Unidirectional'}
                    control={<Radio color="primary" />}
                    label="Unidirectional"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="Bidirectional"
                    checked={interface_type === 'Bidirectional'}
                    control={<Radio color="primary" />}
                    label="Bidirectional"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="I don't know"
                    checked={interface_type === "I don't know"}
                    control={<Radio color="primary" />}
                    label="I don't know"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={3}>
                <Grid container justify='flex-end' className={classes.headerContainer}>
                  Relation
                </Grid>
              </Grid>

              <Grid item xs={9}>
                <RadioGroup
                  aria-label="relation"
                  name="center_relation"
                  value={center_relation}
                  onChange={this.handleRadioButton}>
                  <FormControlLabel
                    value="Intra Center"
                    checked={center_relation === 'Intra Center'}
                    control={<Radio color="primary" />}
                    label="Intra Center"
                    labelPlacement="end"
                  />
                  <Typography variant='body2' className={classes.radioHelperText}>
                    Intra is when you are outsourcing it your own centre. Patient Contact Details, Organisation
                    Details and Referral Details will be shared.
                </Typography>
                  <FormControlLabel
                    value="Inter Center"
                    checked={center_relation === 'Inter Center'}
                    control={<Radio color="primary" />}
                    label="Inter Center"
                    labelPlacement="end"
                  />
                  <Typography variant='body2' className={classes.radioHelperText}>
                    Intra is when you are not outsourcing it your own centre. Patient Contact Details, Organisation
                    Details and Referral Details will not be shared.
                </Typography>
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={3}>
          </Grid>
          <Grid item xs={9}>
            <FormControlLabel
              control={<Checkbox color='primary' />}
              label='Share report automatically'
              labelPlacement='end'
              value={is_share_report_automatically}
              name={'is_share_report_automatically'}
              checked={true ? is_share_report_automatically === '1' : false}
              onClick={this.handleCheckBox}
              classes={{
                label: classes.label
              }}
            />
            <FormControlLabel
              control={<Checkbox color='primary' />}
              label='Auto report submit'
              labelPlacement='end'
              value={is_report_submit}
              name={'is_report_submit'}
              checked={true ? is_report_submit === '1' : false}
              onClick={this.handleCheckBox}
              classes={{
                label: classes.label
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container justify='center'>
              <Grid item>
                <Button
                  type="submit"
                  color="primary"
                  size="medium"
                  variant="contained"
                  className={clsx(classes.buttonText, classes.submitButton)}
                  disabled={!outsourcecenter_name || !center_type || !center_sharing_key}
                  onClick={this.submitForm}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Container>
    )
  }
}


const mapStateToProps = state => ({
  createOutSourceCenterSuccess: state.login.createOutSourceCenterSuccess,
})

export default connect(mapStateToProps, {
  createOutSourceCenter,
})(AddOutsourceCentres);