import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  Divider,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
} from '@material-ui/core';

import classes from '../../App.module.css';

class UpdatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
    }
  }

  handleSelectChange = (e) => {
    this.setState(oldValues => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  }

  render() {
    const { user } = this.state;
    return (
      <Container maxWidth='lg'>
        <Typography>
          <Grid container justify='center' spacing={2}>

            <Grid item xs={12}>
              <Typography variant='h6'>
                Update password for users
              </Typography>
            </Grid>

            <Grid item xs={12}>
              Settings for mapping accession types or sample types with tests.
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Grid container justify='center'>
                <Grid item xs={7}>
                  <FormControl variant='outlined' margin='dense' fullWidth>
                    <InputLabel id='demo-simple-select-outlined-label'>Select User</InputLabel>
                    <Select
                      id='demo-simple-select-outlined-label'
                      fullWidth
                      name='user'
                      value={user}
                      onChange={this.handleSelectChange}
                    >
                      <MenuItem value={1}>user1</MenuItem>
                      <MenuItem value={2}>user2</MenuItem>
                      <MenuItem value={3}>user3</MenuItem>
                      <MenuItem value={4}>user4</MenuItem>
                      <MenuItem value={5}>user5</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={7}>
                  <TextField
                    type='password'
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    label='Enter new password'
                  />
                  <Typography variant='body2'>* Password should contain at least one small letter, one capital letter,
                    one digit,one special character & greater than 8 characters length
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <TextField
                    type='password'
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    label='Confirm password'
                  />
                  <Typography variant='body2'>* Password should contain at least one small letter, one capital letter,
                    one digit,one special character & greater than 8 characters length
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction='row' justify='center' spacing={1}>
                <Grid item xs={2}>
                  <Button
                    variant='contained'
                    color='primary'
                    className={classes.buttonAccession}
                    fullWidth
                  >
                    Update Password
                  </Button>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Typography>
      </Container>
    )
  }
}

export default UpdatePassword;