import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';

import {
  Container,
  Grid,
  Tabs,
  Tab,
  Typography,
  TextField,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Link,
  Button,
  Checkbox,
  MenuItem,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CircularProgress,
  Snackbar,
} from '@material-ui/core';

import { ArrowForwardIos, Close, Search } from '@material-ui/icons';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  getTestPrices, searchBillByBillId, addTestToBill, getBillByPatientId, searchBillByPatientName, searchBillByAccession,
  updateBillsPayment, getPaymentModes,
} from '../../../Store/ActionCreators';


import classes from '../../../App.module.css';

var moment = require('moment');
class BillSettlement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      bill_history_type: 1,
      bill_id: null,
      openBillList: false,
      openBillAccessionList: false,
      patient_list: [],
      date: Date(),
      payment_mode: '',
      comments: '',
      bills_settlement_amount: 0,
      selected: [],
      selectedList: [],
      duplicateRowData: [],
      total: 0,
      dueForToast: 0,
      openDiscountErrorToast: false,
      openToast: false,
      paymentModeError: false,
      searchPatientData: '',
      searchAccessionID: '',
    }
    this.reset = this.state;
    this.props.getPaymentModes();
  }

  handleTabEvent = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({ bill_history_type: newValue },
      () => {
        if (this.state.bill_history_type === 0) {
          window.location.replace('/bill-settlements')
        } else if (this.state.bill_history_type === 2) {
          window.location.replace('/addtesttobill')
        }
      }
    )
  }

  handleSearchBillId = async () => {
    const { bill_id } = this.state
    await this.props.searchBillByBillId({
      search: bill_id,
    })
    if(this.state.rows.length !== 0) {
      this.setState(this.reset)
    }
    this.setState({ rows: this.props.searchBillList, duplicateRowData: this.props.searchBillList }
      , () => {
      if (this.state.rows.length !== 0) {
        this.props.getTestPrices(this.state.rows[0].patientdetails.organisationdetails !== null ? this.state.patient_list[0].patientdetails.organisationdetails.id : null);
      }}
    )
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleCheckBox = (e) => {
    this.setState({ [e.target.name]: e.target.checked === true ? '1' : '0' })
  }

  handleSearchPatient = async (name, value) => {
    if (!value) {
      this.setState({ [name]: value })
    } else if (!isNaN(value)) {
      await this.props.getBillByPatientId({ patientref: value });
      this.setState({ patient_list: this.props.patientWiseSearchList },
        () => {
          if (this.state.patient_list.length !== 0) {
            this.props.getTestPrices(this.state.patient_list[0].patientdetails.organisationdetails !== null ? this.state.patient_list[0].patientdetails.organisationdetails.id : null);
          }
        }
      )
    } else {
      await this.props.searchBillByPatientName({ search: value })
      this.setState({ patient_list: this.props.patientNameWiseSearchList },
        () => {
          if (this.state.patient_list.length !== 0) {
            this.props.getTestPrices(this.state.patient_list[0].patientdetails.organisationdetails !== null ? this.state.patient_list[0].patientdetails.organisationdetails.id : null);
          }
        }
      )
    }
  }

  handlePatientIdClick = (values) => {
    if(values !== null) {
      let data = [values];
      if(this.state.rows.length !== 0) {
        this.setState(this.reset)
      }
      this.setState({ rows: data, duplicateRowData: data })
    }
  }

  handleSearchAccession = async (name, value) => {
    if (!value && !isNaN(value)) {
      this.setState({ [name]: value })
    } else {
      await this.props.searchBillByAccession({ search: value }
      )
      this.setState({ patient_list: this.props.accessionWiseSearchList },
        () => {
          if (this.state.patient_list.length !== 0) {
            this.props.getTestPrices(this.state.patient_list[0].patientdetails.organisationdetails !== null ? this.state.patient_list[0].patientdetails.organisationdetails.id : null);
          }
        }
      )
    }
  }

  handleAccessionIdClick = (values) => {
    if(values !== null) {
      let data = [values];
      if(this.state.rows.length !== 0) {
        this.setState(this.reset)
      }
      this.setState({ rows: data, duplicateRowData: data })
    }
  }

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = this.state.rows.map((n) => n.id);
      this.setState({ selected: newSelecteds, selectedList: this.state.rows })
      return;
    }
    this.setState({ selected: [], selectedList: [] })
  }

  isSelected = (id) => {
    return this.state.selected.indexOf(id) !== -1
  }

  handleSelectRow = (event, row) => {
    const { selected, selectedList } = this.state;
    const selectedIndex = selected.indexOf(row.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.id);
      this.setState({ selectedList: [...this.state.selectedList, row ]})
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      let newSelectedList = selectedList.filter((item,index) => item.id !== row.id)
      this.setState({ selectedList: newSelectedList })

    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      let newSelectedList = selectedList.filter((item,index) => item.id !== row.id)
      this.setState({ selectedList: newSelectedList })

    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      let newSelectedList = selectedList.filter((item,index) => item.id !== row.id)
      this.setState({ selectedList: newSelectedList })
    }

    this.setState({ selected: newSelected })   
  }

  handleDueChange = (e, index) => {
    let newArray = [...this.state.duplicateRowData];
    newArray[index] = { ...newArray[index], 
      due_amount: e.target.value,
    }

    let newState = this.state.selectedList;
    newArray.map( item => {
      this.state.selectedList.map((list, index) => {
        if( item.id === list.id ) {
          newState[index] = item;
        }
      })
    })
    this.setState({ duplicateRowData: newArray, selectedList: newState })
  }

  handleDiscountChange = (e, index) => {
    let newArray = [...this.state.duplicateRowData];
    let rowArray = [...this.state.rows]
    const due = rowArray[index].due_amount;
    if(e.target.value !== '') {
      if(e.target.value > newArray[index].due_amount) {
        newArray[index] = { ...newArray[index], 
          discount_price: 0,
          due_amount: due
        }
        this.setState({ dueForToast: due, openDiscountErrorToast: true })
      } else {
        newArray[index] = { ...newArray[index], 
          discount_price: e.target.value,
          due_amount: due - e.target.value
        }
      }
    } else {
      newArray[index] = { ...newArray[index], 
        discount_price: 0,
      }
    }
    let newState = this.state.selectedList;
    newArray.map( item => {
      this.state.selectedList.map((list, index) => {
        if( item.id === list.id ) {
          newState[index] = item;
        }
      })
    })
    this.setState({ duplicateRowData: newArray, selectedList: newState })
  }

  handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    };
    this.setState({ openDiscountErrorToast: false })
  }
  
  handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    };
    this.setState({ openToast: false })
  }

  handleEditBill = (item) => {
    window.location.replace('/editbill/'+ item.id)
  }

  handleBillSettlement = async() => {
    const { comments, payment_mode, date, bills_settlement_amount, selectedList } = this.state;
    var bill_payment_info=[]
    let bill_paid_date = moment(date).format()

    let request = { bill_payment_info, comments, payment_mode, bill_paid_date, bills_settlement_amount }
    
    selectedList.map((item,index) => {
      let billref= parseInt(item.id)
      let patient_id= parseInt(item.patient_id)
      let total_price= parseInt(item.total_price)
      let concession_type= parseInt(item.concession_type)
      let concession_value= parseInt(item.concession_value)
      let discount_price= parseInt(item.discount_price)
      let payable_amount= parseInt(item.due_amount)

      bill_payment_info[index] = { ...bill_payment_info[index], billref,patient_id,total_price,concession_type,concession_value,discount_price,payable_amount}
    })
    this.setState({ paymentModeError: false, openToast: false })
    if(bill_payment_info.length !== 0) {
      if(payment_mode !== '') {
        await this.props.updateBillsPayment(request);
        if(this.props.billsPaymentSuccess) {
          window.location.reload();
        }
      } else this.setState({ paymentModeError: true })
    } else this.setState({openToast: true})
  }

  render() {
    const { rows, bill_history_type, bill_id, openBillList, openBillAccessionList, patient_list, date, payment_mode,
      comments, selected, duplicateRowData, openDiscountErrorToast, dueForToast, selectedList, paymentModeError,
      openToast, searchPatientData, searchAccessionID,
    } = this.state;
    const { accessionWiseSearchList } = this.props;
    let Sum = 0;
    duplicateRowData.map(item => {
      Sum = parseInt(Sum) + parseInt(item.due_amount)
      return this.state.total = Sum;
    })

    let amountToSettle = 0;
    selectedList.map( item => {
      amountToSettle = parseInt(amountToSettle) + parseInt(item.due_amount)
    })

    this.state.bills_settlement_amount = amountToSettle

    return (
      <Container component='main' maxWidth={false} classes={{ root: classes.containerPadding }}>
        <Grid container justify='center' alignItems='center' spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Billing History&nbsp;
              <ArrowForwardIos fontSize='small' className={classes.fontSizeArrowIcon} />
              <b>{bill_history_type === 0 ? 'All Bills' : (bill_history_type === 1 ? 'Bill Settlements' : 'Add Test To Bill')}</b>
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Tabs
              value={bill_history_type}
              onChange={this.handleTabChange}
              indicatorColor='primary'
            >
              <Tab className={clsx(classes.buttonText, classes.drawerMainList)} label='All Bills' {...this.handleTabEvent(1)} />
              <Tab className={clsx(classes.buttonText, classes.drawerMainList)} label='Bill Settlements' {...this.handleTabEvent(2)} />
              <Tab className={clsx(classes.buttonText, classes.drawerMainList)} label='Add Test To Bill' {...this.handleTabEvent(3)} />
            </Tabs>
          </Grid>

          <Grid item xs={7}>
            <Grid container justify='flex-end' alignItems='center' alignContent='center' spacing={1}>
              <Grid item>
                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                  variant='outlined'
                  margin='dense'
                >
                  <InputLabel htmlFor='outlined-adornment-password'>
                    Search Bill Id
                  </InputLabel>
                  <OutlinedInput
                    id='outlined-adornment-password'
                    type='number'
                    name='bill_id'
                    value={bill_id}
                    onChange={this.handleChange}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={this.handleSearchBillId}
                          edge='end'
                        >
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1} justify='center' alignItems='center'>
              <Grid item xs={1}></Grid>
              <Grid item xs={4}>
                <Autocomplete
                  id='asynchronous-search'
                  open={openBillList}
                  value={searchPatientData}
                  onOpen={() => {
                    this.setState({ openBillList: true })
                  }}
                  onClose={() => {
                    this.setState({ openBillList: false });
                  }}
                  onChange={(e, newValue) => this.handlePatientIdClick(newValue)}
                  getOptionLabel={(option) => option.patientdetails !== undefined ? option.patientdetails.patient_name : option}
                  filterOptions={option => option.filter((option) => {
                    return option.patientdetails.patient_name.toLowerCase().indexOf(searchPatientData.toLowerCase()) > -1
                  })}
                  renderOption={(option) => (
                    <React.Fragment>
                      <Grid container>
                        <Grid item xs={6}><Typography>{option.patientdetails.patient_name}</Typography></Grid>
                        <Grid item xs={6}><Typography></Typography></Grid>
                        <Grid item xs={12}><Typography variant='subtitle2' className={classes.fontColor}>{option.patientdetails.gender}, {option.patientdetails.age}yrs</Typography></Grid>
                        <Grid item xs={6}><Typography>Patient ID:{option.patientdetails.id}</Typography></Grid>
                        <Grid item xs={6}>
                          <Grid container justify='flex-end'>
                            <Typography className={classes.branchDetailsStyle}>Pending Due:{option.due_amount}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}><Divider /></Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                  options={patient_list}
                  loading={patient_list.length === 0 ? false : true}
                  renderInput={(params) => (
                    <TextField
                      name='searchPatientData'
                      value={searchPatientData}
                      fullWidth
                      margin='dense'
                      placeholder='Search by Patient Name or Patient ID or Contact'
                      className={classes.backgroundColor}
                      onChange={e => this.handleSearchPatient(e.target.name, e.target.value)}
                      {...params}
                      variant='outlined'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {openBillList && patient_list.length === 0 ? <CircularProgress color='inherit' size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1}>
                <Grid container justify='center' className={classes.fontColor}>
                  OR
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  id='asynchronous-search'
                  open={openBillAccessionList}
                  value={searchAccessionID}
                  onOpen={() => {
                    this.setState({ openBillAccessionList: true })
                  }}
                  onClose={() => {
                    this.setState({ openBillAccessionList: false });
                  }}
                  onChange={(e, newValue) => this.handleAccessionIdClick(newValue)}
                  getOptionLabel={(option) => option.requisition_id !== undefined ? option.requisition_id : option}
                  filterOptions={option => option.filter((option) => {
                    return option.requisition_id.toLowerCase().indexOf(searchAccessionID.toLowerCase()) > -1
                  })}
                  renderOption={(option) => (
                    <React.Fragment>
                      <Grid container>
                        <Grid item xs={6}><Typography>{option.patientdetails.patient_name}</Typography></Grid>
                        <Grid item xs={6}><Typography></Typography></Grid>
                        <Grid item xs={12}><Typography variant='subtitle2' className={classes.fontColor}>{option.patientdetails.gender}, {option.patientdetails.age}yrs</Typography></Grid>
                        <Grid item xs={6}><Typography>Accession ID:{option.requisition_id}</Typography></Grid>
                        <Grid item xs={6}>
                          <Grid container justify='flex-end'>
                            <Typography className={classes.branchDetailsStyle}>Pending Due:{option.due_amount}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}><Divider /></Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                  options={accessionWiseSearchList}
                  loading={accessionWiseSearchList.length === 0 ? false : true}
                  renderInput={(params) => (
                    <TextField
                      name='searchAccessionID'
                      value={searchAccessionID}
                      fullWidth
                      margin='dense'
                      placeholder='Search By Accession ID'
                      className={classes.backgroundColor}
                      onChange={e => this.handleSearchAccession(e.target.name, e.target.value)}
                      {...params}
                      variant='outlined'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {openBillAccessionList && accessionWiseSearchList.length === 0 ? <CircularProgress color='inherit' size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </Grid>
          
          <Grid item xs={6}>
            <Typography variant='h6'>
              <b>{rows.length !== 0 ? 
                <span>
                  {rows[0].patientdetails.patient_name}&nbsp;
                  ({rows[0].patientdetails.gender.charAt(0)}, {rows[0].patientdetails.age})
                </span>
                : '' }
              </b>
            </Typography><br/>
            <Typography className={classes.fontColor}>{rows.length !== 0 ? 'ID: '+ rows[0].patientdetails.id : '' }</Typography>
          </Grid>

          <Grid item xs={6}>
            <Grid container justify='flex-end'>
              { 
                duplicateRowData.length !== 0 ?
                  <Typography>
                    Pending Due: <b>INR: {Sum}</b>
                  </Typography>
                : ''
              }
            </Grid>
          </Grid>
          
          {rows.length !== 0 ? 
            <React.Fragment>
              <Grid item xs={12}>
                <Table aria-label='simple table'>
                  <TableHead style={{ minWidth: 650 }} >
                    <TableRow
                    >
                      <TableCell>
                        <Checkbox
                          indeterminate={selected.length > 0 && selected.length < rows.length}
                          checked={rows.length > 0 && selected.length === rows.length}
                          onChange={this.handleSelectAllClick}
                        />
                      </TableCell>
                      <TableCell style={{ fontWeight: '600' }}>Bill Id</TableCell>
                      <TableCell style={{ fontWeight: '600' }} align='left'>Bill Date</TableCell>
                      <TableCell style={{ fontWeight: '600' }} align='left'>Referral</TableCell>
                      <TableCell style={{ fontWeight: '600' }} align='left'>Organization</TableCell>
                      <TableCell style={{ fontWeight: '600' }} align='left'>Total</TableCell>
                      <TableCell style={{ fontWeight: '600' }} align='left'>Due Amount</TableCell>
                      <TableCell style={{ fontWeight: '600' }} align='left'>Amount To Settle</TableCell>
                      <TableCell style={{ fontWeight: '600' }} align='left'>Concession</TableCell>
                      <TableCell style={{ fontWeight: '600' }} align='left'></TableCell>
                    </TableRow>
                  </TableHead>
                  {rows.map((item, index) => {
                    const isItemSelected = this.isSelected(item.id);
                    return (
                      <TableBody key={index}>
                        <TableRow>
                          <TableCell onClick={e => this.handleSelectRow(e,item)}><Checkbox checked={isItemSelected}/></TableCell>
                          <TableCell onClick={e => this.handleSelectRow(e,item)}>{item.id}</TableCell>
                          <TableCell onClick={e => this.handleSelectRow(e,item)}>{item.bill_on}</TableCell>
                          <TableCell onClick={e => this.handleSelectRow(e,item)}>{item.patientdetails.referraldetails !== null ? item.patientdetails.referraldetails.referrer_name : ''}</TableCell>
                          <TableCell onClick={e => this.handleSelectRow(e,item)}>{item.patientdetails.organisationdetails !== null ? item.patientdetails.organisationdetails.organisation_name : ''}</TableCell>
                          <TableCell onClick={e => this.handleSelectRow(e,item)}>{item.total_price}</TableCell>
                          <TableCell>{item.due_amount}</TableCell>
                          <TableCell>
                            {
                              isItemSelected ? 
                                <TextField
                                  type='number'
                                  margin='dense'
                                  variant='outlined'
                                  error={duplicateRowData[index].due_amount < 0}
                                  value={duplicateRowData[index].due_amount}
                                  disabled={duplicateRowData[index].discount_price !== '0' ? true: false}
                                  onChange={e => this.handleDueChange(e,index)}
                                /> :
                                <Link variant="body2" className={classes.pointer} onClick={e => this.handleSelectRow(e,item)} >Settle Now</Link>
                            }
                          </TableCell>
                          <TableCell>
                            {
                              isItemSelected ?
                                <Grid container justify='center' alignContent='center'>
                                  <Grid item xs={9}>
                                    <TextField
                                      type='number'
                                      margin='dense'
                                      variant='outlined'
                                      value={duplicateRowData[index].discount_price}
                                      onChange={e => this.handleDiscountChange(e,index)}
                                    />
                                  </Grid>
                                  <Grid item xs={3} className={classes.headerContainer}>
                                    <Grid container justify='flex-end' alignContent='center'>
                                      {((parseInt(duplicateRowData[index].discount_price) / parseInt(item.due_amount)) * 100).toFixed(2)} %
                                    </Grid>
                                  </Grid>
                                  </Grid>
                              :
                                <Link variant="body2" className={classes.pointer} onClick={e => this.handleSelectRow(e,item)}>Add concession</Link>
                            }
                          </TableCell>
                          <TableCell><Link variant="body2" className={classes.pointer} onClick={e => this.handleEditBill(item)}>Edit Bill</Link></TableCell>
                        </TableRow>
                      </TableBody>
                    )
                  })}
                </Table>
              </Grid>

              <Grid item xs={2}>
                <Typography>Amount To Settle</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  value={amountToSettle}
                  fullWidth
                  type='number'
                  margin='dense'
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={2}>
                <Typography>Settlement Date</Typography>
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disabled
                    fullWidth
                    style={{ marginTop: '0px' }}
                    margin="dense"
                    id="date-picker-dialog"
                    placeholder="Date picker inline"
                    format="dd/MM/yyyy hh:mm:ss"
                    value={date}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    inputVariant='outlined'
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2}>
                <Typography>Payment Type</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name='payment_mode'
                  value={payment_mode}
                  select
                  fullWidth
                  onChange={this.handleChange}
                  margin='dense'
                  variant='outlined'
                >
                  {
                    this.props.paymentModes.list.map((item, index) => 
                      <MenuItem key={index} value={item.id}>{item.payment_mode_name}</MenuItem>
                    )
                  }
                </TextField>
                {paymentModeError && payment_mode === '' ? <div className={classes.error}>This field is required</div> : ''}
              </Grid>
              <Grid item xs={6}></Grid>

              <Grid item xs={2}>
                <Typography>Comment</Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  name='comments'
                  value={comments}
                  multiline
                  onChange={this.handleChange}
                  fullWidth
                  margin='dense'
                  variant='outlined'
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container justify='flex-end'>
                  <Button
                    type='submit'
                    color='primary'
                    variant='contained'
                    className={classes.buttonText}
                    onClick={this.handleBillSettlement}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
           : null
          }

          <Grid item xs={12}>
            <Snackbar
              open={openDiscountErrorToast}
              autoHideDuration={6000}
              onClose={this.handleCloseToast}
              message={'Concession limit exceeded, your maximum concession limit is INR'+ 
                dueForToast +'Concession Limit = Bill Amount - Additional Amount'
              }
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              action={
                <React.Fragment>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleCloseToast}
                  >
                    <Close />
                  </IconButton>
                </React.Fragment>
              }
            />
          </Grid>
          
          <Grid item>
            <Snackbar
              open={openToast}
              autoHideDuration={6000}
              onClose={this.handleCloseToast}
              message='Settlement amount is not valid'
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              action={
                <React.Fragment>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleCloseToast}
                  >
                    <Close />
                  </IconButton>
                </React.Fragment>
              }
            />
          </Grid>
        </Grid>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  searchBillList: state.login.searchBillByBillId,
  testsData: state.login.testsData,
  addTestToBillSuccess: state.login.addTestToBillSuccess,
  patientWiseSearchList: state.login.patientWiseSearchList,
  patientNameWiseSearchList: state.login.patientNameWiseSearchList,
  accessionWiseSearchList: state.login.accessionWiseSearchList,
  billsPaymentSuccess: state.login.billsPaymentSuccess,
  paymentModes: state.login.paymentModes,
})

export default connect(mapStateToProps, {
  searchBillByBillId,
  getTestPrices,
  addTestToBill,
  getBillByPatientId,
  searchBillByPatientName,
  searchBillByAccession,
  updateBillsPayment,
  getPaymentModes,
})(BillSettlement);
