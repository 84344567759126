import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Main from '../Drawer/DrawerLists/AdminList';
import Signin from '../Signin/index';
import Home from '../Home';
import Registration from '../Registration';
import Layout from '../Hoc/layout';
import ConcurrentLogin from '../ConcurrentLogin';
import Billing from '../Bill';
import DefaultBilling from '../Billing';
import PendingSamples from '../Accession/PendingSamples';
import RejectedSamples from '../Accession/RejectedSamples'
import Accessed from '../Accession/Accessed';
import TestList from '../TestCreation/TestList';
import AddReport from '../TestCreation/AddReport';
import BillSettings from '../TestCreation/BillSettings';
import InvoiceSettings from '../TestCreation/InvoiceSettings';
import ReportSettings from '../TestCreation/ReportSettings';
import UploadReportList from '../TestCreation/UploadReportList';
import ReferralList from '../ReferralManagement/ReferralList';
import DoctorList from '../DoctorManagement/DoctorList';
import OrganizationList from '../OrganizationManagement/OrganizationList';
import AddOutSource from '../OutsourceManagement';
import UserManagement from '../UserManagement';
import UpdatePassword from '../UserManagement/UpdatePassword';
import ListManagement from '../ListManagement';
import TestTypes from '../TestCreation/TestTypes';
import TestFileInput from '../TestCreation/TestTypes/TestFileInput';
import TestListValues from '../TestCreation/TestTypes/TestListValues';
import TestGraph from '../TestCreation/TestTypes/TestGraph';
import PatientWiseWaitingList from '../OperationManagement/PatientWaitingList';
import TestWiseWaitingList from '../OperationManagement/TestWiseWaitingList';
import HistoryOrDispatched from '../OperationManagement/HistoryOrDispatched';
import CenterDetails from '../CenterManagement/CenterDetails';
import CenterList from '../CenterManagement/CenterList';
import ProfileList from '../ProfileManagement/ProfileList';
import AllBills from '../Bill/BillSettlements/AllBills';
import BillSettlements from '../Bill/BillSettlements/BillSettlements';
import AddTestsToBill from '../Bill/BillSettlements/AddTestsToBill';
import EditBill from '../Bill/BillSettlements/EditBill';
import SampleTypes from '../SampleManagement/SampleTypes';
import CancelledReports from '../OperationManagement/CancelledReports';
import OperationSettings from '../OperationManagement/Settings';
import BatchManagement from '../BatchManagement/BatchData';
import DepartmentList from '../DepartmentManagement/DepartmentList';
import Report from '../Report';

import Reset from '../Reset';

class Routes extends React.Component {
  render() {
    const loggedin = localStorage.getItem('lims_access_token');
    const already_loggedin = localStorage.getItem('lims_already_loggedin');

    return (
      <BrowserRouter>
        {loggedin !== null ?

          <Switch>
            {/* <Route path="/error" render={()=> <h1>Page Not Found</h1>}/> */}
            {(already_loggedin !== null && already_loggedin === 'true') ?
              <Switch>
                <Route path="/inter" exact component={ConcurrentLogin} />
                <Route path="/*" component={Reset} />
              </Switch> :
              <Layout>
                <Route path="/" exact component={Home} />
                <Route path="/home" exact component={Home} />
                <Route path="/registration" exact component={Registration} />
                <Route path="/bill" component={Billing} />
                <Route path="/billing" component={DefaultBilling} />
                <Route path="/pending-samples" component={PendingSamples} />
                <Route path="/rejected-samples" component={RejectedSamples} />
                <Route path="/accessed" component={Accessed} />
                <Route path="/reportlist" component={TestList} />
                <Route path="/addreport" component={AddReport} />
                <Route path="/uploadReportList" component={UploadReportList} />
                <Route path="/testTypes" component={TestTypes} />
                <Route path="/testfileinput" component={TestFileInput}/>
                <Route path="/testlistvalues" component={TestListValues}/>
                <Route path="/testgraph" component={TestGraph}/>
                <Route path="/billsettings" component={BillSettings} />
                <Route path="/invoicesettings" component={InvoiceSettings} />
                <Route path="/reportsettings" component={ReportSettings} />
                <Route path="/referral-list" component={ReferralList} />
                <Route path="/doctor-list" component={DoctorList} />
                <Route path="/organization-list" component={OrganizationList} />
                <Route path="/addoutsource" component={AddOutSource} />
                <Route path="/adduser" component={UserManagement} />
                <Route path="/updatepassword" component={UpdatePassword} />
                <Route path="/list-management" component={ListManagement}/>
                <Route path="/patient-waitinglist" component={PatientWiseWaitingList} />
                <Route path="/archives" component={PatientWiseWaitingList} />
                <Route path="/test-waitinglist" component={TestWiseWaitingList}/>
                <Route path="/operation/dispatched" component={HistoryOrDispatched}/>
                <Route path="/centerdetails" component={CenterDetails}/>
                <Route path="/center-list" component={CenterList}/>
                <Route path="/profile-list" component={ProfileList}/>
                <Route path="/bill-settlements" component={AllBills}/>
                <Route path="/addtesttobill" component={AddTestsToBill}/>
                <Route path="/editbill" component={EditBill}/>
                <Route path="/allbills" component={BillSettlements}/>
                <Route path="/accession-settings" component={SampleTypes}/>
                <Route path="/operation/cancelled-reports" component={CancelledReports}/>
                <Route path="/operation/settings" component={OperationSettings}/>
                <Route path="/batch-management" component={BatchManagement}/>
                <Route path="/department-list" component={DepartmentList}/>
                <Route path="/report" component={Report}/>
              </Layout>}
            <Route path="/admin" component={Main} />
          </Switch> :

          <Switch>
            <Route path="/" exact component={Signin} />
            <Route path="/error" exact render={() => <h1>Page Not Found</h1>} />
            <Route path="*" render={() => (<Redirect to="/" />)} />
          </Switch>
        }
      </BrowserRouter>
    );
  }
}

export default Routes;