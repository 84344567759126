import React from 'react';
import { connect } from 'react-redux';

import { 
  Container,
  Grid,
  Typography,
  Divider,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';

import classes from '../../App.module.css';

class CenterDetails extends React.Component {

  handleCheckBox = (e) => {
    this.setState({ [e.target.name]: e.target.checked === true ? '1' : '0' })
  }

  render() {
    return (
      <Container component="main" maxWidth={false} classes={{root: classes.containerPadding}}>
        <Grid container justify='center' spacing={1}>
          <Grid item xs={12}>
            <Typography variant='h6'>Update your centre details on Livehealth Website</Typography>
          </Grid>
          <Grid item xs={12}><Divider/></Grid>

          <Grid item xs={6}>
            <Typography>Lab Name <b>jhdkjhjfdhk</b></Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography>Auth ID: <span className={classes.authBox}>wwww</span></Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>Report Sharing Key: <span className={classes.authBox}>68738684623874626834</span></Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider/>
          </Grid>

          <Grid item xs={12}>
            <Container component="main" maxWidth={false} classes={{root: classes.containerPadding}}>
              <Grid container justify='center' alignItems='center' spacing={1}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Lab Address</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    fullWidth
                    margin='dense'
                    variant='outlined'
                  />
                </Grid>

                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Area</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    margin='dense'
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={1}>
                  <Grid container justify='flex-end'>
                    <Typography>City</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    margin='dense'
                    variant='outlined'
                  />
                </Grid>

                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Type of Functioning</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    margin='dense'
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={5}></Grid>

                <Grid item xs={3}>
                  <Typography align='right'>Timings</Typography>
                </Grid>
                <Grid item xs={1}><Typography align='center'>From</Typography></Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    margin='dense'
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={1}><Typography align='center'>To</Typography></Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    margin='dense'
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    name='is_24_hrs'
                    // value={is_24_hrs}
                    onClick={this.handleCheckBox}
                    control={<Checkbox color='primary' />}
                    label='24/7'
                    labelPlacement='end'
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography align='right'>Website</Typography>
                </Grid>
      
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    margin='dense'
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={4}></Grid>
      
                <Grid item xs={3}>
                  <Typography align='right'>Contact Numbers</Typography>
                </Grid>
      
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    margin='dense'
                    variant='outlined'
                  />
                </Grid>
      
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    margin='dense'
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={1}></Grid>

                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Lab Email</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    margin='dense'
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={5}></Grid>

                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Admin Email</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    margin='dense'
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={5}></Grid>

                <Grid item xs={3}>
                  <Typography align='right'>Specialities</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin='dense'
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin='dense'
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin='dense'
                    variant='outlined'
                  />
                </Grid>
              </Grid>
              
              <Grid item xs={12}>
                <Grid container justify='flex-end'>
                  <Button
                    color='primary'
                    variant='contained'
                    className={classes.buttonText}
                  >
                    Confirm & Update
                  </Button>
                </Grid>
              </Grid>    
            </Container>
          </Grid>

        </Grid>
      </Container>
    )
  }
}

export default connect(null, null)(CenterDetails);