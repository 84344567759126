import React from "react";
import { connect } from "react-redux";
import DateRangePicker from 'react-bootstrap-daterangepicker';

import {
    Container,
    Grid,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button
} from "@material-ui/core";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import 'bootstrap-daterangepicker/daterangepicker.css';


import {
    getRejectedTests
} from "../../Store/ActionCreators";
import classes from "../../App.module.css";


class RejectedSamples extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            from_date: '',
            to_date: '',
        };
    }
    getData = async (data) => {
        this.props.getRejectedTests(this.state);
        // this.props.rejectedSamples;
        console.log(this.props.rejectedSamples);
    }

    componentDidMount(){
        this.getData(this.state)
    }

    handleEvent = (event, picker) => {
        if (event.type === 'apply') {
            this.setState(
                {
                    from_date: picker.startDate,
                    to_date: picker.endDate
                },
                () => this.getData(this.state)
            );
        }
    };

    render(){
        const {from_date, to_date} = this.state;
        const {rejectedSamples} = this.props;

        return (
            <Container component="main">
                <Grid container spacing={2} justify="space-between" alignItems="center">
                    <Grid item xs={6}>
                        <a href="#">Rejected & Dismissed Samples</a>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justify='flex-end'>
                            <Button variant='outlined' className={classes.datePickerButton}>
                                <DateRangePicker onEvent={this.handleEvent}>
                                    {(from_date && to_date) ? <span>{from_date.format('DD-MM-YYYY').toString()} to {to_date.format('DD-MM-YYYY').toString()}</span> :
                                        <span>Select Date Range</span>}
                                </DateRangePicker>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Table style={{ 'tableLayout': 'fixed', 'border': '1px solid black' }}>
                            <TableBody>
                                {(rejectedSamples || []).map((sample, index) => (
                                    <TableRow key={'rejected-sample-'+index}>
                                        <TableCell>
                                            <span className={classes.billId}>{sample.bill_id}</span>
                                            <div className={classes.rejectedTestNames}>Tests:&nbsp; 
                                                {
                                                    Object.keys(sample.test_name).map((item, index) => (
                                                        <span>
                                                            <span>{sample.test_name[item]}</span>
                                                            {
                                                                index !== Object.keys(sample.test_name).length -1 ?
                                                                    <i>,&nbsp;</i>:
                                                                null
                                                            }
                                                        </span>
                                                    ))
                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            {sample.patient_name + ', ' + (sample.gender.charAt(0)+' - '+ sample.age)}
                                            <div className={classes.dismissedTag}>Dismissed</div>
                                        </TableCell>
                                        <TableCell>
                                            <span>Dismissed Date: {' ' + sample.sample_dismiss_at}</span>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table> 
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    rejectedSamples: state.login.rejectedSamplesList
});

export default connect(mapStateToProps, {
    getRejectedTests
})(RejectedSamples);