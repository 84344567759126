import React from 'react';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  Typography,
  Button,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  TextField,
  Dialog,
  Chip,
  DialogTitle,
  DialogContent,
  Divider,
  IconButton,
  AppBar,
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

import CloseIcon from '@material-ui/icons/Close';

import classes from '../../App.module.css';
import { getTestList, searchTest } from '../../Store/ActionCreators';

class TestList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      rowsPerPage: 15,
      page: 0,
      addSupplementDialog: {
        isOpen: false,
        title: '',
        name: ''
      },
      fullList: [],
      chips: [],
      openEditPriceDialog: false,
      button_test_id: '',
      branch_details: [],
      openProcessedAt: false,
      search_branch: '',
      openBranchSearch: false,
      branchlisttest: [
        { branch_name: 'Central lab', branch_id: 1, mrp: '1220' },
        { branch_name: 'Kukatpalley', branch_id: 2, mrp: '6547' },
        { branch_name: 'Banjara Hills', branch_id: 3, mrp: '2578' },
        { branch_name: 'Vijayawada', branch_id: 4, mrp: '145' },
        { branch_name: 'Secunderabad', branch_id: 5, mrp: '145' },
        { branch_name: 'Guntur', branch_id: 6, mrp: '145' },
        { branch_name: 'Eluru', branch_id: 7, mrp: '145' }
      ],

      warning: false,
      branchAlreadyExist: false,
    }
    this.handleTestlist(0, 15);
    this.getFullList();
    this.searchRef = React.createRef();
  }

  debounce = (func, delay) => {
    let debounceTimer
    return function () {
      const context = this
      const args = arguments
      clearTimeout(debounceTimer)
      debounceTimer
        = setTimeout(() => func.apply(context, args), delay)
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    if(!newPage){
      this.handleTestlist(0, this.state.rowsPerPage);
    } else {
      let start = newPage * this.state.rowsPerPage;
      let length = start + this.state.rowsPerPage;
      this.handleTestlist(start, length);
    }
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
    this.setState({ page: 0 });
    this.handleTestlist(0, event.target.value);
  };

  handleEditPrice = (id) => {
    this.setState({ openEditPriceDialog: true, button_test_id: id })
  }

  handleDialogClose = () => {
    this.setState({ openEditPriceDialog: false, openProcessedAt: false })
  }

  handleProcessedAt = (branchDetails) => {
    this.setState({ openProcessedAt: true, branch_details: branchDetails })
  }

  handleAddBranch = (branch) => {
    this.setState({ branch_details: [...this.state.branch_details, branch] })
  }

  handleWarningClose = () => {
    this.setState({ warning: false })
  }

  handleDeleteBranch = (id) => {
    const branchListAfterDelete = this.state.branch_details.filter(item => item.branch_id !== id)
    this.setState({ branch_details: branchListAfterDelete })
  }

  handleEditPriceDialog = () => {
    const { openEditPriceDialog, rows, button_test_id } = this.state;
    return (
      <Dialog
        open={openEditPriceDialog}
        fullWidth
        maxWidth='sm'
      >
        <Grid container>
          <AppBar position='static'>
            <Grid container className={classes.type}>
              <Grid item xs={11} className={classes.type}>
                <Typography variant='h6'>
                  Edit price
                </Typography>
              </Grid>

              <Grid item xs={1}>
                <Grid container justify='flex-end'>
                  <IconButton onClick={this.handleDialogClose}>
                    <CloseIcon fontSize='small' className={classes.whiteColor} />
                  </IconButton>
                </Grid>
              </Grid>

            </Grid>
          </AppBar>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} className={classes.type}>
            <Container>
              <Grid container direction='row' alignItems='center'>
                <Grid item xs={12}>
                  {
                    rows.map(item => {
                      return (
                        <div key={item.no}>
                          {button_test_id === item.no ?
                            item.branchDetails.map((list) => {
                              return (
                                <Grid container direction='row' key={list.branch_id}>
                                  <Grid item xs={12}>
                                    <Grid container alignItems='center'>
                                      <Grid item xs={6} className={classes.type}>
                                        <Typography>{list.branch_name}</Typography>
                                      </Grid>
                                      <Grid item xs={3} className={classes.type}>
                                        <Typography>{list.mrp}</Typography>
                                      </Grid>
                                      <Grid item xs={3} className={classes.type}>
                                        <TextField
                                          value={list.mrp}
                                          variant='outlined'
                                          margin='dense'
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Divider orientation='horizontal' />
                                  </Grid>
                                </Grid>
                              )
                            })
                            : ''}
                        </div>
                      )
                    })
                  }
                </Grid>
              </Grid>
            </Container>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={1} className={classes.type}>
            <Button
              onClick={this.handleDialogClose}
              variant='outlined'
              color='primary'
              className={classes.buttonAccession}
            >
              Cancel
            </Button>
          </Grid>

          <Grid item xs={11} className={classes.type}>
            <Grid container justify='flex-end'>
              <Button
                variant='outlined'
                color='primary'
                className={classes.buttonAccession}
                onClick={this.handleSave}
              >
                Save
              </Button>&nbsp;&nbsp;
            </Grid>
          </Grid>

        </Grid>
      </Dialog>
    )
  }

  handleProcessedAtDialog = () => {
    const { search_branch, openProcessedAt, branchlisttest, branch_details, warning } = this.state;
    return (
      <Dialog
        open={openProcessedAt}
        fullWidth
        maxWidth='sm'
        className={classes.dialogWidth}
      >
        <Grid container>
          <AppBar position='static'>
            <Grid container className={classes.type}>
              <Grid item xs={11} className={classes.type}>
                <Typography variant='h6'>
                  Processed At
                </Typography>
              </Grid>

              <Grid item xs={1}>
                <Grid container justify='flex-end'>
                  <IconButton onClick={this.handleDialogClose}>
                    <CloseIcon fontSize='small' className={classes.whiteColor} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </AppBar>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} className={classes.type}>
            <Container>
              <Autocomplete
                options={branchlisttest}
                value={search_branch}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    this.state.branch_details.map(item => {
                      if (item.branch_id !== newValue.branch_id) {
                        this.handleAddBranch(newValue)
                      } else this.handleDeleteBranch(newValue)
                    })
                  }
                }}
                getOptionLabel={(option) => option !== '' ? option.branch_name : option}
                renderInput={(params) => <TextField fullWidth {...params} label='Select Branch' variant='outlined' />}
              />

              <Grid item xs={12}>
                <Grid container direction='row' justify='center'>
                  <Grid item xs={12}>
                    {
                      branch_details !== undefined && branch_details.map(item => {
                        return (
                          <Grid container direction='row' key={item.branch_id}>
                            <Grid item xs={12} className={classes.type}>
                              <Grid container direction='row' className={classes.box}>
                                <Grid item xs={10} className={classes.type}>
                                  <Typography>
                                    {item.branch_name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <IconButton onClick={e => this.handleDeleteBranch(item.branch_id)}>
                                    <CloseIcon fontSize='small' />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Grid>

          <Grid item xs={12}>
            {
              warning ?
                <Grid container alignItems='center' justify='center' spacing={1} style={{ backgroundColor: '#fcf8e3' }}>
                  <Grid item xs={11}>
                    <Typography variant='subtitle2'>You can give only 100% discount</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton onClick={this.handleWarningClose}>
                      <CloseIcon fontSize='small' />
                    </IconButton>
                  </Grid>
                </Grid>
                : ''
            }
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={1} className={classes.type}>
            <Button
              onClick={this.handleDialogClose}
              variant='outlined'
              color='primary'
              className={classes.buttonAccession}
            >
              Cancel
            </Button>
          </Grid>

          <Grid item xs={11} className={classes.type}>
            <Grid container justify='flex-end'>
              <Button
                variant='outlined'
                color='primary'
                className={classes.buttonAccession}
                onClick={this.handleSave}
              >
                Save
              </Button>&nbsp;&nbsp;
            </Grid>
          </Grid>

        </Grid>
      </Dialog>

    )
  }

  handleSave = () => {
    // console.log(this.state.rows)
  }

  handleTestlist = async (start = 0, length = 15) => {
    let x = {
      branch_id: null,
       start, 
       length
    }
    await this.props.getTestList(x);
    // console.log(this.props.test, x);
    this.setState({ rows: this.props.test });
  }

  getFullList = async () => {
    await this.props.getTestList();
    this.setState({ fullList: this.props.test });
  }

  handleSearch = this.debounce(async (value) => {
    if (value !== '') {
      await this.props.searchTest({ test: value })
      this.setState({ rows: this.props.searchTestList })
      this.setState({ page: 0 });
    } else {
      this.handleTestlist();
    }
  }, 1000)

  handleClose = () => {
    this.setState(state => ({
      dialogData: {
        isDialogOpen: false,
        dialogName: ''
      }
    }));
  }

  handleSupplementClose = () => {
    this.setState(state => ({
      addSupplementDialog: {
        isOpen: false,
        title: ''
      }
    }));
  }
  
  openSupplementDialog = (row) => {
    this.setState(state => ({
      addSupplementDialog: {
        isOpen: true,
        title: row.test_name
      }
    }));
  }

  handleEditTest = (row) => {
    this.props.history.push({ pathname: '/addreport', state: { row: row, isEdit: true } })
  }

  updateChips = (value) => {
    // console.log(value);
  }

  deleteChip = (chip, i) => {
    // console.log(chip, i);
  }

  render() {
    const { rows, addSupplementDialog, chips, fullList, rowsPerPage, page, openEditPriceDialog, openProcessedAt } = this.state;
    // console.log(rows);
    return (
      <Container component="main" maxWidth={false} classes={{root: classes.containerPadding}}>
        <Dialog
          open={addSupplementDialog.isOpen}
          onClose={this.handleSupplementClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="Add-Supplement-dialog-title">
            Add supplemantary test for - {addSupplementDialog.title}
          </DialogTitle>
          <DialogContent>
            <Grid container direction="row" justify="space-around" alignItems="center" spacing={3}>
              <Typography variant="subtitle2" gutterBottom>
                Search Supplementary Test :
              </Typography>
              <Grid item xs={12}>
                <Autocomplete
                  title="test name"
                  id="controlled-demo"
                  options={fullList}
                  getOptionLabel={(option) => option.test_name}
                  value={addSupplementDialog.name}
                  onChange={(event, newValue) => {
                    this.updateChips(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth
                    margin='dense' label="Type test name" />}
                />
              </Grid>
              <Grid item xs={12}>
                {
                  chips.map((chip, i) => (
                    <Chip variant="outlined" color="primary" size="small" onDelete={e => this.deleteChip(chip, i)} />
                  ))
                }
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <Grid container direction="row" alignItems="flex-start" spacing={2}>

          <Grid item xs={4}>
            <Typography variant='h6'>Complete List of Tests (with price)</Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant='outlined'
              fullWidth
              margin='dense'
              className={classes.search}
              placeholder="Test Search"
              onChange={e => this.handleSearch(e.target.value)}
            />
          </Grid>
          <Grid item xs={5}>
            <Grid container direction='column' justify='flex-end' alignItems='flex-end' spacing={1}>
              <Grid item xs={12}>
                <Button disabled variant='outlined' className={classes.buttonAccession}>Bulk Edit</Button>&nbsp;&nbsp;
                <Button disabled variant='contained' color='primary' className={classes.buttonAccession}>Export to Excel</Button>&nbsp;&nbsp;
                <Button disabled variant='contained' color='primary' className={classes.buttonAccession}>Print Test List </Button>
              </Grid>
              <Grid item xs={12}>
                {/* <Button variant='outlined' className={classes.buttonAccession}>Add Profile</Button>&nbsp;&nbsp; */}
                <Button disabled variant='outlined' className={classes.buttonAccession}>Bulk Edit Report Format(s)</Button>
              </Grid>
              <Grid item xs={12}>
                <Button disabled variant='outlined' className={classes.buttonAccession}>Verify Report Formats</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify='flex-end'>
              <TablePagination
                component='div'
                rowsPerPageOptions={[5, 10, 15, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={this.props.test.totalRecords || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Table aria-label="simple table">
              <TableHead style={{ minWidth: 650 }} >
                <TableRow>
                  <TableCell style={{ fontWeight: '600' }}>No.</TableCell>
                  <TableCell style={{ fontWeight: '600' }} align="center">Test Name</TableCell>
                  <TableCell style={{ fontWeight: '600' }} align="center">Test Price</TableCell>
                  {/* <TableCell style={{ fontWeight: '600', color: 'white' }} align="center"></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length !== 0 ? rows.list.map((row, i) => (
                  <TableRow key={row.id} className={classes.pointer} onClick={e => this.handleEditTest(row)}>
                    <TableCell component="th" scope="row">
                      {(page * rowsPerPage) + i + 1}
                    </TableCell>
                    <TableCell align="center">{row.test_name}</TableCell>
                    <TableCell align="center">{row.price}</TableCell>
                    {/* <TableCell align="center">
                        <Button href="#text-buttons" color="primary" onClick ={e => this.openSupplementDialog(row)}>
                          Map Test
                        </Button>
                      </TableCell> */}
                  </TableRow>
                )) : ''}
              </TableBody>
            </Table>
          </Grid>

          <Grid item xs={12}>
            {openEditPriceDialog ? this.handleEditPriceDialog() : ''}
          </Grid>

          <Grid item xs={12}>
            {openProcessedAt ? this.handleProcessedAtDialog() : ''}
          </Grid>

        </Grid>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  test: state.login.addTestList,
  searchTestList: state.login.searchTest,
})

export default connect(mapStateToProps, {
  getTestList,
  searchTest,
})(TestList);