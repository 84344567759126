import React from 'react';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Divider,
  Button
} from '@material-ui/core';

import { ValidatorForm } from 'react-material-ui-form-validator';
import { createtestparameter } from '../../../Store/ActionCreators';
import classes from '../../../App.module.css';

class TestGraph extends React.Component {
  constructor (props) {
    super(props); 
    this.state = {
      parameter_name: '',
      integrationCode: '',
      parameterCode: '',
      horizontalPosition: '',
      verticalPosition: '',
      graphWidth: '',
      graphHeight: '',
      min_horizontal_value: '',
      max_horizontal_value: '',
      min_vertical_value: '',
      max_vertical_value: '',
      graphLabel: '',

      is_sms_doctor: '0',
      is_email_doctor: '0',
      is_highlight_value: '0',
      is_underline_value: '0',
      is_non_editable_field: '0',
      is_optional_field: '0',
      is_break_line: '0',
      is_impressions: '0',
      report_name: 'Test_With_Graph',
      report_type: this.props.reportType,
      createAnother: false,
      test_id: this.props.testRef
    }
    this.reset = this.state;
  }

  handleSaveChanges = async(e) => {
    var obj = {
      test_id: this.state.test_id,
      parameter_info: {}
    }
    Object.keys(this.state).forEach(item => {
      if(item !== 'test_id' && item !== 'createAnother'){
        obj.parameter_info[item] = this.state[item];
      }
    })
    await this.props.createtestparameter(obj);
    if(this.props.createTestParamSuccess){
      if(this.state.createAnother){
        this.setState(this.reset);
      } else {
        this.props.history.push({pathname: '/reportlist'})
      }
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleCheckBox = (e) => {
    this.setState({ [e.target.name]: e.target.checked === true ? '1' : '0' })
  }

  render() {
    const { parameter_name, integrationCode, horizontalPosition, verticalPosition, graphWidth, graphHeight, min_horizontal_value, max_horizontal_value,
      min_vertical_value, max_vertical_value, graphLabel, is_sms_doctor, is_email_doctor, is_highlight_value, is_underline_value, is_non_editable_field,
      is_optional_field, is_break_line, is_impressions, createAnother, parameterCode
    } = this.state;
    return (
      <ValidatorForm onSubmit={this.handleSaveChanges}>
        <Container>
          <Grid container direction='row' justify='center' alignItems='center' spacing={1}>
          <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                  <Typography variant="h4">Test with Graph</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Name:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name='parameter_name'
                    value={parameter_name}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Enter the value name"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>Code:</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      name='parameterCode'
                      value={parameterCode}
                      onChange={this.handleChange}
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      placeholder="Enter the Primary Code"
                    />
                  </Grid>
                </Grid>
              </Grid>
            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Integration Code:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name='integrationCode'
                    value={integrationCode}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Integration Code"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={6}>
                  <Grid container justify='flex-end'>
                    <Typography>Horizontal Position:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name='horizontalPosition'
                    value={horizontalPosition}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Enter Horizontal Position"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={6}>
                  <Grid container justify='flex-end'>
                    <Typography>Vertical Position:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name='verticalPosition'
                    value={verticalPosition}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Enter Vertical Position"
                  />
                </Grid>
              </Grid>
            </Grid>
            
            <Grid item xs={6}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={6}>
                  <Grid container justify='flex-end'>
                    <Typography>Graph Width:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name='graphWidth'
                    value={graphWidth}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder='Enter Width of Graph'
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={6}>
                  <Grid container justify='flex-end'>
                    <Typography>Graph Height:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name='graphHeight'
                    value={graphHeight}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder='Enter Height of Graph'
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={6}>
                  <Grid container justify='flex-end'>
                    <Typography>Minimum Horizontal value:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name='min_horizontal_value'
                    value={min_horizontal_value}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder='Optional'
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={6}>
                  <Grid container justify='flex-end'>
                    <Typography>Maximum Horizontal value:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name='max_horizontal_value'
                    value={max_horizontal_value}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder='Optional'
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={6}>
                  <Grid container justify='flex-end'>
                    <Typography>Minimum Vertical Value:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name='min_vertical_value'
                    value={min_vertical_value}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder='Optional'
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={6}>
                  <Grid container justify='flex-end'>
                    <Typography>Maximum Vertical Value:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name='max_vertical_value'
                    value={max_vertical_value}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder='Optional'
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Graph Label:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name='graphLabel'
                    value={graphLabel}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder='Optional'
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider/>
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_sms_doctor'
                value={is_sms_doctor}
                checked={true ?  is_sms_doctor === '1' : false}
                control={<Checkbox color='primary' />}
                label='SMS to doctor'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_email_doctor'
                value={is_email_doctor}
                checked={true ? is_email_doctor === '1' : false}
                control={<Checkbox color='primary' />}
                label='Email to doctor'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_highlight_value'
                value={is_highlight_value}
                checked={true ? is_highlight_value === '1' : false}
                control={<Checkbox color='primary' />}
                label='Highlight this value'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_underline_value'
                value={is_underline_value}
                checked={true ? is_underline_value === '1' : false}
                control={<Checkbox color='primary' />}
                label='Underline this value'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_non_editable_field'
                value={is_non_editable_field}
                checked={true ? is_non_editable_field === '1' : false}
                control={<Checkbox color='primary' />}
                label='Non-editable field'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_optional_field'
                value={is_optional_field}
                checked={true ? is_optional_field === '1' : false}
                control={<Checkbox color='primary' />}
                label='Optional field'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_break_line'
                value={is_break_line}
                checked={true ? is_break_line === '1' : false}
                control={<Checkbox color='primary' />}
                label='Break line'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_impressions'
                value={is_impressions}
                checked={true ? is_impressions === '1' : false}
                control={<Checkbox color='primary' />}
                label='Has Impressions'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>
            <Grid item xs={12}>
                <Grid container direction='row' justify="flex-end" alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <FormControlLabel
                      name='createAnother'
                      value={createAnother}
                      checked={createAnother ? true : false}
                      control={<Checkbox color='primary' />}
                      label='Create Another'
                      labelPlacement='end'
                      onClick={this.handleCheckBox}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      type="submit"
                      color="primary"
                      size="medium"
                      variant="contained"
                      className={classes.buttonText}
                      onSubmit={this.handleSaveChanges}
                    >
                      Create
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

          </Grid>
        </Container>
        </ValidatorForm >
    )
  }
}

const mapStateToProps = state => ({
  createTestParamSuccess: state.login.createTestParamSuccess
})

export default connect(mapStateToProps, {
  createtestparameter
})(TestGraph);
