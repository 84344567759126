import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Dialog,
  IconButton,
  Grid,
  Link,
  Divider,
  TextField,
  Typography,
  Checkbox,
  Button,
  MenuItem,
  OutlinedInput,
  FormControl,
  InputAdornment,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { getTestResults, getDoctorList, updateTestResult } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class DialogBox extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    openDialog: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      open: this.props.openDialog,
      search: '',
      showPassword: false,
      doctor_reference: '',
      doctor_pass_key: '',
      disableSaveSign: true,
      advised: '',
      impression: '',
      resultedTest: this.props.testResult,
      patientInfo: this.props.data,
      filteredReports: this.props.partialList,
    }
    this.props.getDoctorList(1);
    this.handleApiCall(this.props.data.patient_test_id)
  }

  debounce = (func, delay) => { 
    let debounceTimer 
    return function() { 
        const context = this
        const args = arguments 
            clearTimeout(debounceTimer) 
                debounceTimer 
            = setTimeout(() => func.apply(context, args), delay) 
    } 
  }

  handleApiCall = async(id) => {
    await this.props.getTestResults({ patient_test_id: id })
  }

  handleSearchChange = (e) => {
    var updatedList = this.state.filteredReports;

    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.patient_name.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1;
      });
      this.setState({
        [e.target.name]: e.target.value,
        filteredReports: updatedList
      })
    } else {
      this.setState({ filteredReports: this.props.partialList })
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  handleClick = this.debounce(async(e, item) => {
    await this.props.getTestResults({ patient_test_id: item.patient_test_id })
    this.setState({resultedTest: this.props.testResults, patientInfo: item})
  },500)

  handleCloseDialog = () => {
    this.setState({ open: false })
  }

  handleClearPasskey = (e) => {
    this.setState({ doctor_pass_key: '' })
  }

  handleResultChange = (e,result) => {
    result.result = e.target.value;
  }

  handleSaveAndSign = async() => {
    let updatedResults = this.props.testResult.parameters_result.map(item => {
      return item;
    })
    const { data, testResults } = this.props;
    let status
    if (data.test_status === 'Incomplete') {
      status = '0'
    } else if (data.test_status === 'Partially Completed') {
      status = '1'
    } else if (data.test_status === 'Completed') {
      status = '2'
    } else if (data.test_status === 'Signed') {
      status = '3'
    }

    if (this.state.disableSaveSign) {
      this.setState({ disableSaveSign: false })
    } else {
      await this.props.updateTestResult({
        patient_test_id: data.patient_test_id,
        test_status: status,
        updated_result: updatedResults,
        doctor_reference: this.state.doctor_reference,
        doctor_pass_key: this.state.doctor_pass_key,
      },this.state);
    }
    if (this.props.updateTestResultStatus) {
      alert('Updated Successfully')
      this.handleCloseDialog();
      this.props.listRefresh()
    }
  }

  handleSave = async() => {
    const { data, testResults } = this.props;
    let status
    if (data.test_status === 'Incomplete') {
      status = '0'
    } else if (data.test_status === 'Partially Completed') {
      status = '1'
    } else if (data.test_status === 'Completed') {
      status = '2'
    } else if (data.test_status === 'Signed') {
      status = '3'
    }
    await this.props.updateTestResult({
      patient_test_id: data.patient_test_id,
      test_status: status,
      updated_result: testResults.test_result,
    },this.state);

    if (this.props.updateTestResultStatus) {
      alert('Updated Successfully')
    }
  }

  render() {
    const { search, doctorList,  partialList } = this.props;
    const { open, showPassword, doctor_reference, doctor_pass_key, disableSaveSign, resultedTest, patientInfo, filteredReports,
      advised, impression,  
    } = this.state;
    return (
      <Dialog open={open} maxWidth='lg'>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justify='flex-start'>
              <Grid item xs={2} className={classes.type}>
                <Grid container>
                  <Grid item xs={12}>
                    <Link onClick={this.handleCloseDialog}>
                      <ArrowBackIosIcon fontSize="small" className={classes.backArrowFontSize} />Back
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name='search'
                      value={search}
                      onChange={this.handleSearchChange}
                      fullWidth
                      margin='dense'
                      variant='outlined'
                      placeholder='Search by Patient Name and Access'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      Filtered reports
                    </Typography>   
                      {
                        filteredReports.map((item, index) => 
                          <Grid container key={index} onClick={e => this.handleClick(e, item)} className={clsx(classes.box,classes.type)}>
                            <Typography>{item.patient_name}({item.gender.charAt(0)}-{item.age})</Typography>
                            <Typography variant='caption'>{item.test_name}</Typography>
                            <Typography variant='caption' align='right'>{item.test_barcode}</Typography>
                          </Grid>
                        )
                      }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.type}>
                <Divider orientation='vertical' />
              </Grid>
              <Grid item xs={7} >
                <Grid item xs={12} className={classes.type}>
                  <Typography variant='h6'>{resultedTest.test_name} &nbsp;&nbsp;
                      <span className={clsx(classes.fontSmallSize, classes.textColor, classes.backgroundPurple)}>{resultedTest.test_status === '1' ? 'Partially Completed' : ''}</span>
                  </Typography>
                </Grid>
                <Grid container justify='center' alignItems='center' className={classes.type}>
                  {resultedTest.parameters_result.map((item, index)=>
                    <Grid container key={index} justify='center' alignItems='center'>
                      <Grid item xs={4}>
                        <Typography>
                          {item.parameter_info.parameterCode}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          onChange={e => this.handleResultChange(e, item)}
                          variant='outlined'
                          margin='dense'
                        />
                      </Grid>
                      <Grid item xs={1}>
                        {/* &nbsp;&nbsp;
                        <span className={clsx(classes.backgroundPurple, classes.textColor)}>A</span>&nbsp;&nbsp;
                        <span className={clsx(classes.backgroundGreen, classes.textColor)}>C</span> */}
                      </Grid>
                      <Grid item xs={1}>
                        <Typography>
                          {item.parameter_info.parameterType}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant='caption'>
                          {item.parameter_info.parameterRanges.map((item,index) => <div key={index}>{item}</div>)}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Checkbox />
                      </Grid>

                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                   )}

                  <Grid item xs={9} className={classes.type}>
                    Advised:    (Optional)
                  </Grid>

                  <Grid item xs={3}>
                    <Grid container justify='flex-end'><Checkbox /></Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name='advised'
                      value={advised}
                      onChange={this.handleChange}
                      multiline
                      fullWidth
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={9} className={classes.type}>
                    Impression:    (Optional)
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container justify='flex-end'><Checkbox /></Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name='impression'
                      value={impression}
                      onChange={this.handleChange}
                      multiline
                      fullWidth
                      variant='outlined'
                    />
                  </Grid>

                  <Grid item xs={6} className={classes.type}>
                    {disableSaveSign === false ?
                      <Grid container item justify='flex-start'>
                        <Typography>Select Signing Doctor</Typography>
                        <TextField
                          name='doctor_reference'
                          value={doctor_reference}
                          onChange={this.handleChange}
                          select
                          fullWidth
                          margin='dense'
                          variant='outlined'
                        >
                          {doctorList.map((item, index) => {
                            return <MenuItem key={index} value={item.id}>{item.doctor_name}</MenuItem>
                          })}
                        </TextField>
                      </Grid>
                      : ''}
                  </Grid>
                  {disableSaveSign === false ?
                    <Grid item xs={6} className={classes.type}>
                      <Grid container justify='flex-end'><Link onClick={this.handleClearPasskey}><Typography>Clear Passkey</Typography></Link></Grid>
                      <FormControl fullWidth>
                        <OutlinedInput
                          type={showPassword ? 'text' : 'password'}
                          name='doctor_pass_key'
                          value={doctor_pass_key}
                          margin='dense'
                          className={classes.search}
                          onChange={this.handleChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                    : ''}

                  <Grid item xs={11}>
                    <Grid container justify='flex-end'>
                      <Button
                        onClick={this.handleSaveAndSign}
                        // disabled={}
                        variant='outlined'
                        color='primary'
                        className={classes.buttonAccession}
                      >
                        Save and Sign
                      </Button> &nbsp;&nbsp;
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      variant='contained'
                      color='primary'
                      className={classes.buttonAccession}
                      onClick={this.handleSave}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.type}>
                <Divider orientation='vertical' />
              </Grid>
              <Grid item xs={2} className={classes.type}>
                <Typography>{patientInfo.designation}{patientInfo.patient_name}  ({patientInfo.gender.charAt(0)} - {patientInfo.age})</Typography>
                <div>#{patientInfo.patient_id}</div><br />
                <Typography variant='body2'>Bill Date: </Typography><div>{patientInfo.bill_date}</div><br />
                <Typography variant='body2'>Bill ID:</Typography> <div>{patientInfo.bill_id}</div><br />
                <Typography variant='body2'>Sample ID:</Typography><div>{patientInfo.test_barcode}</div><br />
                <Typography variant='body2'>Organization:</Typography> <div>{patientInfo.organisation_name}</div><br />
                <Typography variant='body2'>Referral: </Typography><div>{patientInfo.referrer_name}</div><br />
              </Grid>

              <Grid item>
                <Grid container justify='flex-end'>
                  <IconButton onClick={this.handleCloseDialog}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  testResults: state.login.testResults,
  doctorList: state.login.doctorList,
  updateTestResultStatus: state.login.updateTestResultStatus,
})

export default connect(mapStateToProps, {
  getTestResults,
  getDoctorList,
  updateTestResult,
})(DialogBox);