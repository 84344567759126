import React from 'react';
import clsx  from 'clsx';
import { connect } from 'react-redux';

import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  TextField,
  Button,
  Box,
  Divider,
  MenuItem,
} from '@material-ui/core';

import {
  Close
} from '@material-ui/icons';

import { createBatch } from '../../Store/ActionCreators';
import classes from '../../App.module.css';

var moment = require('moment');
class BatchManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      batch_ref_number: this.props.centersData.batch_ref_number,
	    collection_center_at: 0,
      batch_temp: '',
      collect_person: '',
      batch_comments: '',
      total_samples: 0,
      total_vacutainer: 0,
      red_top_count: 0,
      blue_top_count: 0,
      blue_count: 0,
      stool_sample_count: 0,
      rapid_serum_tube_count: 0,
      blue_container_count: 0,
      urine_container_count: 0,
      patient_test_ids: [],
      branch_id: 1,
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleCreateBatch = async() => {
    var values = [];
    var obj = this.state;
    this.props.data.map((item,index) => {
      let variable = item.data !== undefined ? item.data : item;
      values[index] = Object.keys(JSON.parse(variable.test_name))
    })
    obj = {...obj, patient_test_ids: values}

    await this.props.createBatch(obj);
    if(this.props.createBatchSuccess) {
      window.location.reload();
    }
  }

  render() {
    const { red_top_count, urine_container_count, blue_container_count, blue_count, blue_top_count, rapid_serum_tube_count, 
      stool_sample_count, batch_ref_number, batch_temp, collect_person, batch_comments, collection_center_at,
    } = this.state;
    const { profile, data, openCreateBatch, handleClose, handleAllAccessedSamples, collectPersonError, batchRefNumberError } = this.props;

    return (
      <Dialog
        open={openCreateBatch}
        fullWidth
        maxWidth='md'
        onClose={handleClose}
      >
        <Grid container classes={{root: classes.type}} className={classes.expansionPanelColor}>
          <Grid item xs={11} className={classes.type}>
            <Typography><b>Create New Batch</b></Typography>
          </Grid>
          <Grid item xs={1}>
            <Grid container justify='flex-end'>
              <IconButton onClick={handleClose}>
                <Close fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>

          <Grid item xs={12} className={clsx(classes.type,classes.backgroundWhite)}>
            <Grid container>
              <Grid item xs={5}>
                {/* <TextField
                  select
                  fullWidth
                  margin='dense'
                  variant='outlined'
                  onChange={this.handleChange}
                >
                </TextField> */}
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={3}>
                <br/>
                <Button
                  color='primary'
                  variant='outlined'
                  className={classes.buttonText}
                  onClick={handleAllAccessedSamples}
                >
                  Add All Accessed Samples
                </Button>
              </Grid>

              <Grid item xs={12}><br/></Grid>
              <Grid item xs={2}><Typography variant='subtitle2' className={classes.fontColor}>Sample ID</Typography></Grid>
              <Grid item xs={3}><Typography variant='subtitle2' className={classes.fontColor}>Patient Name</Typography></Grid>
              <Grid item xs={2}><Typography variant='subtitle2' className={classes.fontColor}>Sample Type(Container)</Typography></Grid>
              <Grid item xs={3}><Typography variant='subtitle2' className={classes.fontColor}>Tests</Typography></Grid>
              <Grid item xs={2}><Typography></Typography></Grid>

              <Grid item xs={12}>
                <Box border={1} borderColor='grey.500' className={clsx(classes.heightFix, classes.type)}>
                  { 
                    data.map( (item, index) => {
                      let status = item.data !== undefined ? item.data : item
                      return (
                        <Grid container justify='center' key={index} spacing={1}>
                          <Grid item xs={2}><Typography>{status.test_barcode}</Typography></Grid>
                          <Grid item xs={3}><Typography>{status.patient_name}(({status.gender.charAt(0)}-{status.age}))</Typography></Grid>
                          <Grid item xs={2}><Typography>
                            <span style={{ backgroundColor: '#' + status.container_color }} className={clsx(classes.marginTop,classes.testSampleCircle)}></span>&nbsp;
                            {status.sample_name}</Typography>
                          </Grid>
                          <Grid item xs={3}><Typography>{Object.values(JSON.parse(status.test_name),)}</Typography></Grid>
                          <Grid item xs={2}>
                            <Button
                              disabled={true ? data.length === 1 : false}
                              color='primary'
                              variant='contained'
                              className={clsx(classes.buttonText, classes.paddingButton)}
                            >
                              Remove
                            </Button>
                          </Grid>
                          <Grid item xs={12}><Divider/></Grid>
                        </Grid>
                      )
                    }
                  )}
                </Box>
                Note: Test Name with dark color will be outsourced.
              </Grid>

              <Grid item xs={12}>
                <br/>
                <Typography className={classes.fontColor}>Vacutainer Summary</Typography>
              </Grid>

              <Grid item xs={12} className={classes.type}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      type='number'
                      margin='dense'
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    Red Top
                    <TextField
                      name='red_top_count'
                      value={red_top_count}
                      onChange={this.handleChange}
                      type='number'
                      margin='dense'
                      variant='outlined'
                      placeholder='Red Top'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    Urine Container
                    <TextField
                      name='urine_container_count'
                      value={urine_container_count}
                      onChange={this.handleChange}
                      type='number'
                      margin='dense'
                      variant='outlined'
                      placeholder='Urine Container'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    Blue Top
                    <TextField
                      name='blue_top_count'
                      value={blue_top_count}
                      onChange={this.handleChange}
                      type='number'
                      margin='dense'
                      variant='outlined'
                      placeholder='Blue Top'
                    />
                  </Grid>

                  <Grid item xs={3}>
                    Stool Sample
                    <TextField
                      name='stool_sample_count'
                      value={stool_sample_count}
                      onChange={this.handleChange}
                      type='number'
                      margin='dense'
                      variant='outlined'
                      placeholder='Stool Sample'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    Blue Containers
                    <TextField
                      name='blue_container_count'
                      value={blue_container_count}
                      onChange={this.handleChange}
                      type='number'
                      margin='dense'
                      variant='outlined'
                      placeholder='Blue Containers'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    Blue
                    <TextField
                      name='blue_count'
                      value={blue_count}
                      onChange={this.handleChange}
                      type='number'
                      margin='dense'
                      variant='outlined'
                      placeholder='Blue'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    Rapid Serum Tube
                    <TextField
                      name='rapid_serum_tube_count'
                      value={rapid_serum_tube_count}
                      onChange={this.handleChange}
                      type='number'
                      margin='dense'
                      variant='outlined'
                      placeholder='Rapid Serum Tube'
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12}><Divider/></Grid>

                <Grid item xs={12}>
                  <Typography className={classes.fontColor}>Logistic Summary</Typography>
                </Grid>

                <Grid item xs={12}><Divider/></Grid>

                <Grid item xs={12} className={classes.type}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      Batch Ref No
                      <TextField
                        name='batch_ref_number'
                        value={batch_ref_number}
                        onChange={this.handleChange}
                        fullWidth
                        margin='dense'
                        variant='outlined'
                        placeholder='Batch Ref No'
                      />
                      {batchRefNumberError !== [] ? <div className={classes.errorStyle}>{batchRefNumberError}</div> : ''}
                    </Grid>
                    <Grid item xs={6}>
                      Batch Temperature
                      <TextField
                        name='batch_temp'
                        value={batch_temp}
                        onChange={this.handleChange}
                        fullWidth
                        margin='dense'
                        variant='outlined'
                        placeholder='Batch Temperature'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      Batch Collecting Person
                      <TextField
                        name='collect_person'
                        value={collect_person}
                        onChange={this.handleChange}
                        fullWidth
                        margin='dense'
                        variant='outlined'
                        placeholder='Batch Collecting Person'
                      />
                      {collectPersonError !== [] ? <div className={classes.errorStyle}>{collectPersonError}</div> : ''}
                    </Grid>
                    <Grid item xs={6}>
                      Comments
                      <TextField
                        name='batch_comments'
                        value={batch_comments}
                        onChange={this.handleChange}
                        fullWidth
                        margin='dense'
                        variant='outlined'
                        placeholder='Comments'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      To Location
                      <TextField
                        name='collection_center_at'
                        value={collection_center_at}
                        onChange={this.handleChange}
                        select
                        fullWidth
                        margin='dense'
                        variant='outlined'
                        placeholder='Select Outsource Center'
                      >
                        {this.props.centersData.collection_center_at.map( item => <MenuItem value={item.id} key={item.id}>{item.branch_name}</MenuItem>)}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={3}>
                <Typography variant='body2'>
                  Batch Generated By {profile.username}<br/>
                  On {moment().format('MMMM Do YYYY, h:mm:ss a')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {data.length}<br/>
                  Sample Total
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {data.length}<br/>
                  Vacutainer Total
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Button
                  color='primary'
                  variant='outlined'
                  className={classes.buttonText}
                  onClick={handleClose}
                >
                  Close
                </Button> &nbsp;&nbsp;
                <Button
                  color='primary'
                  variant='contained'
                  className={classes.buttonText}
                  onClick={this.handleCreateBatch}
                >
                  Create Branch
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.login.profile,
  createBatchSuccess: state.login.createBatchSuccess,
  batchRefNumberError: state.login.errorMessageCreateBatch.batchRefNumberError,
  collectPersonError: state.login.errorMessageCreateBatch.collectPersonError,
})

export default connect(mapStateToProps, {
  createBatch,
})(BatchManagement);