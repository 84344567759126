import React from 'react';
// import clsx from 'clsx';
import { connect } from 'react-redux';

import {
  Grid,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  Divider,
  TextField,
  Button,
  MenuItem,
} from '@material-ui/core';

import {
  Close,
} from '@material-ui/icons';

import flags from '../../Data/flags.json';

import { getCenterList, createCenter, updateCenter } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

class AddEditCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddCenter: false,
      branchref: null,
      branch_name: '',
      branch_type: '',
      parent_branch: '',
      country_code_mobile: '+91',
      mobile: '',
      email: '',
      address: '',
      area: '',
      city: '',
      pincode: '',
      state: '',
      country: '',
      certificate: null,
      errorMessages: false,
      image: '',
    }
    this.props.getCenterList();
    this.reset = this.state;
  }
  
  componentDidMount() {
    const { data, open, edit } = this.props;
    if(edit) {
      this.setState({
        branchref: data.id,
        branch_name: data.branch_name,
        branch_type: data.branch_type,
        parent_branch: data.parent_branch,
        country_code_mobile: data.country_code_mobile,
        mobile: data.mobile,
        email: data.email,
        address: data.address,
        area: data.area,
        city: data.city,
        pincode: data.pincode,
        state: data.state,
        country: data.country,
        certificate: data.certificate,

        openAddCenter: open,
        errorMessages: false,
      })
    } else this.setState({ openAddCenter: open, errorMessages: false })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleFileChange = async (e, name) => {
    let file = e.currentTarget.files[0];
    if(file){
      this.setState({ certificate: await toBase64(file), image: file.name })
    }
  }

  handleAddCenter = async() => {
    const { branch_name, branch_type, branchref, mobile, country_code_mobile, email, address, area, city, pincode, state, country,
      certificate, parent_branch
    } = this.state;
    const { edit } = this.props;

    let data ={ branch_name, branch_type, branchref, mobile, country_code_mobile, email, address, area, city, pincode, state, country,
      certificate, parent_branch
    }

    if(!edit) {
      await this.props.createCenter(data);
      if(this.props.createCenterSuccess) {
        this.setState(this.reset);
      } else this.setState({ errorMessages: true })
    } else {
      await this.props.updateCenter(data);
      if(this.props.updateCenterSuccess) {
        this.setState(this.reset);
      } else this.setState({ errorMessages: true })
    }
  }

  render() {
    const { openAddCenter, branch_name, branch_type, parent_branch, mobile, country_code_mobile,
      email, address, area, city, pincode, state, country, errorMessages, image,
    } = this.state;

    const { edit, createBranchNameError, createEmailError, updateBranchNameError, updateEmailError, createBranchTypeError, 
      updateBranchTypeError, createCountryCodeError, updateCountryCodeError, createMobileError, updateMobileError, centerList
    } = this.props;
    return (
      <Grid container>
        <Dialog
          open={openAddCenter}
          maxWidth='md'
          fullWidth
          onClose={this.props.handleCloseDialog}
        >
          <DialogTitle id="alert-dialog-slide-title">
            <Grid container>
              <Grid item xs={11}>
                { !edit ? 'Add Center' : 'Edit Center' }
              </Grid>
              <Grid item xs={1}>
                <Grid container justify='flex-end'>
                  <IconButton onClick={this.props.handleCloseDialog} >
                    <Close fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </DialogTitle>
          <Divider/>
          <Grid
            container
            classes={{root: classes.containerPadding}} 
            className={classes.type}
            justify='center'
            alignItems='center'
          >
            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> Center Name : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <TextField
                name='branch_name'
                value={branch_name}
                onChange={this.handleChange}
                fullWidth
                margin='dense'
                variant='outlined'
                placeholder='Enter name of the Center'
              />
              {errorMessages && !edit && createBranchNameError !== [] ? <div className={classes.error}>{createBranchNameError}</div> : ''}
              {errorMessages && edit && updateBranchNameError !== [] ? <div className={classes.error}>{updateBranchNameError}</div> : ''}
            </Grid>

            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> Center Type : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <TextField
                name='branch_type'
                value={branch_type}
                onChange={this.handleChange}
                fullWidth
                margin='dense'
                variant='outlined'
                placeholder='Enter type of the Center'
              />
              {errorMessages && !edit && createBranchTypeError !== [] && createBranchTypeError !== undefined ? <div className={classes.error}>{createBranchTypeError}</div> : ''}
              {errorMessages && edit && updateBranchTypeError !== [] && updateBranchTypeError !== undefined ? <div className={classes.error}>{updateBranchTypeError}</div> : ''}
            </Grid>

            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> Parent Center : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>

            <Grid item xs={9}>
              <TextField
                name='parent_branch'
                value={parent_branch}
                onChange={this.handleChange}
                select
                fullWidth
                margin='dense'
                variant='outlined'
              >
                {
                  centerList.list.map(item => {
                    return (
                      item.parent_branch !== null && item.parent_branch > 0 ?
                      <MenuItem key={item.id} value={item.id}>
                        {item.branch_name}
                      </MenuItem> : null
                    )
                  })
                }
              </TextField>
            </Grid>

            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> Mobile Number : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>

            <Grid item xs={2}>
              <TextField
                name='country_code_mobile'
                value={country_code_mobile}
                onChange={this.handleChange}
                select
                fullWidth
                variant='outlined'
                margin='dense'
              >
                {flags.map(flag =>
                  <MenuItem value={flag.dial_code} key={flag.code}>
                    {flag.flag} {flag.name} {flag.dial_code}
                  </MenuItem>
                )}
              </TextField>
              {errorMessages && !edit && createCountryCodeError !== [] ? <div className={classes.error}>{createCountryCodeError}</div> : ''}
              {errorMessages && edit && updateCountryCodeError !== [] ? <div className={classes.error}>{updateCountryCodeError}</div> : ''}
            </Grid>

            <Grid item xs={7}>
              <TextField
                name='mobile'
                value={mobile}
                onChange={this.handleChange}
                fullWidth
                variant='outlined'
                margin='dense'
                placeholder='Mobile Number'
                />
                {errorMessages && !edit && createMobileError !== [] ? <div className={classes.error}>{createMobileError}</div> : ''}
                {errorMessages && edit && updateMobileError !== [] ? <div className={classes.error}>{updateMobileError}</div> : ''}
            </Grid>
            
            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> Email : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <TextField
                name='email'
                value={email}
                onChange={this.handleChange}
                fullWidth
                variant='outlined'
                margin='dense'
                placeholder='Email ID'
              />
              {errorMessages && !edit && createEmailError !== [] ? <div className={classes.error}>{createEmailError}</div> : ''}
              {errorMessages && edit && updateEmailError !== [] ? <div className={classes.error}>{updateEmailError}</div> : ''}
            </Grid>

            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> Address : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <TextField
                name='address'
                value={address}
                onChange={this.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Address'
                margin='dense'
              />
            </Grid>

            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> Area : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <TextField
                name='area'
                value={area}
                onChange={this.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Area'
                margin='dense'
              />
            </Grid>

            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> City : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <TextField
                name='city'
                value={city}
                onChange={this.handleChange}
                fullWidth
                variant='outlined'
                placeholder='City Name'
                margin='dense'
              />
            </Grid>

            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> Pincode : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <TextField
                name='pincode'
                value={pincode}
                onChange={this.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Pin Code'
                margin='dense'
              />
            </Grid>

            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> State : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <TextField
                name='state'
                value={state}
                onChange={this.handleChange}
                fullWidth
                variant='outlined'
                placeholder='State'
                margin='dense'
              />
            </Grid>

            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> Country : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <TextField
                name='country'
                value={country}
                onChange={this.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Country'
                margin='dense'
              />
            </Grid>

            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> Upload Certificate : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>

            <Grid item xs={2}>
              <label htmlFor="upload-certificate">
                <input
                  style={{ display: 'none' }}
                  id="upload-certificate"
                  name="certificate"
                  type="file"
                  accept="image/jpeg, image/jpg, image/png"
                  onChange={ e => this.handleFileChange(e, 'pdf_header_image')}
                />
                <Button color="primary" variant="outlined" component="span" className={classes.buttonAccession}>
                  Upload Image
                </Button>
              </label>
            </Grid>

            <Grid item xs={7}>
              <h3>{image}</h3>
            </Grid>

            <Grid container justify='center' className={classes.type}>
              <Button
                color='primary'
                variant='contained'
                className={classes.buttonText}
                onClick={this.handleAddCenter}
              >
                {edit ? 'Update' : 'Submit'}
              </Button>
            </Grid>
          </Grid>
        </Dialog>
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  centerList: state.login.getCenterList,

  createCenterSuccess: state.login.createCenterSuccess,
  createEmailError: state.login.createCenterError.emailError,
  createBranchNameError: state.login.createCenterError.branchNameError,
  createBranchTypeError: state.login.createCenterError.branchTypeError,
  createMobileError: state.login.createCenterError.mobileError,
  createCountryCodeError: state.login.createCenterError.countryCodeError,

  updateCenterSuccess: state.login.updateCenterSuccess,
  updateEmailError: state.login.updateCenterError.emailError,
  updateBranchNameError: state.login.updateCenterError.branchNameError,
  updateBranchTypeError: state.login.updateCenterError.branchTypeError,
  updateMobileError: state.login.updateCenterError.mobileError,
  updateCountryCodeError: state.login.updateCenterError.countryCodeError,
})

export default connect(mapStateToProps, {
  getCenterList,
  createCenter,
  updateCenter,
})(AddEditCenter);