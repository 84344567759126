import React from 'react';
import { connect } from 'react-redux';

import {
  List,
  ListItem,
  ListItemText,
  Link,
  Collapse,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { logout } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class InventoryList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openStock: false,
      openInventory: false,
      openAddInventory: false,
      openSupplier: false,
    }
  }

  handleStock = () => {
    this.setState({ openStock: !this.state.openStock })
  }

  handleInventory = () => {
    this.setState({ openInventory: !this.state.openInventory })
  }

  handleAddInventory = () => {
    this.setState({ openAddInventory: !this.state.openAddInventory })
  }

  handleSupplier = () => {
    this.setState({ openSupplier: !this.state.openSupplier })
  }

  handleLogout = () => {
    this.props.logout();
  }

  render() {
    const { openStock, openInventory, openAddInventory, openSupplier } = this.state;
    return (
      <List>
        <ListItem button onClick={this.handleStock}>
          <ListItemText> <div className={classes.drawerMainList}> Stock Management </div> </ListItemText>
          {openStock ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openStock} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.listStyle}>
            <ListItem button>
              <Link underline="none" color="inherit"> Indent Order Requests </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Current Stock </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Consumed Inventories </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Expired Stock </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Departments Stock Details </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Near Expiry Inventories </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Stock Alert Quantity </Link>
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={this.handleInventory}>
          <ListItemText> <div className={classes.drawerMainList}> Inventory Purchase </div> </ListItemText>
          {openInventory ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openInventory} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.listStyle}>
            <ListItem button>
              <Link underline="none" color="inherit"> Inventory Purchase Orders </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Add New Purchase </Link>
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={this.handleAddInventory}>
          <ListItemText> <div className={classes.drawerMainList}> Add/Edit Inventory </div> </ListItemText>
          {openAddInventory ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openAddInventory} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.listStyle}>
            <ListItem button>
              <Link underline="none" color="inherit"> Inventory Add/Edit </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Bulk Inventory Import </Link>
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={this.handleSupplier}>
          <ListItemText> <div className={classes.drawerMainList}> Supplier Management </div> </ListItemText>
          {openSupplier ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openSupplier} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.listStyle}>
            <ListItem button>
              <Link underline="none" color="inherit"> Add/Edit Supplier </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Map Supplier Products </Link>
            </ListItem>
          </List>
        </Collapse>

        <ListItem button>
          <Link underline="none" color="inherit" >
            <ListItemText> <div className={classes.drawerMainList}> Consume Inventory Stock </div> </ListItemText>
          </Link>
        </ListItem>

        <ListItem button>
          <Link underline="none" color="inherit" >
            <ListItemText> <div className={classes.drawerMainList}> Purchase History </div> </ListItemText>
          </Link>
        </ListItem>

        <ListItem button>
          <Link underline="none" color="inherit" >
            <ListItemText> <div className={classes.drawerMainList}> Supplier History </div> </ListItemText>
          </Link>
        </ListItem>

        <ListItem button>
          <Link underline="none" color="inherit" >
            <ListItemText> <div className={classes.drawerMainList}> Reports </div> </ListItemText>
          </Link>
        </ListItem>

        <ListItem button onClick={this.handleLogout}>
          <Link underline="none" color="inherit" >
            <ListItemText> <div className={classes.drawerMainList}> Logout </div> </ListItemText>
          </Link>
        </ListItem>
      </List>
    );
  }
}

export default connect(null, { logout })(InventoryList);