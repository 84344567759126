import React from 'react';
import { connect } from 'react-redux';

import {
  List,
  ListItem,
  ListItemText,
  Link,
  Collapse,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { logout } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class ReviewerList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openDashboard: false
    }
  }

  handleDashboard = () => {
    this.setState({ openDashboard: !this.state.openDashboard })
  }

  handleLogout = () => {
    this.props.logout();
  }

  render() {
    const { openDashboard } = this.state;
    return (
      <List>
        <ListItem button onClick={this.handleDashboard}>
          <ListItemText> <div className={classes.drawerMainList}> Dashboard </div> </ListItemText>
          {openDashboard ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openDashboard} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.listStyle}>
            <ListItem button>
              <Link underline="none" color="inherit"> Patient List </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Test List </Link>
            </ListItem>
          </List>
        </Collapse>

        <ListItem button>
          <Link underline="none" color="inherit" >
            <ListItemText> <div className={classes.drawerMainList}> Archieves </div> </ListItemText>
          </Link>
        </ListItem>

        <ListItem button>
          <Link underline="none" color="inherit" >
            <ListItemText> <div className={classes.drawerMainList}> Advanced Search </div> </ListItemText>
          </Link>
        </ListItem>
        
        <ListItem button onClick={this.handleLogout}>
          <Link underline="none" color="inherit" >
            <ListItemText> <div className={classes.drawerMainList}> Logout </div> </ListItemText>
          </Link>
        </ListItem>
      </List>
    );
  }
}

export default connect(null, { logout })(ReviewerList);