export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const ACTIVITYLOG_SUCCESS = 'ACTIVITYLOG_SUCCESS';
export const LOGINCONFIRM_SUCCESS = 'LOGINCONFIRM_SUCCESS';

export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';

export const PATIENT_COUNT_SUCCESS = 'PATIENT_COUNT_SUCCESS';
export const PATIENT_TYPE_SUCCESS = 'PATIENT_TYPE_SUCCESS';
export const PATIENT_MOBILE_SUCCESS = 'PATIENT_MOBILE_SUCCESS';
export const PATIENT_NAME_SUCCESS = 'PATIENT_NAME_SUCCESS';
export const PATIENT_ORGANISATION_SUCCESS = 'PATIENT_ORGANISATION_SUCCESS';
export const PATIENT_REFERRAL_SUCCESS = 'PATIENT_REFERRAL_SUCCESS';

export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const UPDATION_SUCCESS = 'UPDATION_SUCCESS';

export const PATIENT_MOBILE_BILLING_SUCCESS = 'PATIENT_MOBILE_BILLING_SUCCESS';
export const PATIENT_ID_BILLING_SUCCESS = 'PATIENT_ID_BILLING_SUCCESS';
export const GET_PATIENT_BILLING_SEARCH_SUCCESS = 'GET_PATIENT_BILLING_SEARCH_SUCCESS';
export const GET_TEST_PRICES = 'GET_TEST_PRICES';
export const PATIENT_BILLING_SUCCESS = 'PATIENT_BILLING_SUCCESS';

export const GET_PENDING_SAMPLES = 'GET_PENDING_SAMPLES';
export const UPDATE_TEST_RECEIVE = 'UPDATE_TEST_RECEIVE';
export const GET_BILLWISE_TESTS = 'GET_BILLWISE_TESTS';
export const UPDATE_BILLWISE_RECEIVE_ALL = 'UPDATE_BILLWISE_RECEIVE_ALL';
export const BAR_CODE_SUCCESS = 'BAR_CODE_SUCCESS';
export const GET_REJECTED_SAMPLES = 'GET_REJECTED_SAMPLES';

export const GET_DEPARTMENTS_LIST = 'GET_DEPARTMENTS_LIST';
export const SEARCH_DEPARTMENT_LIST = 'SEARCH_DEPARTMENT_LIST';
export const ADD_DEPARTMENT_SUCCESS = 'ADD_DEPARTMENT_SUCCESS';
export const UPDATE_DEPARTMENT_SUCCESS = 'UPDATE_DEPARTMENT_SUCCESS';

export const GET_ACCESSED_TESTS = 'GET_ACCESSED_TESTS';

export const TEST_CREATION_SUCCESS = 'TEST_CREATION_SUCCESS';
export const DOCTOR_LIST_SUCCESS = 'DOCTOR_LIST_SUCCESS';
export const SEARCH_DOCTOR_SUCCESS = 'SEARCH_DOCTOR_SUCCESS';
export const ADD_DOCTOR_SUCCESS = 'ADD_DOCTOR_SUCCESS';
export const EDIT_DOCTOR_SUCCESS = 'EDIT_DOCTOR_SUCCESS';

export const ADD_ORGANIZATION_SUCCESS = 'ADD_ORGANIZATION_SUCCESS';
export const ORGANIZATION_LIST_SUCCESS = 'ORGANIZATION_LIST_SUCCESS';
export const SEARCH_ORGANIZATION_SUCCESS = 'SEARCH_ORGANIZATION_SUCCESS';
export const EDIT_ORGANIZATION_SUCCESS = 'EDIT_ORGANIZATION_SUCCESS';

export const GET_OPERATION_TESTS_SUCCESS = 'GET_OPERATION_TESTS_SUCCESS';
export const GET_TEST_RESULTS = 'GET_TEST_RESULTS';
export const UPDATE_TEST_RESULT = 'UPDATE_TEST_RESULT';
export const GET_OPERATION_SETTINGS = 'GET_OPERATION_SETTINGS';
export const UPDATE_OPERATION_SETTINGS = 'UPDATE_OPERATION_SETTINGS';

export const SEARCH_LIST_SUCCESS = 'SEARCH_LIST_SUCCESS';
export const CREATE_LIST_SUCCESS = 'CREATE_LIST_SUCCESS';
export const UPDATE_LIST_PRICES_SUCCESS = 'UPDATE_LIST_PRICES_SUCCESS';
export const UPDATE_LIST_SUCCESS = 'UPDATE_LIST_SUCCESS';
export const GET_LIST_SUCCESS = 'GET_LIST_SUCCESS';
export const GET_LIST_PRICES_SUCCESS = 'GET_LIST_PRICES_SUCCESS';
export const DELETE_LIST_SUCCESS = 'DELETE_LIST_SUCCESS';

export const GET_TEST_LIST_SUCCESS = 'GET_TEST_LIST_SUCCESS';
export const SEARCH_TEST_SUCCESS = 'SEARCH_TEST_SUCCESS';

export const SEARCH_REFERRAL_SUCCESS = 'SEARCH_REFERRAL_SUCCESS';
export const ADD_REFERRAL_SUCCESS = 'ADD_REFERRAL_SUCCESS';
export const REFERRAL_LIST_SUCCESS = 'REFERRAL_LIST_SUCCESS';
export const EDIT_REFERRAL_SUCCESS = 'EDIT_REFERRAL_SUCCESS';

export const ADD_TEST_SUCCESS = 'ADD_TEST_SUCCESS';
export const TEST_LIST_SUCCESS = 'TEST_LIST_SUCCESS';
export const EDIT_TEST_SUCCESS = 'EDIT_TEST_SUCCESS';
export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST';
export const GET_TEST_SAMPLE_TYPES_LIST = 'GET_TEST_SAMPLE_TYPES_LIST';
export const GET_REPORT_TYPE_LIST = 'GET_REPORT_TYPE_LIST';
export const UPDATE_TEST_SUCCESS = 'UPDATE_TEST_SUCCESS';

export const GET_BILL_SETTINGS = 'GET_BILL_SETTINGS';
export const UPDATE_BILL_SETTINGS = 'UPDATE_BILL_SETTINGS';

export const CREATE_TEST_PARAM_SUCCESS = 'CREATE_TEST_PARAM_SUCCESS';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';

export const CREATE_BRANCH_SUCCESS = 'CREATE_BRANCH_SUCCESS';
export const GET_BRANCH_LIST = 'GET_BRANCH_LIST';
export const UPDATE_BRANCH_SUCCESS = 'UPDATE_BRANCH_SUCCESS';
export const SEARCH_BRANCH_SUCCESS = 'SEARCH_BRANCH_SUCCESS';

export const ADD_TEST_PROFILE_SUCCESS = 'ADD_TEST_PROFILE_SUCCESS';
export const UPDATE_TEST_PROFILE_SUCCESS = 'UPDATE_TEST_PROFILE_SUCCESS';
export const SEARCH_TEST_PROFILE_SUCCESS = 'SEARCH_TEST_PROFILE_SUCCESS';
export const GET_TEST_PROFILE_LIST = 'GET_TEST_PROFILE_LIST';

export const GET_BILL_LIST_SUCCESS = 'GET_BILL_LIST_SUCCESS';
export const GET_BILL_SUCCESS = 'GET_BILL_SUCCESS';
export const SEARCH_BILL_ID_SUCCESS = 'SEARCH_BILL_ID_SUCCESS';
export const ADD_TEST_TO_BILL = 'ADD_TEST_TO_BILL';
export const SEARCH_BILL_BY_BILL_ID = 'SEARCH_BILL_BY_BILL_ID';
export const GET_BILL_BY_PATIENT_ID = 'GET_BILL_BY_PATIENT_ID';
export const SEARCH_BILL_BY_PATIENT_NAME = 'SEARCH_BILL_BY_PATIENT_NAME';
export const SEARCH_BILL_BY_ACCESSION = 'SEARCH_BILL_BY_ACCESSION';
export const UPDATE_BILL_PAYMENT_COMPLETED = 'UPDATE_BILL_PAYMENT_COMPLETED';
export const UPDATE_BILL_SUCCESS = 'UPDATE_BILL_SUCCESS';
export const CANCEL_BILL_SUCCESS = 'CANCEL_BILL_SUCCESS';
export const UPDATE_BILLS_PAYMENT = 'UPDATE_BILLS_PAYMENT';

export const GET_TEST_SAMPLE_TYPE_LIST = 'GET_TEST_SAMPLE_TYPE_LIST';
export const CREATE_TEST_SAMPLE_TYPE = 'CREATE_TEST_SAMPLE_TYPE';
export const UPDATE_TEST_SAMPLE_TYPE = 'UPDATE_TEST_SAMPLE_TYPE';
export const DELETE_TEST_SAMPLE_TYPE = 'DELETE_TEST_SAMPLE_TYPE';
export const GET_SAMPLE_TYPE_LIST = 'GET_SAMPLE_TYPE_LIST';
export const GET_SAMPLE_CONTAINER_LIST = 'GET_SAMPLE_CONTAINER_LIST';
export const CREATE_OUTSOURCE_CENTER = 'CREATE_OUTSOURCE_CENTER';

export const GET_OUTSOURCE_LIST = 'GET_OUTSOURCE_LIST';
export const GET_PERMISSIONS_LIST = 'GET_PERMISSIONS_LIST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS';
export const GET_USER_LIST = 'GET_USER_LIST';
export const GET_OUTSOURCE_CENTER_LIST = 'GET_OUTSOURCE_CENTER_LIST';
export const SEARCH_OUTSOURCE_CENTER = 'SEARCH_OUTSOURCE_CENTER';

export const CREATE_BATCH_SUCCESS = 'CREATE_BATCH_SUCCESS';
export const VALIDATE_BATCH_TESTS = 'VALIDATE_BATCH_TESTS';
export const GET_BATCH_LIST = 'GET_BATCH_LIST';
export const UPDATE_SAMPLE_TO_REJECT = 'UPDATE_SAMPLE_TO_REJECT';
export const UPDATE_SAMPLE_TO_RECEIVE = 'UPDATE_SAMPLE_TO_RECEIVE';
export const GET_BATCH_LOGISTICS = 'GET_BATCH_LOGISTICS'

export const GET_PAYMENT_MODES = 'GET_PAYMENT_MODES';