import React from 'react';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
} from '@material-ui/core';

import { ArrowForwardIos } from '@material-ui/icons';

import classes from '../../App.module.css';

import { createTestSample, getSampleTypeList, updateTestSample, deleteTestSample, getSampleContainerList } from '../../Store/ActionCreators';

class AddSampleType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorSampleName: false,
      errorSampleType: false,
      testsampletyperef: this.props.editItem.id !== undefined && this.props.editItem.id !== null ? this.props.editItem.id : null,
      test_sample_name: this.props.editItem.test_sample_name !== undefined && this.props.editItem.test_sample_name !== null ? this.props.editItem.test_sample_name : '',
      sample_id: this.props.editItem.sample_id !== undefined && this.props.editItem.sample_id !== null ? this.props.editItem.sample_id : '',
      container_id: this.props.editItem.container_id !== undefined && this.props.editItem.container_id !== null ? this.props.editItem.container_id : '',
      accession_id_abbreviation: this.props.editItem.accession_id_abbreviation !== undefined && this.props.editItem.accession_id_abbreviation !== null ? this.props.editItem.accession_id_abbreviation : '',
      accession_use_as: this.props.editItem.accession_use_as !== undefined && this.props.editItem.accession_use_as !== null ? this.props.editItem.accession_use_as : 'Prefix',
      test_sample_description: this.props.editItem.test_sample_description !== undefined && this.props.editItem.test_sample_description !== null ? this.props.editItem.test_sample_description : '',
      collection_instructions: this.props.editItem.collection_instructions !== undefined && this.props.editItem.collection_instructions !== null ? this.props.editItem.collection_instructions : '',
      new_series_of_accession: this.props.editItem.new_series_of_accession !== undefined && this.props.editItem.new_series_of_accession !== null ? this.props.editItem.new_series_of_accession : '0',
      test_barcode_gen_format_type: 1,
    }
    this.props.getSampleTypeList();
    this.props.getSampleContainerList();
  }

  handleTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleCheckBox = (e) => {
    this.setState({ [e.target.name]: e.target.checked === true ? '1' : '0' })
  }

  handleCancel = () => {
    window.location.replace('/accession-settings')
  }

  handleDelete = async() => {
    await this.props.deleteTestSample({ testsampletyperef: this.state.testsampletyperef })
    if(this.props.deleteTestSampleSuccess) {
      window.location.reload();
    }
  }

  handleSubmit = async() => {
    const { test_sample_name, sample_id } = this.state;
    const { editItem } = this.props;
    if (editItem.length === 0) {
      if (test_sample_name === '') {
        this.setState({ errorSampleName: true })
      } else if (sample_id === '') {
        this.setState({ errorSampleType: true })
      } else {
        this.setState({ errorSampleName: false, errorSampleType: false })
        await this.props.createTestSample(this.state)
        if (this.props.addTestSampleSuccess) {
          window.location.reload()
        }
      }
    } else {
      if (test_sample_name === '') {
        this.setState({ errorSampleName: true })
      } else if (sample_id === '') {
        this.setState({ errorSampleType: true })
      } else {
        this.setState({ errorSampleName: false, errorSampleType: false })
        await this.props.updateTestSample(this.state)
      }
      if (this.props.updateTestSampleSuccess) {
        window.location.reload()
      }
    }
  }

  render() {
    const { errorSampleName, test_sample_name, sample_id, errorSampleType, container_id, accession_id_abbreviation, accession_use_as,
      test_sample_description, collection_instructions, new_series_of_accession
    } = this.state;
    const { sampleTypeList, sampleContainerList, sampleNameError, containerIDError, editItem, updateTestSampleNameError,
      updateSampleIDError, updateContainerIDError,
    } = this.props;
    return (
      <Container component='main' maxWidth={false} classes={{ root: classes.containerPadding }}>
        <Typography>
          Accession Settings&nbsp;
          <ArrowForwardIos fontSize='small' className={classes.fontSizeArrowIcon} />
          <b>{editItem.length === 0 ? 'Add Sample Type' : 'Edit Sample Type'}</b>
        </Typography><br/>
        <Container maxWidth='md'>
          <Grid container justify='center' direction='row' spacing={1}>
            <Grid item xs={12}>
              Sample Name*
              <TextField
                name='test_sample_name'
                value={test_sample_name}
                error={errorSampleName}
                onChange={this.handleTextChange}
                variant='outlined'
                margin='dense'
                placeholder='Enter Sample Name'
                fullWidth
              />
              {errorSampleName ? <div className={classes.error}>This field should not be empty</div> : ''}
              {sampleNameError !== [] ? <div className={classes.error}>{sampleNameError}</div> : ''}
              {updateTestSampleNameError !== [] ? <div className={classes.error}>{updateTestSampleNameError}</div> : ''}
            </Grid>

            <Grid item xs={12}>
              Sample Type*
              <TextField
                name='sample_id'
                value={sample_id}
                error={errorSampleType}
                onChange={this.handleTextChange}
                select
                variant='outlined'
                margin='dense'
                placeholder='Select Sample Type'
                fullWidth
              >
                {sampleTypeList.map((item, index) => (
                  <MenuItem value={item.id} key={index}>{item.sample_name}</MenuItem>
                ))}
              </TextField>
              {errorSampleType ? <div className={classes.error}>This field should not be empty</div> : ''}
              {updateSampleIDError ? <div className={classes.error}>{updateSampleIDError}</div> : ''}
            </Grid>

            <Grid item xs={12}>
              Container Type
              <TextField
                name='container_id'
                value={container_id}
                onChange={this.handleTextChange}
                select
                variant='outlined'
                margin='dense'
                placeholder='Enter Container Type'
                fullWidth
              >
                {sampleContainerList.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    <span style={{ backgroundColor: '#' + item.container_color }} className={classes.sampleContainerCircle}></span> &nbsp;
                    {item.container_name}
                  </MenuItem>
                ))}
              </TextField>
              {containerIDError !== [] ? <div className={classes.error}>{containerIDError}</div> : '' }
              {updateContainerIDError !== [] ? <div className={classes.error}>{updateContainerIDError}</div> : '' }
            </Grid>

            <Grid item xs={6}>
              Accession ID Abbreviation
              <TextField
                name='accession_id_abbreviation'
                value={accession_id_abbreviation}
                onChange={this.handleTextChange}
                variant='outlined'
                margin='dense'
                placeholder='(Max 2 digit)'
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              Use as
              <TextField
                name='accession_use_as'
                value={accession_use_as}
                onChange={this.handleTextChange}
                select
                variant='outlined'
                margin='dense'
                fullWidth
              >
                <MenuItem value='Prefix'>Prefix</MenuItem>
                <MenuItem value='Postfix'>Postfix</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12}>
              Sample Description
              <TextField
                name='test_sample_description'
                value={test_sample_description}
                onChange={this.handleTextChange}
                variant='outlined'
                margin='dense'
                placeholder='Sample Description'
                fullWidth
                multiline
              />
            </Grid>

            <Grid item xs={12}>
              Collection Instruction
              <TextField
                name='collection_instructions'
                value={collection_instructions}
                onChange={this.handleTextChange}
                variant='outlined'
                margin='dense'
                placeholder='Collection Instruction'
                fullWidth
                multiline
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container justify='center'>
                <FormControlLabel
                  name='new_series_of_accession'
                  value={new_series_of_accession}
                  checked={true ? new_series_of_accession === '1' : false}
                  control={<Checkbox color='primary' />}
                  label='Make new series of Accession Numbers'
                  labelPlacement='end'
                  onClick={this.handleCheckBox}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  {
                    editItem.length !== 0 && editItem.is_deletable ?
                      <Button
                        type='submit'
                        onClick={this.handleDelete}
                        variant='contained'
                        color='secondary'
                        className={classes.buttonAccession}
                      >
                        Delete
                      </Button>
                    : ''
                  }
                </Grid>
                <Grid item xs={6}>
                  <Grid container justify='flex-end'>
                    <Button
                      variant='outlined'
                      color='primary'
                      className={classes.buttonAccession}
                      onClick={this.handleCancel}
                    >
                      Cancel
                    </Button> &nbsp;&nbsp;
                    {
                      editItem.length === 0 ?
                        <Button
                          type='submit'
                          onClick={this.handleSubmit}
                          variant='contained'
                          color='primary'
                          className={classes.buttonAccession}
                        >
                          Add Sample
                        </Button>
                      :
                        <Button
                          type='submit'
                          onClick={this.handleSubmit}
                          variant='contained'
                          color='primary'
                          className={classes.buttonAccession}
                        >
                          Update Sample
                        </Button>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>

            </Grid>
          </Grid>
        </Container>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  sampleTypeList: state.login.sampleTypeList,
  sampleContainerList: state.login.sampleContainerList,
  addTestSampleSuccess: state.login.addTestSampleSuccess,
  sampleNameError: state.login.errorMessageCreateSample.sampleNameError,
  containerIDError: state.login.errorMessageCreateSample.containerIDError,

  updateTestSampleSuccess: state.login.updateTestSampleSuccess,
  updateTestSampleNameError: state.login.errorMessageUpdateSample.sampleNameError,
  updateSampleIDError: state.login.errorMessageUpdateSample.sampleIDError,
  updateContainerIDError: state.login.errorMessageUpdateSample.containerIDError,
  updateTestBarcodeError: state.login.errorMessageUpdateSample.testBarcodeError,

  deleteTestSampleSuccess: state.login.deleteTestSampleSuccess,
})

export default connect(mapStateToProps, {
  getSampleTypeList,
  getSampleContainerList,
  createTestSample,
  updateTestSample,
  deleteTestSample,
})(AddSampleType);