import React from 'react';

import {
  Button,
  Grid,
} from '@material-ui/core'

import Logo from '../../Images/lucid.png';
import classes from '../../App.module.css';

class Header extends React.Component {
  render() {
    return (
      <Grid container alignItems="center" justify="center">
        <Grid item xs={8} sm={8} >
          <img src={Logo} alt="!" className={classes.logo}/>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Button
            className={classes.headerBtn}
            type="submit"
            variant="outlined"
            size="medium"
            href="/home"
            target="_blank"
          > Services Status</Button>
          <Button
            className={classes.headerBtn}
            type="submit"
            variant="outlined"
            size="medium"
            href="/home"
            target="_blank"
          > Blog</Button>
          <Button
            className={classes.headerBtn}
            type="submit"
            variant="outlined"
            size="medium"
            href="/home"
            target="_blank"
          > Feedback</Button>
        </Grid>
      </Grid>
    );
  }
}

export default Header;