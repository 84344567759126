import React from 'react';
import { connect } from 'react-redux';

import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import LucidWhiteLogo from '../Images/lucidJPG.jpg';

import { getTestResults } from '../Store/ActionCreators';

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    }
    this.handleReport();
  }

  handleReport = async () => {
    const pathName = window.location.pathname.split('/')
    if (pathName !== undefined && pathName[2] !== undefined) {
      const id = pathName[2].toString();
      await this.props.getTestResults({ patient_test_id: id });
    } else window.location.replace('/patient-waitinglist/signed')
    this.setState({ data: this.props.testResults })
  }

  render() {
    const container = {
      margin: '0px 35px 35px 35px',
      fontSize: '15px',
      fontFamily: 'sans-serif'
    }

    const align = {
      width: '992px',
    }

    const { data } = this.state;

    return (
      <Grid container style={align}>
        <Grid item xs={12}>
          <img src={LucidWhiteLogo} alt="!" style={{ height: '200px', width: '300px' }} />
        </Grid>

        <Grid item xs={12}>
          {
            data.length !== 0 ?
              <Grid container style={container} spacing={1}>
                <Grid item xs={2}>Client</Grid>
                <Grid item xs={4}>:&nbsp;<b>{data.organisation_name}</b></Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={4}></Grid>

                <Grid item xs={2}>Patient Name</Grid>
                <Grid item xs={4}>:&nbsp;<b>{data.patientdetails.designation}&nbsp;{data.patientdetails.patient_name}</b></Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={4}></Grid>

                <Grid item xs={2}>Age/Gende</Grid>
                <Grid item xs={4}>: {data.patientdetails.age} years / {data.patientdetails.gender}</Grid>
                <Grid item xs={2}>Sample Type</Grid>
                <Grid item xs={4}>: {data.testdetails.testsampledetails.test_sample_name}</Grid>

                <Grid item xs={2}>Phone</Grid>
                <Grid item xs={4}>: {data.patientdetails.mobile}</Grid>
                <Grid item xs={2}>Registered On</Grid>
                <Grid item xs={4}>: {data.patientdetails.registered_on}</Grid>

                <Grid item xs={2}>Ref. Dr.</Grid>
                <Grid item xs={4}>: </Grid>
                <Grid item xs={2}>Collected On</Grid>
                <Grid item xs={4}>: {data.sample_received_at}</Grid>

                <Grid item xs={2}><b>Req. No</b></Grid>
                <Grid item xs={4}><b>: {data.test_barcode}</b></Grid>
                <Grid item xs={2}>Reported On</Grid>
                <Grid item xs={4}>: {data.doctor_signed_at}</Grid>

                <Grid item xs={12}>
                  <b><hr /></b>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify='center'>
                    {data.testdetails.department_name}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify='center'>
                    <b>{data.testdetails.test_name}</b>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Table>
                    <TableHead style={{ borderCollapse: 'none', border: '2px solid' }}>
                      <TableRow>
                        <TableCell align="center"><b>Test Name</b></TableCell>
                        <TableCell align="center"><b>Observed Values</b></TableCell>
                        <TableCell align="center"><b>Units</b></TableCell>
                        <TableCell align="center"><b>Biological Reference Interval</b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ border: 'none' }}>
                      {/* <TableRow>
                        <TableCell>
                          Glycosylated Hb (HbA1C)
                          <div style={{ fontSize: '12px' }}>(Method : High-performance Iiquid chromatography(HPLC))</div>
                        </TableCell>
                        <TableCell><b>8.0</b></TableCell>
                        <TableCell>%</TableCell>
                        <TableCell style={{ fontSize: '16px' }}>
                          Normal:&lt;5.7<br />
                          Prediabetes:5.7-6.4<br />
                          Diabetes:&gt;6.5
                        </TableCell>
                      </TableRow> */}
                      {/* {data.testdetails.map(item =>  */}
                      <TableRow>
                        <TableCell>{data.testdetails.test_name}</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      {/* )} */}
                    </TableBody>
                  </Table>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>

                    <Grid item xs={12}>
                      Interpretation:
                    </Grid>
                    <Grid item xs={12}>
                      1.HbA1C reflects the mean blood glucose concentration over the previous 4-8wks.Improvement in glucose control occuring inthe 4weeks
                      before drawing of the sample is not well reflected in the result because formation of glycated hemoglobin isirreversible.
                    </Grid>
                    <Grid item xs={12}>
                      2.Low HbA1C values(&gt;4%) in a non diabetic individual are often associated with systemic inflammatory diseases,chronicanemia,
                      chronic renal failure and liver diseases.
                    </Grid>
                    <Grid item xs={12}>
                      3.Presence of variant hemogobins(HbF,HbS,HbC etc) may result in false elevation or reduction in HbA1C values.
                    </Grid>

                    <Grid item xs={12}>
                      Reference:
                      <div style={{ fontSize: '17px' }}>
                        American  Diabetes Association guidelines  2020.
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container justify='center' spacing={5}>
                    <Grid item xs={12}></Grid>
                      **End of Report**
                    <Grid item xs={12}></Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>

                <Grid item xs={6} style={{ fontSize: '17px' }}>
                  <div style={data.doctordetails !== null ? {height: '50px'} : null}/>
                  <b>{data.doctordetails !== null ? data.doctordetails.doctor_name : ''}</b>
                  <div>{data.testdetails.department_name}</div>
                </Grid>

                <Grid item xs={6} style={{ fontSize: '17px' }}>
                  <Grid container justify='flex-end'>
                    <Grid item>
                      <img src={data.doctordetails !== null ? data.doctordetails.doctor_signature_path : ''} alt='!' style={{ height: '50px', width: '150px' }}/>
                      <b>{data.doctordetails !== null ? data.doctordetails.doctor_name : ''}</b>
                      <div>{data.testdetails.department_name}</div>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
              : ''
          }
        </Grid>

      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  testResults: state.login.testResults
})

export default connect(mapStateToProps, { getTestResults })(Report);
