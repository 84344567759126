import React from 'react';
import { connect } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';

import {
  Button,
  Grid,
  Tabs,
  Tab,
  Divider,
  Container,
  Typography
} from '@material-ui/core'

import { TextValidator } from '../Material UI';
import Copyright from './Copyright';
import Header from './Header';
import { login } from '../Store/ActionCreators';

import LucidServices from '../Images/service.jpg';
import classes from '../App.module.css';


class Signin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      loginType: 0,
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({ loginType: newValue })
  }

  handleTabEvent = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  handleEventChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleLoginSubmit = (e) => {
    const loggedin = localStorage.getItem('lims_access_token');
    e.preventDefault();

    if (loggedin != null) {
      alert("Alredy Logged In")
      window.location.reload();
    }
    this.props.login(this.state.username, this.state.password, this.state.loginType)
  }

  render() {
    const { username, password, loginType } = this.state;
    const { error } = this.props;
    return (
      <Container component="main" maxWidth={false} classes={{root: classes.containerPadding}}>
        <Grid container spacing={5}>

          <Grid item xs={12} className={classes.headerContainer}>
            <Header />
          </Grid>

          <Grid item xs={12}>
            <Divider variant="fullWidth" component="hr" />
          </Grid>

          <Grid item xs={12}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={6}>
                <Grid container alignItems="center" justify="center">
                  <Grid item xs={12}>
                    <Typography variant="h5" color="primary" gutterBottom>
                      Stay connected with your business
                  </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" color="initial" gutterBottom>
                      Sign In to the largest Healthcare Management Platform
                  </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Tabs
                      value={loginType}
                      onChange={this.handleTabChange}
                      indicatorColor="primary"
                    >
                      <Tab label="Centre Login"  {...this.handleTabEvent(1)} />
                      <Tab label="Doctor Login"  {...this.handleTabEvent(2)} />
                    </Tabs>
                  </Grid>

                  <Grid item xs={12}>
                    <ValidatorForm onSubmit={this.handleLoginSubmit}>
                      <TextValidator
                        value={username}
                        onChange={this.handleEventChange}
                        error={error}
                        margin="normal"
                        label="Username"
                        name="username"
                        validators={['required', 'minStringLength:2', 'maxStringLength:150']}
                        errorMessages={['Username is required', 'Username should have atleast 2 characters', 'Username should not contain more than 150 characters']}
                      />
                      <TextValidator
                        value={password}
                        onChange={this.handleEventChange}
                        error={error}
                        margin="normal"
                        label="Password"
                        name="password"
                        type="password"
                        validators={['required', 'minStringLength:2', 'maxStringLength:25']}
                        errorMessages={['Password is required', 'Password should have atleast 2 characters', 'Password should not contain more than 25 characters']}
                      />
                      {error ?
                        <div className={classes.errorStyle}>
                          {this.props.errorMessage}
                        </div>
                        : ''
                      }
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="medium"
                      > Sign in using our secure server</Button>
                    </ValidatorForm>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Divider orientation="vertical" variant="middle" />
              </Grid>

              <Grid item xs={5}>
                <img src={LucidServices} alt="!" className={classes.image} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider variant="fullWidth" />
          </Grid>

          <Grid item xs={12}>
            <Copyright />
          </Grid>

        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  errorMessage: state.login.errorMessage,
  error: state.login.error,
})

export default connect(mapStateToProps, { login })(Signin);