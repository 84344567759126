import React from "react";
import { connect } from "react-redux";
import DateRangePicker from 'react-bootstrap-daterangepicker';

import {
  Container,
  Grid,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Button
} from "@material-ui/core";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import 'bootstrap-daterangepicker/daterangepicker.css';

import SampleWise from './SampleWise';
import BillWise from "./BillWise";

import {
  getPendingTests,
  getAccessionDepartmentsList,
  getBillWiseTests
} from "../../Store/ActionCreators";
import classes from "../../App.module.css";

class PendingSamples extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      department: "",
      branch_id: 1,
      type_wise: 0,
      is_emergency: false,
      start: props.start,
      length: 15,
      from_date: '',
      to_date: '',
    };
    this.props.getPendingTests(this.state);
    this.props.getAccessionDepartmentsList();
  }

  handleTabChange = (event, newValue) => {
    if (newValue === 1) {
      this.setState({ type_wise: newValue, is_emergency: false, start: 0, length: 15 }, () => {
        this.props.getBillWiseTests(this.state);
      })
    } else if (newValue === 2) {
      this.setState({ type_wise: newValue, is_emergency: true, start: 0, length: 15 }, () => {
        this.props.getPendingTests(this.state);
      });
    } else {
      this.setState({ type_wise: newValue, is_emergency: false, start: 0, length: 15 }, () => {
        this.props.getPendingTests(this.state)
      });
    }
  };

  setStartNLength = (start, length) => {
    this.setState({ start, length });
    this.props.getPendingTests(this.state);
  };

  handleTabEvent = index => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  };

  handleSelectChange = e => {
    this.setState(
      oldValues => ({
        ...oldValues,
        [e.target.name]: e.target.value
      }),
      () => this.props.getPendingTests(this.state)
    );
  };

  handleEvent = (event, picker) => {
    if (event.type === 'apply') {
      this.setState(
        {
          from_date: picker.startDate,
          to_date: picker.endDate
        },
        () => this.props.getPendingTests(this.state)
      );
    }
  };

  render() {
    const { department, type_wise, from_date, to_date } = this.state;
    const { pendingSamples, departmentsList, billWiseTests } = this.props;

    return (
      <Container component="main" maxWidth={false} classes={{root: classes.containerPadding}}>
        <Grid container spacing={2} justify="flex-end" alignItems="flex-end">
          <Grid item xs={6}>
            <Tabs
              value={type_wise}
              onChange={this.handleTabChange}
              indicatorColor="primary"
            >
              <Tab label="Sample-Wise" {...this.handleTabEvent(1)} />
              <Tab label="Bill-Wise" {...this.handleTabEvent(2)} />
              <Tab label="Emergency" {...this.handleTabEvent(3)} />
            </Tabs>
          </Grid>

          <Grid item xs={3}>
            <Grid container justify='flex-end'>
              <Select
                name="department"
                className={classes.departmentSelect}
                value={department}
                onChange={this.handleSelectChange}
                variant="outlined"
                margin="dense"
                MenuProps={{
                  style: {
                    maxHeight: 280
                  }
                }}
              >
                {departmentsList.length > 0 ? departmentsList.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.department_name}
                  </MenuItem>
                )) : ''}
              </Select>
            </Grid>
          </Grid>

          <Grid item xs={3}>
            <Grid container justify='flex-end'>
            <Button variant='outlined' className={classes.datePickerButton}>
              <DateRangePicker onEvent={this.handleEvent}>
                {(from_date && to_date) ? <span>{from_date.format('DD-MM-YYYY').toString()} to {to_date.format('DD-MM-YYYY').toString()}</span> :
                  <span>Select Date Range</span>}
              </DateRangePicker>
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {
              type_wise === 0 || type_wise === 2 ?
                (pendingSamples.length > 0 ? <SampleWise
                  data={pendingSamples}
                  recall={this.setStartNLength}
                /> : '')
                : <BillWise data={billWiseTests} />
            }
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  pendingSamples: state.login.pendingSamples,
  departmentsList: state.login.departmentsList,
  billWiseTests: state.login.billWiseTests,
  start: state.login.start
});

export default connect(mapStateToProps, {
  getPendingTests,
  getAccessionDepartmentsList,
  getBillWiseTests
})(PendingSamples);
