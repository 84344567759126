import React from 'react';
import clsx from 'clsx';

import {
  Container,
  Grid,
  ButtonGroup,
  Typography,
  Button,
  FormControlLabel,
  RadioGroup,
  Checkbox,
  Radio,
} from '@material-ui/core';

import classes from '../../../App.module.css';

class AdvancedSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_override_calculations: '0',
      is_pet_management: '0',
      is_cache_passkey: '0',
      is_hide_not_accessed_reports: '0',
      is_fetch_all_pending_reports: '0',
      is_disable_real_time_update: '0',
      notification_position: '0',
      accession_number: false,
      bill_id: false,
    }
  }

  handleButtonClick = (text) => {
    if(text === 'button1') {
      this.setState({ accession_number: true, bill_id: false })
    }else if(text === 'button2') {
      this.setState({ bill_id: true, accession_number: false })
    }
  }

  render() {
    const { is_override_calculations, is_pet_management, is_cache_passkey, is_hide_not_accessed_reports, is_fetch_all_pending_reports,
      is_disable_real_time_update, notification_position, accession_number, bill_id,
    } = this.state;
    return (
      <Container component="main" maxWidth={false} 
        className={classes.backgroundWhite} 
        style={{ height:'75vh'}}
      >
        <Grid container alignItems='center' className={clsx(classes.type,classes.containerPadding )}>
          <Grid item xs={10}>
            <FormControlLabel
              name='is_override_calculations'
              value={is_override_calculations}
              control={<Checkbox color='primary' />}
              label='Override Calculations'
              labelPlacement='end'
              onClick={this.handleCheckBox}
            />
          </Grid>
          <Grid item xs={2}>
            <Grid container justify='flex-end'>
              <Button
                variant='outlined'
                color='primary'
                className={classes.buttonAccession}
              >
                Cancel
              </Button>&nbsp;&nbsp;
              <Button
                variant='contained'
                color='primary'
                className={classes.buttonAccession}
              >
                Save Settings
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              name='is_pet_management'
              value={is_pet_management}
              control={<Checkbox color='primary' />}
              label='Pet Management'
              labelPlacement='end'
              onClick={this.handleCheckBox}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>Default Id Shown</Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              name='is_cache_passkey'
              value={is_cache_passkey}
              control={<Checkbox color='primary' />}
              label='Cache Passkey'
              labelPlacement='end'
              onClick={this.handleCheckBox}
            />
          </Grid>
          <Grid item xs={6}>
            <ButtonGroup size="large" color="primary">
              <Button className={classes.buttonText} variant={accession_number ? 'contained' : 'outlined'} 
                onClick={e => this.handleButtonClick('button1')}
              >
                Accession Number
              </Button>
              <Button className={classes.buttonText} variant={bill_id ? 'contained' : 'outlined'} 
                onClick={e => this.handleButtonClick('button2')}
              >
                Bill ID
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              name='is_hide_not_accessed_reports'
              value={is_hide_not_accessed_reports}
              control={<Checkbox color='primary' />}
              label='Hide Not Accessed Reports'
              labelPlacement='end'
              onClick={this.handleCheckBox}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              name='is_fetch_all_pending_reports'
              value={is_fetch_all_pending_reports}
              control={<Checkbox color='primary' />}
              label='Fetch All Pending Reports (Might impact load speed)'
              labelPlacement='end'
              onClick={this.handleCheckBox}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              name='is_disable_real_time_update'
              value={is_disable_real_time_update}
              control={<Checkbox color='primary' />}
              label='Disable Real-time Update'
              labelPlacement='end'
              onClick={this.handleCheckBox}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography>Show Notification Position</Typography>
          </Grid>
          <Grid item xs={10}>
            <RadioGroup
              name='notification_position'
              value={notification_position}
              row
              aria-label='notification_position'
              onChange={this.handleChange}
            >
              <FormControlLabel
                value='0'
                checked={notification_position === '0'}
                control={<Radio color='primary' />}
                label='Top Right'
                labelPlacement='end'
              />
              <FormControlLabel
                value='1'
                checked={notification_position === '1'}
                control={<Radio color='primary' />}
                label='Right Center'
                labelPlacement='end'
              />
              <FormControlLabel
                value='2'
                checked={notification_position === '2'}
                control={<Radio color='primary' />}
                label='Bottom Right'
                labelPlacement='end'
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

export default AdvancedSettings;