import React from 'react';

import Routes from './Routes';

class App extends React.Component {
  render() {
    return (
      <div>
        <Routes />
      </div>
    );
  }
}

export default App;
