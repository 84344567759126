import React from 'react';
import { connect } from 'react-redux';

import {
	Container,
	Grid,
	Typography,
	Divider,

	List,
	ListItem,
	ListItemText,
	Link,
	Collapse,
} from '@material-ui/core';

import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';

import classes from '../../App.module.css';
import NormalRange from './NormalRange';
import AgeSpecificRange from './AgeSpecificRange';
import DescriptiveRange from './DescriptiveRange';
import DescriptiveTest from './DescriptiveTest';
import TestFileInput from './TestFileInput';
import TestGraph from './TestGraph';
import TestListValues from './TestListValues';

class TestTypes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			parameter_name: '',
			parameterCode: '',
			method: '',
			integrationCode: '',
			positionPdf: 0,
			designation: 1,
			ageRange: 1,
			min_range: 0,
			max_range: 0,
			type: 'age',
			currentView: 'NormalRange',
			reportType: 'Pathology',
			openPathologyList: true,
			openRadiologyList: false,
			openPathologyTestTypeList: true,
			openPathologyPDFList: false,
			openTestRangeList: true,
			openRadiologyTestTypeList: false,
			openRadiologyPDFList: false,
			openEditReport: false,
		};
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	handlePathologyClick = () => {
		this.setState({ openPathologyList: !this.state.openPathologyList })
	}

	handleRadiologyClick = () => {
		this.setState({ openRadiologyList: !this.state.openRadiologyList })
	}

	handleTesttypeClick = () => {
		this.setState({ openPathologyTestTypeList: !this.state.openPathologyTestTypeList })
	}

	handlePathologyPDFClick = () => {
		this.setState({ openPathologyPDFList: !this.state.openRadiologyList })
	}

	handleTestRangeClick = () => {
		this.setState({ openTestRangeList: !this.state.openTestRangeList })
	}

	handleRadiologyTestTypeClick = () => {
		this.setState({ openRadiologyTestTypeList: !this.state.openRadiologyTestTypeList })
	}

	handleRadiologyPDFClick = () => {
		this.setState({ openRadiologyPDFList: !this.state.openRadiologyPDFList })
	}

	changeView = (viewName, reportType) => {
		this.setState({currentView: viewName, reportType: reportType});
	}

	handleTestNameClick = () => {
		this.setState({ openEditReport: true })
	}

	handleReportOptions = () => {
		const {
			openPathologyList, openRadiologyList, openPathologyTestTypeList, openPathologyPDFList,
			openTestRangeList, openRadiologyTestTypeList,
		} = this.state;
		return (
			<Grid container>
				<Grid item xs={12}>
					<Typography align='center'>
						Report options
          </Typography>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={12}>
					<Container maxWidth='md'>
						<List>
							<ListItem button>
								<Link underline="none" color="inherit" href='/reportlist'>
									<ListItemText> <div className={classes.drawerMainList}> &lt; Return to Admin Panel </div> </ListItemText>
								</Link>
							</ListItem>

							<ListItem button onClick={this.handlePathologyClick}>
								<ListItemText> <div className={classes.drawerMainList}> Pathology </div> </ListItemText>
								{openPathologyList ? <ArrowDropUp /> : <ArrowDropDown />}
							</ListItem>
							<Collapse in={openPathologyList} timeout="auto" unmountOnExit>
								<List component="div" disablePadding className={classes.listStyle}>

									<ListItem button onClick={this.handleTesttypeClick}>
										<ListItemText> <div className={classes.drawerMainList}> Test type </div> </ListItemText>
										{openPathologyTestTypeList ? <ArrowDropUp /> : <ArrowDropDown />}
									</ListItem>
									<Collapse in={openPathologyTestTypeList} timeout="auto" unmountOnExit>
										<List component="div" disablePadding className={classes.listStyle}>
											<ListItem button onClick={this.handleTestRangeClick}>
												<ListItemText> <div className={classes.drawerMainList}> Test with range </div> </ListItemText>
												{openTestRangeList ? <ArrowDropUp /> : <ArrowDropDown />}
											</ListItem>
											<Collapse in={openTestRangeList} timeout="auto" unmountOnExit>
												<List component="div" disablePadding className={classes.listStyle}>
													<ListItem button>
														<Link underline="none" color="inherit" onClick={e => this.changeView('NormalRange', 'Pathology')}> Test with normal range </Link>
													</ListItem>
													<ListItem button>
														<Link underline="none" color="inherit" onClick={e => this.changeView('DescriptiveRange', 'Pathology')}> Test with descriptive range </Link>
													</ListItem>
													<ListItem button>
														<Link underline="none" color="inherit" onClick={e => this.changeView('AgeSpecificRange', 'Pathology')}> Test with age-specific range </Link>
													</ListItem>
												</List>
											</Collapse>
											<ListItem button>
												<Link underline="none" color="inherit" onClick={e => this.changeView('DescriptiveTest', 'Pathology')}> Descriptive test (no range) </Link>
											</ListItem>
											<ListItem button>
												<Link underline="none" color="inherit" onClick={e => this.changeView('TestFileInput', 'Pathology')}> Test with file input </Link>
											</ListItem>
											<ListItem button>
												<Link underline="none" color="inherit" onClick={e => this.changeView('TestGraph', 'Pathology')}> Test with graph </Link>
											</ListItem>
											<ListItem button>
												<Link underline="none" color="inherit" onClick={e => this.changeView('TestListValues', 'Pathology')}> Test with list of values </Link>
											</ListItem>
										</List>
									</Collapse>

									{/* <ListItem button onClick={this.handlePathologyPDFClick}>        // Do Not Delete
										<ListItemText> <div className={classes.drawerMainList}> Styling PDF </div> </ListItemText>
										{openPathologyPDFList ? <ArrowDropUp /> : <ArrowDropDown />}
									</ListItem> */}
									<Collapse in={openPathologyPDFList} timeout="auto" unmountOnExit>
										<List component="div" disablePadding className={classes.listStyle}>
											<ListItem button>
												<Link underline="none" color="inherit" > Heading with separator line </Link>
											</ListItem>
											<ListItem button>
												<Link underline="none" color="inherit" > Heading </Link>
											</ListItem>
											<ListItem button>
												<Link underline="none" color="inherit" > Heading in center </Link>
											</ListItem>
											<ListItem button>
												<Link underline="none" color="inherit" > Insert space </Link>
											</ListItem>
											<ListItem button>
												<Link underline="none" color="inherit" > Insert a separator line </Link>
											</ListItem>
											<ListItem button>
												<Link underline="none" color="inherit" > Insert page break </Link>
											</ListItem>
										</List>
									</Collapse>

								</List>
							</Collapse>

							<ListItem button onClick={this.handleRadiologyClick}>
								<ListItemText> <div className={classes.drawerMainList}> Radiology </div> </ListItemText>
								{openRadiologyList ? <ArrowDropUp /> : <ArrowDropDown />}
							</ListItem>
							<Collapse in={openRadiologyList} timeout="auto" unmountOnExit>
								<List component="div" disablePadding className={classes.listStyle}>
									<ListItem button onClick={this.handleRadiologyTestTypeClick}>
										<ListItemText> <div className={classes.drawerMainList}> Test type </div> </ListItemText>
										{openRadiologyTestTypeList ? <ArrowDropUp /> : <ArrowDropDown />}
									</ListItem>
									<Collapse in={openRadiologyTestTypeList} timeout="auto" unmountOnExit>
										<List component="div" disablePadding className={classes.listStyle}>
											<ListItem button>
												<Link underline="none" color="inherit" onClick={e => this.changeView('DescriptiveTest', 'Radiology')}> Descriptive test (no range) </Link>
											</ListItem>
											<ListItem button>
												<Link underline="none" color="inherit" onClick={e => this.changeView('TestFileInput', 'Radiology')}> Test with file input </Link>
											</ListItem>
											<ListItem button>
												<Link underline="none" color="inherit" onClick={e => this.changeView('TestListValues', 'Radiology')}> Test with list of values </Link>
											</ListItem>
										</List>
									</Collapse>

									{/* <ListItem button onClick={this.handleRadiologyPDFClick}>      // Do Not Delete
										<ListItemText> <div className={classes.drawerMainList}> Styling PDF </div> </ListItemText>
										{openRadiologyPDFList ? <ArrowDropUp /> : <ArrowDropDown />}
									</ListItem>
									<Collapse in={openRadiologyPDFList} timeout="auto" unmountOnExit>
										<List component="div" disablePadding className={classes.listStyle}>
											<ListItem button>
												<Link underline="none" color="inherit" > Heading with separator line </Link>
											</ListItem>
											<ListItem button>
												<Link underline="none" color="inherit" > Heading </Link>
											</ListItem>
											<ListItem button>
												<Link underline="none" color="inherit" > Heading in center </Link>
											</ListItem>
											<ListItem button>
												<Link underline="none" color="inherit" > Insert space </Link>
											</ListItem>
											<ListItem button>
												<Link underline="none" color="inherit" > Insert a separator line </Link>
											</ListItem>
											<ListItem button>
												<Link underline="none" color="inherit" > Insert page break </Link>
											</ListItem>
										</List>
									</Collapse> */}
								</List>
							</Collapse>

							{/* <ListItem button>      // Do Not Delete
								<Link underline="none" color="inherit">
									<ListItemText> <div className={classes.drawerMainList}> Report Settings </div> </ListItemText>
								</Link>
							</ListItem>

							<ListItem button>
								<Link underline="none" color="inherit">
									<ListItemText> <div className={classes.drawerMainList}> Add/Edit Calculations </div> </ListItemText>
								</Link>
							</ListItem> */}

						</List>
					</Container>
				</Grid>
			</Grid>
		)
	}

	render() {
		const { currentView } = this.state;
		return (
			<Grid container direction='row' spacing={2}>
				<Grid item xs={3}>
					{this.handleReportOptions()}
				</Grid>
				<Grid item xs={9}>
					<Grid container>
						<Grid item >
							<Divider orientation='vertical' />
						</Grid>
						<Grid item xs={11}>
							<Container maxWidth={false} classes={{root: classes.containerPadding}}>
								{ currentView === 'NormalRange' &&
									<NormalRange testRef = {this.props.location.state.testRef} 
									reportType= {this.state.reportType} history = {this.props.history}/>
								}
								{ currentView === 'AgeSpecificRange' &&
									<AgeSpecificRange testRef = {this.props.location.state.testRef} 
									reportType= {this.state.reportType} history = {this.props.history}/>
								}
								{ currentView === 'DescriptiveRange' &&
									<DescriptiveRange testRef = {this.props.location.state.testRef} 
									reportType= {this.state.reportType} history = {this.props.history}/>
								}
								{ currentView === 'DescriptiveTest' &&
									<DescriptiveTest testRef = {this.props.location.state.testRef} 
									reportType= {this.state.reportType} history = {this.props.history}/>
								}
								{ currentView === 'TestFileInput' &&
									<TestFileInput testRef = {this.props.location.state.testRef} 
									reportType= {this.state.reportType} history = {this.props.history}/>
								}
								{ currentView === 'TestGraph' &&
									<TestGraph testRef = {this.props.location.state.testRef} 
									reportType= {this.state.reportType} history = {this.props.history}/>
								}
								{ currentView === 'TestListValues' &&
									<TestListValues testRef = {this.props.location.state.testRef} 
									reportType= {this.state.reportType} history = {this.props.history}/>
								}
							</Container>
						</Grid>
					</Grid>
				</Grid>

			</Grid>
		)
	}
}

const mapStateToProps = state => ({
	addedTestId: state.login.addedTestId
})

export default connect(mapStateToProps, {
  })(TestTypes);