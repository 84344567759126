import React from 'react';
import { connect } from 'react-redux';

import {
  Grid,
  Button
} from '@material-ui/core';

import classes from '../../../App.module.css';
import PDFHelper from '../../../Utilities/PDFHelper';
import { getBarcodesData } from '../../../Store/ActionCreators';

class ButtonRenderer extends React.Component {
  handleRedraw = () => {
    return (
      <Button variant='outlined' disabled color='primary' className={classes.buttonAccession}>Redraw</Button>
    )
  }
  handlePrintUserWiseBarCode = () => {
    return (
      <Button variant='contained' disabled color='primary' className={classes.buttonAccession}>Print Userwise Barcode</Button>
    )
  }
  handlePrint = () => {
    let item = this.props.node.data;
    return (
      <Button variant='contained' color='primary' className={classes.buttonAccession}
        onClick={e => this.handlePrintClick(item)}
      >Print</Button>
    )
  }

  handlePrintClick = async (billdata) => {
    var props = this.props;
    let refs = []
    refs = Object.keys(JSON.parse(billdata.test_name))
    await this.props.getBarcodesData({ testref: refs });
    if (this.props.barCode.length > 0) {
      let printableData = {
        printable: this.props.barCode,
        type: 'pdf',
        base64: true,
      }
      PDFHelper.printPDF(printableData);
    }
    props.agGridReact.gridOptions.rowData.forEach((currentRow, index) => {
      if (currentRow.bill_id === parseInt(billdata.billref)) {
        props.agGridReact.gridOptions.rowData.splice(index, 1);
        props.api.setRowData(props.agGridReact.gridOptions.rowData)
      }
    })
  }

  render() {
    return (
      <Grid container justify='center'>
        <Grid item xs={12}>
          {this.props.node.data.bill_date}
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '10px' }}>
          <Grid container spacing={1}>
            <Grid item>{this.handleRedraw()}</Grid>
            <Grid item>{this.handlePrintUserWiseBarCode()}</Grid>
            <Grid item>{this.handlePrint()}</Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  barCode: state.login.barCode,
})

export default connect(mapStateToProps, { getBarcodesData })(ButtonRenderer);