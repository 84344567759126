import React from 'react';

import PatientWiseWaitingList from './PatientWaitingList';

class OperationManagement extends React.Component {
  render() {
    return (
      <PatientWiseWaitingList/>
    );
  }
}

export default OperationManagement;