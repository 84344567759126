import React from 'react';
import clsx from 'clsx';

import {
  Container,
  Grid,
  Typography,
  Tabs,
  Tab,
} from '@material-ui/core';

import AddOutsourceCentres from './AddOutsourceCentres';
import AssignListsToOutsource from './AssignListsToOutsource'

import classes from '../App.module.css';

class AddOutSource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      outSourceType: 0,
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({ outSourceType: newValue })
  }

  handleTabEvent = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  render() {
    const { outSourceType } = this.state;

    return (
      <Container maxWidth={false} classes={{root: classes.containerPadding}}>
        {/* <Typography> */}
          <Grid container direction='row' justify='center' spacing={2}>
            <Grid item xs={12}>
              <Tabs
                value={outSourceType}
                onChange={this.handleTabChange}
                indicatorColor='primary'
                variant='fullWidth'
              >
                <Tab
                  className={clsx(classes.buttonText, classes.drawerMainList)}
                  label='Add Outsource Centres'
                  {...this.handleTabEvent(1)}
                />
                <Tab
                  className={clsx(classes.buttonText, classes.drawerMainList)}
                  label='Assign Lists to Outsource Centres'
                  {...this.handleTabEvent(2)}
                />
              </Tabs>
            </Grid>

            <Grid item xs={12}>
              {outSourceType  === 0 ? <AddOutsourceCentres/> : (outSourceType === 1 ? <AssignListsToOutsource/> : '') }
            </Grid>
            
          </Grid>
        {/* </Typography> */}
      </Container>
    );
  }
}

export default AddOutSource;