import React from 'react';
// import clsx from 'clsx';
import { connect } from 'react-redux';

import {
  Grid,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  Divider,
  TextField,
  Button,
  CircularProgress,
  FormControlLabel, 
  Checkbox,
  DialogContent,
  DialogActions,
  MenuItem,
} from '@material-ui/core';

import {
  Close,
} from '@material-ui/icons';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { searchTest, createTestProfile, updateTestProfile } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class AddEditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddProfile: false,
      profile_tests: [],
      openTestList: false,
      testName: '',
      openSubmitPopUp: false,
      test_name: '',
      outsource_test: 1,
      integration_code: '',
      test_description: '',
      display_code: '',
      price: '',
      price_2: '',
      minimum_selling_price: '',
      cost_of_test: '',
      revenue_cap: '',
      short_text: '',
      target_tat_in_time: '',
      is_send_email_to_organisation: '0',
      is_send_email_to_referral: '0',
      branch_id: 1,
      profileref: '',
      duplicateTestWarning: false,
      testCountWarning: false,
      deleted_tests: '',
      profile_price_id: '',
    }
  }
  
  componentDidMount() {
    const { data, open, edit } = this.props;
    if(edit) {
      this.setState({
        profileref: data.id,
        profile_price_id: data.testpricedetail.id,
        test_name: data.test_name,
        display_code: data.display_code,
        outsource_test: data.testpricedetail.outsource_test,
        profile_tests: data.testprofilepricedetail !== undefined ? data.testprofilepricedetail : '',
        integration_code: data.integration_code,
        target_tat_in_time: data.target_tat_in_time,
        short_text: data.short_text,
        test_description: data.test_description,
        cost_of_test: data.testpricedetail.cost_of_test,
        minimum_selling_price: data.testpricedetail.minimum_selling_price,
        revenue_cap: data.testpricedetail.revenue_cap,
        price: data.testpricedetail.price,
        price_2: data.testpricedetail.price_2,
        openAddProfile: open,
      })
    } else this.setState({ openAddProfile: open })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleAddProfilePopUpOpen = () => {
    this.setState({ openAddProfile: true })
  }

  handleAddProfilePopUpClose = () => {
    this.setState({ openAddProfile: false })
  }

  handleSearchTest = async (name, value) => {
    if(value !== '') {
      await this.props.searchTest({ test: value })
    }
  }

  handleTextChange = (e, index) => {
    let newArray = [...this.state.profile_tests];
    newArray[index] = { ...newArray[index], 
     price: e.target.value,
    }
    let SumofPrices = 0;
    newArray.map(item => {
      SumofPrices = parseInt(SumofPrices) + parseInt(item.price)
    })
    this.setState({ profile_tests: newArray, price: SumofPrices })
  }

  handleTestDuplicateWarningClose = () => {
    this.setState({ duplicateTestWarning: false })
  }

  handleAddTestMenuClick = (value) => {
    const { profile_tests } = this.state;
    let test_id, test_name, organisation_id, price;
    if(value !== null) {
      test_id = value.id;
      test_name = value.test_name
      organisation_id = value.testpricedetail.organisation_id;
      price = value.testpricedetail.price;
    }
    let SumofPrices = 0;
    if(value !== null) {
      if(profile_tests.length !== 0) {
        profile_tests.map(item => {
          SumofPrices = parseInt(SumofPrices) + parseInt(item.price)
        })
        SumofPrices= SumofPrices + parseInt(price)
        let status = false;
        profile_tests.map(item => {
          if(item.test_id === test_id) {
            this.setState({ duplicateTestWarning: true })
            status = true
          }
        })
        if(!status) {
          this.setState({ 
            profile_tests: [...this.state.profile_tests, { test_name, test_id, organisation_id, price } ], testName: '',
            price: SumofPrices,
          })
        }
      } else this.setState({ 
        profile_tests: [...this.state.profile_tests, { test_name, test_id, organisation_id, price } ], testName: '',
        price: value.price
      })
    }
  }

  handleDeleteTest = (id) => {
    const { profile_tests } = this.state;
    let listAfterDeletion = profile_tests.filter( item => item.test_id !== id )
    let DeletedList = profile_tests.filter( item => item.test_id === id )
    if(DeletedList[0].profile_id !== '') {
      let test_id, test_name, organisation_id, price, profile_id, id;
      test_id = DeletedList[0].id;
      test_name = DeletedList[0].test_name
      price = DeletedList[0].price;
      id = DeletedList[0].id;
      profile_id = DeletedList[0].profile_id;
      this.setState({ deleted_tests: [...this.state.deleted_tests, {
        test_name, test_id, organisation_id, price, profile_id, id
      }]})
    }
    let SumofPrices = 0;
    listAfterDeletion.map(item => {
      SumofPrices = parseInt(SumofPrices) + parseInt(item.price)
    })
    this.setState({ profile_tests: listAfterDeletion, price: SumofPrices })
  }

  handleCheckBox = (e) => {
    this.setState({ [e.target.name]: e.target.checked === true ? '1' : '0'})
  }

  handleSubmitPopUpOpen = () => {
    this.setState({ openSubmitPopUp: true })
  }

  handleSubmitPopUpClose = () => {
    this.setState({ openSubmitPopUp: false })
  }

  handleSubmitPopUp = () => {
    const { openSubmitPopUp, is_send_email_to_organisation, is_send_email_to_referral } = this.state;
    return (
      <Dialog
        open={openSubmitPopUp}
        maxWidth='sm'
        fullWidth
        onClose={this.handleSubmitPopUpClose}
      >
        <DialogTitle disableTypography>
          <Grid container>
            <Grid item xs={11}>
              <Typography>Do you want to email newly added profile to Organizations and Referrals ?</Typography>
            </Grid>
            <Grid item xs={1}>
              <Grid container justify='flex-end'>
                <IconButton onClick={this.handleSubmitPopUpClose} >
                  <Close fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider/>
        <DialogContent>
          <FormControlLabel
            name='is_send_email_to_organisation'
            value={is_send_email_to_organisation}
            control={<Checkbox color='primary' />}
            label='Notify Organization Via Email'
            labelPlacement='end'
            onClick={this.handleCheckBox}
          /><br/>
          <FormControlLabel
            name='is_send_email_to_referral'
            value={is_send_email_to_referral}
            control={<Checkbox color='primary' />}
            label='Notify Referral Via Email'
            labelPlacement='end'
            onClick={this.handleCheckBox}
          />
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Grid container justify='flex-end' spacing={1} className={classes.type}>
            <Button
              color='primary'
              variant='outlined'
              className={classes.buttonText}
              onClick={this.handleSubmitPopUpClose}
            >
              Close
            </Button> &nbsp;&nbsp;
            <Button
              color='primary'
              variant='contained'
              className={classes.buttonText}
              onClick={this.handleAddProfile}
            >
              Save Changes
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }

  handleTestCountWarningClose = () => {
    this.setState({ testCountWarning: false })
  }

  handleAddProfile = async() => {
    const { test_name, display_code, outsource_test, profile_tests, integration_code, target_tat_in_time, short_text, test_description,
      cost_of_test, minimum_selling_price, revenue_cap, price, price_2, is_send_email_to_organisation, is_send_email_to_referral, branch_id,
      profileref, deleted_tests, profile_price_id,
    } = this.state;

    const { edit } = this.props;

    let data ={
      test_name, display_code, outsource_test, profile_tests, integration_code, target_tat_in_time, short_text, test_description,
      cost_of_test, minimum_selling_price, revenue_cap, price, price_2, is_send_email_to_organisation, is_send_email_to_referral, branch_id,
      profileref, deleted_tests, profile_price_id,
    }

    if(!edit) {
      if(profile_tests.length > 1) {
        await this.props.createTestProfile(data);
        if(this.props.createTestProfileSuccess) {
          window.location.replace('/profile-list')
        }
      } else this.setState({ openSubmitPopUp: false, testCountWarning: true })
    } else if(profile_tests.length > 1) {
      await this.props.updateTestProfile(data);
      if(this.props.profileNameError.length !== 0) {
        this.setState({ openSubmitPopUp: false })
      } else if(this.props.updateTestProfileSuccess) {
        window.location.replace('/profile-list')
      }
    } else this.setState({ openSubmitPopUp: false, testCountWarning: true })
  }

  render() {
    const { openAddProfile, profile_tests, openTestList, testName, openSubmitPopUp, test_name, outsource_test, integration_code,
      test_description, display_code, price, price_2, minimum_selling_price, cost_of_test, revenue_cap, short_text,
      target_tat_in_time, duplicateTestWarning, testCountWarning,
    } = this.state;

    const { searchTestList, edit, profileNameError } = this.props;
    return (
      <Grid container>
        <Dialog
          open={openAddProfile}
          maxWidth='md'
          fullWidth
          onClose={this.props.handleCloseDialog}
        >
          <DialogTitle id="alert-dialog-slide-title">
            <Grid container>
              <Grid item xs={11}>
                { !edit ? 'Add Profile' : 'Edit Profile' }
              </Grid>
              <Grid item xs={1}>
                <Grid container justify='flex-end'>
                  <IconButton onClick={this.props.handleCloseDialog} >
                    <Close fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </DialogTitle>
          <Divider/>
          <Grid
            container
            classes={{root: classes.containerPadding}} 
            className={classes.type}
            justify='center'
            alignItems='center'
          >
            <Grid item xs={12}>
              {
                duplicateTestWarning === true ?
                  <Grid container alignItems='center' justify='center' spacing={1} className={classes.redWarningStyles}>
                    <Grid item xs={11} className={classes.type}>
                      <Typography variant='subtitle2'><b>Error!</b>Test already exists in the profile.</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton onClick={this.handleTestDuplicateWarningClose}>
                        <Close fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>
                : ''
              }
            </Grid>
            <Grid item xs={12}>
              {
                testCountWarning === true ?
                  <Grid container alignItems='center' justify='center' spacing={1} className={classes.redWarningStyles}>
                    <Grid item xs={11} className={classes.type}>
                      <Typography variant='subtitle2'><b>Error!</b>Please add more than one test in the profile.</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton onClick={this.handleTestCountWarningClose}>
                        <Close fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>
                : ''
              }
            </Grid>
            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> Profile Name : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <TextField
                name='test_name'
                value={test_name}
                onChange={this.handleChange}
                fullWidth
                margin='dense'
                variant='outlined'
                placeholder='Enter name of the Profile to add'
              />
              {profileNameError.length !== 0 ? <div className={classes.error}>{profileNameError}</div> : ''}
            </Grid>
  
            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> Profile Code : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <TextField
                name='display_code'
                value={display_code}
                onChange={this.handleChange}
                fullWidth
                margin='dense'
                variant='outlined'
                placeholder='Profile Code'
              />
            </Grid>
  
            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> Outsource : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <TextField
                name='outsource_test'
                value={outsource_test}
                onChange={this.handleChange}
                select
                fullWidth
                margin='dense'
                variant='outlined'
                placeholder=''
              >
                <MenuItem value={1} key={1}>SELF</MenuItem>
                <MenuItem value={2} key={2}>Central</MenuItem>
              </TextField>
            </Grid>
  
            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> Add Test to Profile : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <Autocomplete
                id="asynchronous-search"
                open={openTestList}
                onOpen={() => {
                  this.setState({ openTestList: true })
                }}
                onClose={() => {
                  this.setState({ openTestList: false });
                }}
                value={testName}
                onChange={(e, newValue) => this.handleAddTestMenuClick(newValue)}
                getOptionLabel={(option) => option !== '' ?  testName : option}
                renderOption={(option) => (
                  <React.Fragment>
                    <Grid container>
                      <Grid item xs={12}><Typography variant='body2'>{option.test_name}</Typography></Grid>
                      <Grid item xs={12}><Typography variant='body2'>Price: {option.price}</Typography></Grid>
                      <Grid item xs={12}><Divider /></Grid>
                    </Grid>
                  </React.Fragment>
                )}
                options={searchTestList}
                loading={searchTestList.length === 0 ? false : true}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    margin='dense'
                    placeholder='Search Test to add / edit to profile'
                    className={classes.backgroundColor}
                    onChange={e => this.handleSearchTest(e.target.name, e.target.value)}
                    {...params}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {openTestList && searchTestList.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
  
            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> Integration Code : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <TextField
                name='integration_code'
                value={integration_code}
                onChange={this.handleChange}
                fullWidth
                margin='dense'
                variant='outlined'
                placeholder='Integration Code'
              />
            </Grid>
            <Grid item xs={5}></Grid>
  
            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> Analytical Target TAT : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <TextField
                name='target_tat_in_time'
                value={target_tat_in_time}
                onChange={this.handleChange}
                fullWidth
                margin='dense'
                variant='outlined'
                placeholder='Enter Target TAT in hrs.'
              />
            </Grid>
            <Grid item xs={5}></Grid>
  
            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> Short Description : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <TextField
                name='short_text'
                value={short_text}
                onChange={this.handleChange}
                fullWidth
                margin='dense'
                variant='outlined'
                placeholder='short description of Profile'
              />
            </Grid>
  
            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> Profile Description : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <TextField
                name='test_description'
                value={test_description}
                onChange={this.handleChange}
                fullWidth
                margin='dense'
                variant='outlined'
                placeholder='Profile Description'
              />
            </Grid>
  
            <Grid item xs={12} className={classes.dividerPadding}>
              <Divider/>
            </Grid>
  
            {
              profile_tests.length !== 0 ? 
                <React.Fragment>
                  <Grid container justify='flex-start' alignItems='center'>
                    <Grid item xs={1}>
                      <Typography>No.</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography> Test Name ( Count : {profile_tests.length} ) </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography> Test Price </Typography>
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                  </Grid>
                </React.Fragment>
              : ''
            }
  
            <Grid item xs={12} className={classes.dividerPadding}><Divider/></Grid>
            
            {
              profile_tests.length !== 0 ? profile_tests.map((item, index) => {
                return(
                  <React.Fragment key={index}>
                    <Grid container alignItems='center'>
                      <Grid item xs={1}><Typography>{index+1}</Typography></Grid>
                      <Grid item xs={7}><Typography>{item.test_name}</Typography></Grid>
                      <Grid item xs={3}>
                        <TextField
                          value={item.price} 
                          onChange={e => this.handleTextChange(e, index)}
                          variant='outlined'
                          margin='dense'
                        />
                      </Grid>
                      <Grid item xs={1}><Close onClick={e => this.handleDeleteTest(item.test_id)}/></Grid>
                      <Grid item xs={12}><Divider className={classes.dividerPadding}/></Grid>
                    </Grid>
                  </React.Fragment>
                )}) 
              : ''
            }
            {
              profile_tests.length !== 0 ? 
                <Grid container justify='flex-start' alignItems='center'>
                  <Grid item xs={3}><Typography align='center'><b>Tests Cost:</b></Typography></Grid>
                  <Grid item xs={1}>
                    <TextField
                      name='cost_of_test'
                      value={cost_of_test}
                      onChange={this.handleChange}
                      fullWidth
                      margin='dense'
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={3}><Typography align='center'><b>Minimum selling price:</b></Typography></Grid>
                  <Grid item xs={1}>
                    <TextField
                      name='minimum_selling_price'
                      value={minimum_selling_price}
                      onChange={this.handleChange}
                      fullWidth
                      margin='dense'
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={3}><Typography align='center'><b>Revenue CAP:</b></Typography></Grid>
                  <Grid item xs={1}>
                    <TextField
                      name='revenue_cap'
                      value={revenue_cap}
                      onChange={this.handleChange}
                      fullWidth
                      margin='dense'
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    *Cost Of Test should be less than the minimum selling price and test price.<br/>
                    *Minimum selling price should be greater than the cost of test and less than test price.<br/>
                    *Enter valid amount.<br/>
                    *Cost Of Test should be less than the minimum selling price and test price.<br/>
                    *Enter valid amount.
                  </Grid>
  
                  <Grid item xs={12}><Divider className={classes.dividerPadding}/></Grid>
  
                  <Grid item xs={2}><Typography><b>Profile Price:</b></Typography></Grid>
                  <Grid item xs={3}>
                    <TextField
                      name='price'
                      value={price}
                      onChange={this.handleChange}
                      fullWidth
                      margin='dense'
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={4}><Typography align='center'><b>Profile Price 2 (if any):</b></Typography></Grid>
                  <Grid item xs={3}>
                    <TextField
                      name='price_2'
                      value={price_2}
                      onChange={this.handleChange}
                      fullWidth
                      margin='dense'
                      variant='outlined'
                    />
                  </Grid>
                </Grid>
              : ''
            }
          </Grid>
  
          <Divider/>
  
          <Grid container justify='center' className={classes.type}>
            <Button
              color='primary'
              variant='contained'
              className={classes.buttonText}
              onClick={this.handleSubmitPopUpOpen}
            >
              {edit ? 'Update' : 'Submit'}
            </Button>
          </Grid>
        </Dialog>
        {openSubmitPopUp ? this.handleSubmitPopUp() : ''}
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  searchTestList: state.login.searchTest,
  createTestProfileSuccess: state.login.createTestProfileSuccess,
  updateTestProfileSuccess: state.login.updateTestProfileSuccess,
  profileNameError: state.login.profileErrorMessages.profileNameError,
})

export default connect(mapStateToProps, {
  searchTest,
  createTestProfile,
  updateTestProfile,
})(AddEditProfile);