import React from 'react';
import { connect } from 'react-redux';

import {
  List,
  ListItem,
  ListItemText,
  Link,
  Collapse,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { logout } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class AnalyticsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openDepartmentAnalytics: false
    }
  }

  handleDepartmentAnalytics = () => {
    this.setState({ openDepartmentAnalytics: !this.state.openDepartmentAnalytics })
  }

  handleLogout = () => {
    this.props.logout();
  }

  render() {
    const { openDepartmentAnalytics } = this.state;
    return (
      <List>
        <ListItem button>
          <Link underline="none" color="inherit" >
            <ListItemText> <div className={classes.drawerMainList}> Finance Analytics </div> </ListItemText>
          </Link>
        </ListItem>

        <ListItem button onClick={this.handleDepartmentAnalytics}>
          <ListItemText> <div className={classes.drawerMainList}> Departmentwise Analytics </div> </ListItemText>
          {openDepartmentAnalytics ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openDepartmentAnalytics} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.listStyle}>
            <ListItem button>
              <Link underline="none" color="inherit"> Graph View </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Export </Link>
            </ListItem>
          </List>
        </Collapse>

        <ListItem button>
          <Link underline="none" color="inherit" >
            <ListItemText> <div className={classes.drawerMainList}> Outsource Analytics </div> </ListItemText>
          </Link>
        </ListItem>

        <ListItem button>
          <Link underline="none" color="inherit" >
            <ListItemText> <div className={classes.drawerMainList}> Marketing Analytics </div> </ListItemText>
          </Link>
        </ListItem>

        <ListItem button>
          <Link underline="none" color="inherit" >
            <ListItemText> <div className={classes.drawerMainList}> Test Analytics </div> </ListItemText>
          </Link>
        </ListItem>

        <ListItem button>
          <Link underline="none" color="inherit" >
            <ListItemText> <div className={classes.drawerMainList}> TAT Analytics </div> </ListItemText>
          </Link>
        </ListItem>

        <ListItem button onClick={this.handleLogout}>
          <Link underline="none" color="inherit" >
            <ListItemText> <div className={classes.drawerMainList}> Logout </div> </ListItemText>
          </Link>
        </ListItem>
      </List>
    );
  }
}

export default connect(null, { logout })(AnalyticsList);