import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Button,
  Divider,
  MenuItem,
} from '@material-ui/core';

import { getBillSetting, updateBillSetting } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class BillSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      billsettingref: '',
      bill_font_size: 0,
      bill_font_family: 0,
      bill_paper_size: 0,
      header_height: '',
      footer_height: '',
      bill_paper_mode: '',
      bill_paper_width: '',
      bill_paper_height: '',
      bill_paper_margin: '',
      barcode_abbreviation: '',
      tds_concession: '',
      pre_set_amount: '',
      is_bill_header_flag: '0',
      is_bill_footer_flag: '0',
      is_bill_signature_flag: '0',
      is_barcode_flag: '0',
      is_sampletype_on_barcode: '0',
      is_test_name: '0',
      is_short_test_names: '0',
      is_collection_date: '0',
      cardType: 'Patient ID/Manual Patient ID',
      labLogo: 'Enable'
    }
    this.handleBillSettings();
  }

  handleBillSettings = async() => {
   await this.props.getBillSetting();
   const { billSettings } = this.props;
   this.setState({
      billsettingref: billSettings.id,
      bill_font_size: billSettings.bill_setting.bill_font_size,
      bill_font_family: billSettings.bill_setting.bill_font_family,
      bill_paper_size: billSettings.bill_setting.bill_paper_size,
      header_height: billSettings.bill_setting.header_height,
      footer_height: billSettings.bill_setting.footer_height,
      bill_paper_mode: billSettings.bill_setting.bill_paper_mode,
      bill_paper_width: billSettings.bill_setting.bill_paper_width,
      bill_paper_height: billSettings.bill_setting.bill_paper_height,
      bill_paper_margin: billSettings.bill_setting.bill_paper_margin,
      barcode_abbreviation: billSettings.bill_setting.barcode_abbreviation,
      tds_concession: billSettings.bill_setting.tds_concession,
      pre_set_amount: billSettings.bill_setting.pre_set_amount,
      is_bill_header_flag: billSettings.bill_setting.is_bill_header_flag,
      is_bill_footer_flag: billSettings.bill_setting.is_bill_footer_flag,
      is_bill_signature_flag: billSettings.bill_setting.is_bill_signature_flag,
      is_barcode_flag: billSettings.bill_setting.is_barcode_flag,
      is_sampletype_on_barcode: billSettings.bill_setting.is_sampletype_on_barcode,
      is_test_name: billSettings.bill_setting.is_test_name,
      is_short_test_names: billSettings.bill_setting.is_short_test_names,
      is_collection_date: billSettings.bill_setting.is_collection_date,
      cardType: billSettings.bill_setting.cardType,
      labLogo: billSettings.bill_setting.labLogo,
   })
  }

  handleCheckBox = (e) => {
    this.setState({ [e.target.name]: e.target.checked === true ? '1' : '0' })
  }

  handleRadioButton = (e) => {
    if (e.target !== undefined && e.target.value !== undefined) {
      this.setState({ [e.target.name]: e.target.value })
    } else this.setState({ [e.target.value]: '' })
  }

  handleTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = async() => {
    const { billsettingref } = this.state;
    let bill_setting = {}
    bill_setting = {
      bill_font_size: this.state.bill_font_size,
      bill_font_family: this.state.bill_font_family,
      bill_paper_size: this.state.bill_paper_size,
      header_height: this.state.header_height,
      footer_height: this.state.footer_height,
      bill_paper_mode: this.state.bill_paper_mode,
      bill_paper_width: this.state.bill_paper_width,
      bill_paper_height: this.state.bill_paper_height,
      bill_paper_margin: this.state.bill_paper_margin,
      barcode_abbreviation: this.state.barcode_abbreviation,
      tds_concession: this.state.tds_concession,
      pre_set_amount: this.state.pre_set_amount,
      is_bill_header_flag: this.state.is_bill_header_flag,
      is_bill_footer_flag: this.state.is_bill_footer_flag,
      is_bill_signature_flag: this.state.is_bill_signature_flag,
      is_barcode_flag: this.state.is_barcode_flag,
      is_sampletype_on_barcode: this.state.is_sampletype_on_barcode,
      is_test_name: this.state.is_test_name,
      is_short_test_names: this.state.is_short_test_names,
      is_collection_date: this.state.is_collection_date,
      cardType: this.state.cardType,
      labLogo: this.state.labLogo,
    }
    await this.props.updateBillSetting({bill_setting, billsettingref})
    if(this.props.updatebillSettingsSuccess) {
      window.location.reload();
    }
  }

  render() {
    const { bill_font_size, bill_font_family, bill_paper_size, header_height, footer_height, bill_paper_mode, bill_paper_width, bill_paper_height,
      bill_paper_margin, barcode_abbreviation, tds_concession, pre_set_amount, is_bill_header_flag, is_bill_footer_flag, is_bill_signature_flag, 
      is_barcode_flag, is_sampletype_on_barcode, is_test_name, is_short_test_names, is_collection_date, cardType, labLogo,
    } = this.state;
    return (
      <Container component='main' maxWidth={false} classes={{root: classes.containerPadding}}>
        <Grid container direction='row' justify='flex-start' className={classes.drawerMainList}>
          <Grid item xs={12}>
            <h3>Bill Receipt Settings</h3>
          </Grid>
          <Grid item xs={12}>
            Settings related to format of bill receipt.
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.dividerPadding} />
          </Grid>

          <Grid item xs={12}>
            <Grid container direction='row' alignItems='center' spacing={2}>
              <Grid item xs={3}>
                <Grid container direction='row' justify='flex-end'>
                  Bill Font Size:
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name='bill_font_size'
                  value={bill_font_size}
                  onChange={this.handleTextChange}
                  select
                  fullWidth
                  variant='outlined'
                  margin='dense'
                >
                  <MenuItem key={1} value={1}>1</MenuItem>
                  <MenuItem key={2} value={2}>2</MenuItem>
                  <MenuItem key={3} value={3}>3</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={3}>
                <Grid container direction='row' justify='flex-end'>
                  Bill Font Family:
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name='bill_font_family'
                  value={bill_font_family}
                  onChange={this.handleTextChange}
                  select
                  fullWidth
                  variant='outlined'
                  margin='dense'
                >
                  <MenuItem key={1} value={1}>Times New Roman</MenuItem>
                  <MenuItem key={2} value={2}>Times</MenuItem>
                  <MenuItem key={3} value={3}>Arial</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction='row' alignItems='center' spacing={2}>
              <Grid item xs={3}>
                <Grid container direction='row' justify='flex-end'>
                  Bill Paper Size:
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name='bill_paper_size'
                  value={bill_paper_size}
                  onChange={this.handleTextChange}
                  select
                  fullWidth
                  variant='outlined'
                  margin='dense'
                >
                  <MenuItem key={1} value={1}>A4</MenuItem>
                  <MenuItem key={2} value={2}>A5</MenuItem>
                  <MenuItem key={3} value={3}>A6</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={3}>
                <Grid container direction='row' justify='flex-end'>
                  Header Height:
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name='header_height'
                  value={header_height}
                  onChange={this.handleTextChange}
                  fullWidth
                  variant='outlined'
                  margin='dense'
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction='row' alignItems='center' spacing={2}>
              <Grid item xs={3}>
                <Grid container direction='row' justify='flex-end'>
                  Footer Height:
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name='footer_height'
                  value={footer_height}
                  onChange={this.handleTextChange}
                  fullWidth
                  variant='outlined'
                  margin='dense'
                />
              </Grid>

              <Grid item xs={3}>
                <Grid container direction='row' justify='flex-end'>
                  Bill Paper Mode:
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name='bill_paper_mode'
                  value={bill_paper_mode}
                  onChange={this.handleTextChange}
                  select
                  fullWidth
                  variant='outlined'
                  margin='dense'
                >
                  <MenuItem key={1} value={1}>Landscape</MenuItem>
                  <MenuItem key={2} value={2}>Portrait</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction='row' alignItems='center' spacing={2}>
              <Grid item xs={3}>
                <Grid container direction='row' justify='flex-end'>
                  Bill Paper Width:
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name='bill_paper_width'
                  value={bill_paper_width}
                  onChange={this.handleTextChange}
                  fullWidth
                  variant='outlined'
                  margin='dense'
                />
              </Grid>

              <Grid item xs={3}>
                <Grid container direction='row' justify='flex-end'>
                  Bill Paper Height:
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name='bill_paper_height'
                  value={bill_paper_height}
                  onChange={this.handleTextChange}
                  fullWidth
                  variant='outlined'
                  margin='dense'
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction='row' alignItems='center' spacing={2}>
              <Grid item xs={3}>
                <Grid container direction='row' justify='flex-end'>
                  Bill Paper Margin:
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name='bill_paper_margin'
                  value={bill_paper_margin}
                  onChange={this.handleTextChange}
                  fullWidth
                  variant='outlined'
                  margin='dense'
                />
              </Grid>

              <Grid item xs={3}>
                <Grid container direction='row' justify='flex-end'>
                  Barcode Abbreviation:
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name='barcode_abbreviation'
                  value={barcode_abbreviation}
                  onChange={this.handleTextChange}
                  fullWidth
                  variant='outlined'
                  margin='dense'
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction='row' alignItems='center' spacing={2}>
              <Grid item xs={3}>
                <Grid container direction='row' justify='flex-end'>
                  TDS Concession %:
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name='tds_concession'
                  value={tds_concession}
                  onChange={this.handleTextChange}
                  fullWidth
                  variant='outlined'
                  margin='dense'
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <span className={classes.label}>Note - </span>
            TDS will be calculated over the bills having amount greater than 30,000.
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.dividerPadding} />
          </Grid>

          <Grid item xs={3}>
            <h4>Pre-set Additional Amount</h4>
          </Grid>
          <Grid item xs={2}>
            <TextField
              name='pre_set_amount'
              value={pre_set_amount}
              type='number'
              onChange={this.handleTextChange}
              fullWidth
              variant='outlined'
              margin='dense'
            />
          </Grid>
          <Grid item xs={1}>
            <h4 className={classes.collectButtonPadding}>%</h4>
          </Grid>

          <Grid item xs={12}>
            <div className={clsx(classes.collectButtonPadding, classes.fontColor)}>
              (Set fixed additional amounts like Government taxes which will be added to bill amount(in %))
            </div>
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.dividerPadding} />
          </Grid>

          <Grid item xs={3}>
            <FormControlLabel
              name='is_bill_header_flag'
              value={is_bill_header_flag}
              checked={is_bill_header_flag === '1' ? true : false}
              onClick={this.handleCheckBox}
              control={<Checkbox color='primary' />}
              label='Bill Header Flag'
              labelPlacement='end'
              classes={{
                label: classes.label
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <FormControlLabel
              name='is_bill_footer_flag'
              value={is_bill_footer_flag}
              checked={is_bill_footer_flag === '1' ? true : false}
              onClick={this.handleCheckBox}
              control={<Checkbox color='primary' />}
              label='Bill Footer Flag'
              labelPlacement='end'
              classes={{
                label: classes.label
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <FormControlLabel
              name='is_bill_signature_flag'
              value={is_bill_signature_flag}
              checked={is_bill_signature_flag === '1' ? true : false}
              onClick={this.handleCheckBox}
              control={<Checkbox color='primary' />}
              label='Bill Signature Flag'
              labelPlacement='end'
              classes={{
                label: classes.label
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <FormControlLabel
              name='is_barcode_flag'
              value={is_barcode_flag}
              checked={is_barcode_flag === '1' ? true : false}
              onClick={this.handleCheckBox}
              control={<Checkbox color='primary' />}
              label='Barcode Flag'
              labelPlacement='end'
              classes={{
                label: classes.label
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <FormControlLabel
              name='is_sampletype_on_barcode'
              value={is_sampletype_on_barcode}
              checked={is_sampletype_on_barcode === '1' ? true : false}
              onClick={this.handleCheckBox}
              control={<Checkbox color='primary' />}
              label='Sample Type on Barcode'
              labelPlacement='end'
              classes={{
                label: classes.label
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <FormControlLabel
              name='is_test_name'
              value={is_test_name}
              checked={is_test_name === '1' ? true : false}
              onClick={this.handleCheckBox}
              control={<Checkbox color='primary' />}
              label='Test Name'
              labelPlacement='end'
              classes={{
                label: classes.label
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <FormControlLabel
              name='is_short_test_names'
              value={is_short_test_names}
              checked={is_short_test_names === '1' ? true : false}
              onClick={this.handleCheckBox}
              control={<Checkbox color='primary' />}
              label='Short Test Names'
              labelPlacement='end'
              classes={{
                label: classes.label
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <FormControlLabel
              name='is_collection_date'
              value={is_collection_date}
              checked={is_collection_date === '1' ? true : false}
              onClick={this.handleCheckBox}
              control={<Checkbox color='primary' />}
              label='Collection Date'
              labelPlacement='end'
              classes={{
                label: classes.label
              }}
            />
          </Grid>

          {/* <Grid item xs={3}>
            <FormControlLabel
              value='end'
              control={<Checkbox color='primary' icon={<span className={classes.icon} />} />}
              label='Allow Back Dated Settlements'
              labelPlacement='end'
              classes={{
                label: classes.label
              }}
            />
          </Grid> */}

          <Grid item xs={12}>
            <Divider className={classes.dividerPadding} />
          </Grid>

          <Grid item xs={12} className={classes.label}>
            Patient Print Card
          </Grid>

          <Grid item xs={12} className={classes.collectButtonPadding}>
            <span className={classes.label}>Print Card Number -</span>
            <RadioGroup
              aria-label="cardType"
              name="cardType"
              value={cardType}
              onChange={this.handleRadioButton}
            >
              <Grid container direction='row'>
                <Grid item xs={3}>
                  <FormControlLabel
                    value="Patient ID/Manual Patient ID"
                    checked={cardType === 'Patient ID/Manual Patient ID'}
                    control={<Radio color="primary" />}
                    label="Patient ID/Manual Patient ID"
                    labelPlacement="end"
                    classes={{
                      label: classes.label
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormControlLabel
                    value="Patient ID with Location Code"
                    checked={cardType === 'Patient ID with Location Code'}
                    control={<Radio color="primary" />}
                    label="Patient ID with Location Code"
                    labelPlacement="end"
                    classes={{
                      label: classes.label
                    }}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>

          <Grid item xs={12} className={classes.collectButtonPadding}>
            <span className={classes.label}>Lab Logo in Header -</span>
            <RadioGroup
              aria-label="labLogo"
              name="labLogo"
              value={labLogo}
              onChange={this.handleRadioButton}
            >
              <Grid container direction='row'>
                <Grid item xs={3}>
                  <FormControlLabel
                    value="Enable"
                    checked={labLogo === 'Enable'}
                    control={<Radio color="primary" />}
                    label="Enable"
                    labelPlacement="end"
                    classes={{
                      label: classes.label
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormControlLabel
                    value="Disable"
                    checked={labLogo === 'Disable'}
                    control={<Radio color="primary" />}
                    label="Disable"
                    labelPlacement="end"
                    classes={{
                      label: classes.label
                    }}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>

          <Grid item xs={12} className={clsx(classes.collectButtonPadding, classes.textAlign, classes.fontColor)}>
            (You can update your center logo in Center Management -> Resources -> Center Logo)
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.dividerPadding} />
          </Grid>

          <Grid item xs={12}>
            <Grid container justify='center'>
              <Grid item>
                <Button
                  type="submit"
                  color="primary"
                  size="medium"
                  variant="contained"
                  className={clsx(classes.buttonText, classes.submitButton)}
                  onClick={this.handleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  billSettings: state.login.billSettings,
  updatebillSettingsSuccess: state.login.updatebillSettingsSuccess,
})

export default connect(mapStateToProps, {
  getBillSetting,
  updateBillSetting,
})(BillSettings);