import React from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Grid,
  Tabs,
  Tab,
  TextField,
  MenuItem,
  Divider,
  Button,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
} from '@material-ui/core';

import {
  FilterList,
  Refresh,
} from '@material-ui/icons';

import { getOperationTests } from '../../Store/ActionCreators';
import TableHeadSort from '../../Common/TableHeadSort';

import classes from '../../App.module.css';

class HistoryOrDispatched extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      historyTab: 0,
      rows: [],
      page: 0,
      rowsPerPage: 15,
      order: 'asc',
      orderBy: 'doctor_name',
      openSearch: false,
    }
    this.handleHistoryTests()
  }

  handleHistoryTests = async () => {
    await this.props.getOperationTests({ test_status: 7 })
    this.setState({ rows: this.props.operationTests.list })
    console.log(this.props.operationTests)
  }

  handleTabChange = (event, newValue) => {
    this.setState({ historyTab: newValue })
  }

  handleTabEvent = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  handlePageChange = (event, newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 })
  };

  handleRequestSort = (e, property) => {                                                //Searching
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  }

  handleIconButtonClick = () => {
    this.setState({ openSearch: !this.state.openSearch })
  }

  // handleDoctorNameChange = (e) => {
  //   var updatedList = this.state.rows;
  //   if (e.target.value !== '') {
  //     updatedList = updatedList.filter(function (item) {
  //       return item.doctor_name.toLowerCase().search(
  //         e.target.value.toLowerCase()) !== -1;
  //     });
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       rows: updatedList
  //     })
  //   } else {
  //     this.setState({ rows: this.props.doctorList })
  //   }
  // }

  // handleContactChange = (e) => {
  //   var updatedList = this.state.rows;
  //   if (e.target.value !== '') {
  //     updatedList = updatedList.filter(function (item) {
  //       return item.contact_number.toLowerCase().search(
  //         e.target.value.toLowerCase()) !== -1;
  //     });
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       rows: updatedList
  //     })
  //   } else {
  //     this.setState({ rows: this.props.doctorList })
  //   }
  // }

  // handleEmailChange = (e) => {
  //   var updatedList = this.state.rows;
  //   if (e.target.value !== '') {
  //     updatedList = updatedList.filter(function (item) {
  //       if(item.email_address !== null ) {
  //         return  item.email_address.toLowerCase().search(
  //           e.target.value) !== -1;
  //       }
  //     });
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       rows: updatedList
  //     })
  //   } else {
  //     this.setState({ rows: this.props.doctorList })
  //   }
  // }

  // handleCityChange = (e) => {
  //   var updatedList = this.state.rows;
  //   if (e.target.value !== '') {
  //     updatedList = updatedList.filter(function (item) {
  //       if(item.city !== null) {
  //         return item.city.toLowerCase().search(
  //           e.target.value.toLowerCase()) !== -1;
  //       }
  //     });
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       rows: updatedList
  //     })
  //   } else {
  //     this.setState({ rows: this.props.doctorList })
  //   }
  // }

  handleRequestSort = (e, property) => {                                              //Sorting
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  }

  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  render() {
    const headCells = [
      { id: "patient_id", label: "Patient Id" },
      { id: "patient_details", label: "Patient Details" },
      { id: "referral", label: "Referral" },
      { id: "organization", label: "Organization" },
      { id: "ready_reports", label: "Ready Reports" },
      { id: "pending_prints", label: "Pending Prints" },
    ];

    const { historyTab, rows, order, orderBy, openSearch, page, rowsPerPage } = this.state

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
      <Container component="main" maxWidth={false} classes={{ root: classes.containerPadding }}>
        <Grid container justify='center' alignItems='center' spacing={2}>
          <Grid item xs={9}>
            <Tabs
              value={historyTab}
              onChange={this.handleTabChange}
              indicatorColor="primary"
            >
              <Tab className={classes.buttonText} label="All" {...this.handleTabEvent(1)} />
              {/* <Tab className={classes.buttonText} label="Pending Prints" {...this.handleTabEvent(2)} /> */}
            </Tabs>
          </Grid>
          <Grid item xs={2}>
            <TextField
              select
              fullWidth
              value={1}
              disabled
              margin='dense'
              variant='outlined'
            >
              <MenuItem value={1}>  </MenuItem>
              <MenuItem value={2}> 1 </MenuItem>
              <MenuItem value={3}> 1 </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={1}>
            <TextField
              select
              fullWidth
              value={1}
              disabled
              margin='dense'
              variant='outlined'
            >
              <MenuItem value={1}>Worklist</MenuItem>
              <MenuItem value={2}>Worklist</MenuItem>
              <MenuItem value={3}>Worklist</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              margin='dense'
              variant='outlined'
              placeholder='Search by Patient ID / Patient Name / Accession Number / Bill ID / Order ID'
            />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <Grid container justify='center' alignItems='flex-end'>
              <Button
                color='primary'
                margin='dense'
                variant='contained'
                className={classes.buttonAccession}
              >
                Submit All
                    &nbsp;<span className={classes.badge} style={{ color: 'blue' }}>0</span>
              </Button>&nbsp;&nbsp;
                <Button
                color='primary'
                margin='dense'
                variant='outlined'
                className={classes.buttonAccession}
              >
                Filter Rows
                  <FilterList fontSize='small' />
              </Button>&nbsp;&nbsp;
                <Button
                color='primary'
                margin='dense'
                variant='outlined'
                className={classes.buttonAccession}
              >
                Refresh
                  <Refresh fontSize='small' />
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Table aria-label="simple table">
              <TableHead style={{ minWidth: 650 }} >
                <TableRow>
                  <TableHeadSort
                    headCells={headCells}
                    order={order}
                    orderBy={orderBy}
                    openSearch={openSearch}
                    onRequestSort={this.handleRequestSort}
                    handleButtonClick={this.handleIconButtonClick}
                  />
                </TableRow>
                {openSearch ?
                  <TableRow>
                    <TableCell>
                      <TextField
                        fullWidth
                        margin='dense'
                        variant='outlined'
                        placeholder='Patient Id'
                        onChange={this.handleDoctorNameChange}
                      />
                    </TableCell>
                    <TableCell align='left'>
                      <TextField
                        fullWidth
                        margin='dense'
                        variant='outlined'
                        placeholder='Patient Details'
                        onChange={this.handleContactChange}
                      />
                    </TableCell>
                    <TableCell align='left'>
                      <TextField
                        margin='dense'
                        variant='outlined'
                        placeholder='Referral'
                        onChange={this.handleEmailChange}
                      />
                    </TableCell>
                    <TableCell align='left'>
                      <TextField
                        fullWidth
                        margin='dense'
                        variant='outlined'
                        placeholder='Organization'
                        onChange={this.handleCityChange}
                      />
                    </TableCell>
                  </TableRow>
                  : null
                }
              </TableHead>
              <TableBody>
                {this.stableSort((rowsPerPage > 0 && rows.length > 0
                  ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : rows), this.getComparator(order, orderBy)).map((row) => (
                    <TableRow key={row.id} id={row.id} onClick={e => this.handleEditDoctor(row)}>
                      <TableCell component="th" scope="row">
                        {row.patient_id}
                      </TableCell>
                      <TableCell align="left">{row.patient_name}&nbsp;({row.gender.charAt(0)}-{row.age})</TableCell>
                      <TableCell align="left">{row.referrer_name}</TableCell>
                      <TableCell align="left">{row.organisation_name}</TableCell>
                    </TableRow>
                  ))
                }
                {emptyRows > 0 && emptyRows === 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  operationTests: state.login.operationTests,
})

export default connect(mapStateToProps, {
  getOperationTests,
})(HistoryOrDispatched);