import React from 'react';
import { connect } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';

import {
  Container,
  Typography,
  Grid,
  Divider,
  TextField,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button,
  MenuItem,
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  Select,
} from '@material-ui/core';

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import ExpandMore from '@material-ui/icons/ExpandMore';

import {
  TextValidator,
} from '../../Material UI';
import {
  createTest, getTestSampleTypesList, getCategoryList, getAccessionDepartmentsList, searchTest, getReportTypeList, updateTest,
  getCenterList, getOutsourceCenterList,
} from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class AddReport extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      reportTab: 0,
      report_type: 'Pathology',
      test_name: '',
      price: '',
      price_2: '',
      minimum_selling_price: '',
      revenue_cap: 0,
      cost_of_test: '',
      outsource_type: '',
      processed_at: [],
      test_description: '',
      department_type: 0,
      outsource_test: '',
      outsourcing_center: 0,
      category: 0,
      test_code: '',
      parent_test: '',
      sample_type: 0,
      is_test_has_investigations: '0',
      is_test_contains_graph: '0',
      is_multi_unit: '0',
      processed_list: [],

      openPathologyList: false,
      openRadiologyList: false,
      openPathologyTestTypeList: false,
      openPathologyPDFList: false,
      openTestRangeList: false,
      openRadiologyTestTypeList: false,
      openRadiologyPDFList: false,
      openEditReport: false,
      openTest: false,

      editReport: false,
    }

    this.props.getCenterList();
    this.props.getCategoryList();
    this.props.getReportTypeList();
    this.props.getTestSampleTypesList();
    this.props.getOutsourceCenterList();
    this.props.getAccessionDepartmentsList();
    this.reset = this.state;
    if (this.props.location.state && this.props.location.state.isEdit) {
      Object.keys(this.props.location.state.row).forEach(item => {
        this.state[item] = this.props.location.state.row[item]
      })
    }
  }

  handleEditOnLoad = () => {

  }

  handleTabChange = (event, newValue) => {
    this.setState({ reportTab: newValue })
  }

  handleTabEvent = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  handleTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleRadioButton = (e) => {
    if (e.target !== undefined && e.target.value !== undefined) {
      this.setState({ report_type: e.target.value })
    } else this.setState({ report_type: '' })
  }

  handleChange = (id) => {
    this.setState({ report_type: id })
  }

  handleCheckBox = (e) => {
    this.setState({ [e.target.name]: (e.target.checked === true ? 1 : 0) })
  }

  handleOutsourceTypeChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
    if (e.target.value === '1') {
      this.setState({ processed_list: this.props.centerList })
    } else if (e.target.value === '2') {
      this.setState({ processed_list: this.props.outsourceCenterList })
    }
  }

  handleReportType = () => {
    const { report_type, price, price_2, minimum_selling_price, revenue_cap, cost_of_test, outsource_type, processed_at, processed_list,
      test_description, department_type, outsource_test, outsourcing_center, test_code, parent_test, category, sample_type,
    } = this.state;
    const { categoryList, testSampleTypesList, departmentsList, reportTypeList } = this.props;
    return (
      <Grid container direction='row' alignItems='center' spacing={2}>
        <Grid item xs={3}>
          <Grid container justify='flex-end'>
            Test Code(optional):
          </Grid>
        </Grid>
        <Grid item xs={9}>
          <TextField
            name='test_code'
            value={test_code}
            onChange={this.handleTextChange}
            fullWidth
            variant='outlined'
            margin='dense'
          />
        </Grid>
        <Grid item xs={3}>
          <Grid container justify='flex-end'>
            Department:
          </Grid>
        </Grid>
        <Grid item xs={9}>
          <TextField
            select
            name='department_type'
            value={department_type}
            onChange={this.handleTextChange}
            fullWidth
            variant='outlined'
            margin='dense'
          >
            {departmentsList.map((item, index) => <MenuItem key={index} value={item.id}>{item.department_name}</MenuItem>)}
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <Grid container justify='flex-end'>
            Test Price:
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <TextValidator
            name='price'
            value={price}
            onChange={this.handleTextChange}
            fullWidth
            variant='outlined'
            margin='dense'
            placeholder='Enter Test Price'
            validators={['required']}
            errorMessages={['']}
          />
        </Grid>
        <Grid item xs={3}>
          <Grid container justify='flex-end'>
            Test Price 2 (if any):
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <TextField
            name='price_2'
            value={price_2}
            onChange={this.handleTextChange}
            fullWidth
            variant='outlined'
            margin='dense'
            placeholder='Enter Center Pricing'
          />
        </Grid>

        <Grid item xs={3}>
          <Grid container justify='flex-end'>
            Minimum Selling Price:
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <TextField
            name='minimum_selling_price'
            value={minimum_selling_price}
            onChange={this.handleTextChange}
            fullWidth
            variant='outlined'
            margin='dense'
            placeholder='Max 7 digits'
          />
        </Grid>
        <Grid item xs={3}>
          <Grid container justify='flex-end'>
            Revenue Cap:
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <TextField
            name='revenue_cap'
            value={revenue_cap}
            onChange={this.handleTextChange}
            fullWidth
            variant='outlined'
            margin='dense'
            placeholder='Max 7 digits'
          />
        </Grid>

        <Grid item xs={3}>
          <Grid container justify='flex-end'>
            Category:
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <TextField
            name='category'
            value={category}
            select
            onChange={this.handleTextChange}
            fullWidth
            variant='outlined'
            margin='dense'
            placeholder='Max 7 digits'
          >
            {categoryList.map((item, index) => <MenuItem key={index} value={item.id}>{item.category_name}</MenuItem>)}
          </TextField>
        </Grid>

        <Grid item xs={3}>
          <Grid container justify='flex-end'>
            Sample Type:
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <TextField
            select
            name='sample_type'
            value={sample_type}
            onChange={this.handleTextChange}
            fullWidth
            variant='outlined'
            margin='dense'
            placeholder='Max 7 digits'
          >
            {testSampleTypesList.map((item, index) => <MenuItem key={index} value={item.id}>{item.test_sample_name}</MenuItem>)}
          </TextField>
        </Grid>

        <Grid item xs={3}>
          <Grid container justify='flex-end'>
            Cost Of Test:
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <TextField
            name='cost_of_test'
            value={cost_of_test}
            onChange={this.handleTextChange}
            fullWidth
            variant='outlined'
            margin='dense'
            placeholder='Max 7 digits'
          />
        </Grid>

        <Grid item xs={6}></Grid>

        <Grid item xs={3}>
          <Grid container justify='flex-end'>
            Outsource Type:
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <RadioGroup
            name='outsource_type'
            value={outsource_type}
            row
            aria-label='payment_type'
            onChange={this.handleOutsourceTypeChange}
          >
            <FormControlLabel
              value='0'
              checked={outsource_type === '0'}
              control={<Radio color='primary' />}
              label='None'
              labelPlacement='end'
            />
            <FormControlLabel
              value='1'
              checked={outsource_type === '1'}
              control={<Radio color='primary' />}
              label='Internal'
              labelPlacement='end'
            />
            <FormControlLabel
              value='2'
              checked={outsource_type === '2'}
              control={<Radio color='primary' />}
              label='External'
              labelPlacement='end'
            />
          </RadioGroup>
        </Grid>

        <Grid item xs={5}></Grid>
        {outsource_type === '0' ? '' : (outsource_type === '1' || outsource_type === '2' ?
          <React.Fragment>
            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                Processed At:
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Select
                multiple
                name='processed_at'
                value={processed_at}
                onChange={this.handleTextChange}
                fullWidth
                variant='outlined'
                margin='dense'
                placeholder='Max 7 digits'
              >
                {
                  processed_list.length !== 0 ? 
                    (outsource_type === '1' ? 
                        processed_list.list.map((item, index) => <MenuItem key={index} value={item.id}>{item.branch_name}</MenuItem>)
                      : (outsource_type === '2' ? 
                          processed_list.map((item, index) => <MenuItem key={index} value={item.id}>{item.outsourcecenter_name}</MenuItem>)
                        : ''
                      )
                    ) 
                  : ''
                }
              </Select>
            </Grid>
          </React.Fragment> : ''
        )}

        <Grid item xs={12}>
          <Grid container direction='row' alignItems='center' spacing={2}>
            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                Test Description:
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <TextField
                name='test_description'
                value={test_description || ''}
                onChange={this.handleTextChange}
                multiline
                fullWidth
                variant='outlined'
                margin='dense'
                placeholder='Enter Test Description'
              />
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid item xs={12}>
          <Grid container justify='flex-end'>
            <FormControlLabel
              value='end'
              control={<Checkbox color='primary' />}
              label='Advance Editor'
              labelPlacement='end'
            />
          </Grid>
        </Grid> */}

        <Grid item xs={3}>
          <Grid container justify='flex-end'>
            Test Type:
          </Grid>
        </Grid>
        <Grid item xs={9}>
          {reportTypeList.map((item, index) =>
            <div key={index}>
              <FormControlLabel
                key={index}
                control={
                  <Radio
                    checked={report_type === item.id}
                    onChange={() => this.handleChange(item.id)}
                    value={report_type}
                    color='primary'
                  />
                }
                labelPlacement='end'
                label={item.report_name}
              />
            </div>
          )}
          {/* <RadioGroup
            aria-label="report_type"
            name="report_type"
            value={report_type}
            onChange={this.handleRadioButton}>
              {reportTypeList.map((item, index) => 
                <FormControlLabel
                  key={index}
                  value={item.id}
                  checked={report_type === 1}
                  control={<Radio color='primary' />}
                  label={item.report_name}
                  labelPlacement='end'
                />  
              )} */}
          {/* <FormControlLabel
              value="Pathology"
              checked={report_type === 'Pathology'}
              control={<Radio color="primary" />}
              label="Pathology"
              labelPlacement="end"
            />
            <FormControlLabel
              value="Radiology"
              checked={report_type === 'Radiology'}
              control={<Radio color="primary" />}
              label="Radiology"
              labelPlacement="end"
            />
            <FormControlLabel
              value="File Report"
              checked={report_type === 'File Report'}
              control={<Radio color="primary" />}
              label="File Report"
              labelPlacement="end"
            /> */}
          {/* </RadioGroup> */}
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            name='outsource_test'
            value={outsource_test}
            onClick={this.handleCheckBox}
            control={<Checkbox color='primary' />}
            label='This test is completely outsourced(Only bill generated.No reports.)'
            labelPlacement='end'
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            value='end'
            control={<Checkbox color='primary' />}
            label='This test has investigations'
            labelPlacement='end'
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            value='end'
            control={<Checkbox color='primary' />}
            label='This test contains graph'
            labelPlacement='end'
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            value='end'
            control={<Checkbox color='primary' />}
            label='Multi Unit'
            labelPlacement='end'
          />
        </Grid>

        <Grid item xs={12}>
          <ExpansionPanel square>
            <ExpansionPanelSummary
              expandIcon={<ExpandMore />}
              style={{ backgroundColor: '#eff0f1' }}
            >
              <Grid container justify='center'>
                Outsourcing Details(if applicable)
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    Outsourcing Center:
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    select
                    name='outsourcing_center'
                    value={outsourcing_center}
                    onChange={this.handleTextChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                  >
                    <MenuItem value={1}>SELF</MenuItem>
                    <MenuItem value={2}>Central Lab</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>

        <Grid item xs={12}>
          <ExpansionPanel square>
            <ExpansionPanelSummary
              expandIcon={<ExpandMore />}
              style={{ backgroundColor: '#eff0f1' }}
            >
              <Grid container justify='center'>
                Related Format Settings
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={12}>
                  <Grid container justify='center'>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='This test is related to another test'
                      labelPlacement='end'
                      classes={{ label: classes.payableAmount }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    Parent Test:
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name='parent_test'
                    value={parent_test}
                    onChange={this.handleTextChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify='center'>
            <Grid item>
              <Button
                type="submit"
                color="primary"
                size="medium"
                variant="contained"
                className={classes.buttonText}
                onSubmit={this.handleSaveChanges}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  handleEditTest = (value, type) => {
    if (value && type === 'edit' && value.testpricedetail !== null) {
      this.setState({
        openEditReport: true,
        testref: value.id,
        test_price_id: value.testpricedetail.id,

        test_name: value.test_name,
        price: value.testpricedetail.price,
        price_2: value.testpricedetail.price_2,
        minimum_selling_price: value.testpricedetail.minimum_selling_price,
        revenue_cap: value.testpricedetail.revenue_cap,
        cost_of_test: value.testpricedetail.cost_of_test,
        test_description: value.test_description,
        department_type: value.department_type,
        outsource_test: value.outsource_test,
        category: value.category,
        sample_type: value.sample_type,
        report_type: value.report_type,
        // test_code: '',
        // parent_test: '',
        // is_test_has_investigations: '0',
        // is_test_contains_graph: '0',
        // is_multi_unit: '0',
      })
    } else if (value && type === 'create') {
      this.setState({
        openEditReport: false,
        testref: 0,
        // test_price_id: 2112269,

        test_name: value.inputValue,
        price: 0,
        price_2: 0,
        minimum_selling_price: 0,
        revenue_cap: 0,
        cost_of_test: 0,
        test_description: null,
        department_type: '',
        outsource_test: '',
        category: '',
        sample_type: '',
        report_type: ''
      })
    }
  }

  handleSearchTest = async (name, value) => {
    if (!value) {
      this.setState({ [name]: value })
    } else {
      await this.props.searchTest({ test: value });
      if (this.state.reportTab === 0) {
        this.setState({ editReport: true })
      }
    }
  };

  handleDialogSlider = () => {
    const { openSlider } = this.state;
    return (
      <Dialog
        open={openSlider}
        // TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Confirm New Report Creation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography component={'span'} variant={'h6'}>
              Are you sure you want to create a new report? Or do you want to edit an existing report format?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleAddReport} variant='contained' className={classes.buttonText} color="primary">
            Yes! I want to create a new report
          </Button>
          <Button onClick={this.handleEditReport} variant='outlined' className={classes.buttonText} color="primary">
            No! I want to edit an existing report
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  handleAddReport = async () => {
    if (this.state.reportTab === 0) {
      await this.props.createTest(this.state)
    }
    if (this.props.createTestSuccess) {
      this.setState(this.reset);
      this.setState({ editReport: false, openSlider: false })
    } else this.setState({ editReport: false, openSlider: false })
  }

  handleEditReport = () => {
    this.setState({ reportTab: 1, openSlider: false })
  }

  handleClose = () => {
    this.setState({ openSlider: false })
  }

  handleSaveChanges = async (e) => {
    const { editReport } = this.state;
    if (this.state.reportTab === 0) {
      await this.props.createTest(this.state);
      if (this.props.createTestSuccess) {
        this.props.history.push({ pathname: '/testTypes', state: { testRef: this.props.addedTestId } })
      }
    } else if (this.state.reportTab === 1) {
      await this.props.updateTest(this.state);
    } if (this.props.updateTestSuccess) {
      this.setState(this.reset);
    }
  }

  createParams = () => {
    this.props.history.push({ pathname: '/testTypes', state: { testRef: this.props.location.state.row.id } })
  }

  render() {
    const { test_name, reportTab, openTest, openSlider } = this.state;
    const { searchTestList, testNameError } = this.props;
    const filter = createFilterOptions();

    return (
      <ValidatorForm onSubmit={this.handleSaveChanges}>
        <Grid container direction='row' justify='center'>

          <Grid item>
            <Container maxWidth={false} classes={{ root: classes.containerPadding }}>
              <Typography variant='subtitle1'>
                <Grid container direction='row' alignItems='center' spacing={2}>

                  {/* <Grid item xs={12}>
                    <Tabs
                      value={reportTab}
                      onChange={this.handleTabChange}
                      indicatorColor='primary'
                      variant='fullWidth'
                    >
                      <Tab
                        className={classes.buttonText}
                        style={{ fontSize: '16px' }}
                        label='Add Report'
                        {...this.handleTabEvent(1)}
                      />
                      <Tab
                        className={classes.buttonText}
                        style={{ fontSize: '16px' }}
                        label='Edit Report'
                        {...this.handleTabEvent(2)}
                      />
                    </Tabs>
                  </Grid> */}
                  {
                    (this.props.location.state && this.props.location.state.isEdit) &&
                    <Grid item xs={12}>
                      <Grid container direction='row' justify="flex-end" alignItems='center' spacing={2}>
                        <Grid item xs={3}>
                          <Button onClick={this.createParams} variant='contained' className={classes.buttonText} color="primary">
                            Create Params
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  }

                  <Grid item xs={12}>
                    <Grid container direction='row' alignItems='center' spacing={2}>
                      <Grid item xs={3}>
                        <Grid container justify='flex-end'>
                          Enter Test Name:
                        </Grid>
                      </Grid>
                      <Grid item xs={9}>
                        {
                          (this.props.location.state && this.props.location.state.isEdit) ?

                            <TextField
                              name='test_name'
                              value={test_name}
                              disabled={true}
                              fullWidth
                              variant='outlined'
                              margin='dense'
                            />
                            : <Autocomplete
                              id="asynchronous-search"
                              open={openTest}
                              freeSolo
                              onOpen={() => {
                                this.setState({ openTest: true })
                              }}
                              onClose={() => {
                                this.setState({ openTest: false });
                              }}
                              onChange={(e, newValue) => {
                                if (newValue && newValue.inputValue) {
                                  this.handleEditTest(newValue, 'create')
                                } else {
                                  this.handleEditTest(newValue, 'edit')
                                }
                              }}
                              getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                  return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return option.inputValue;
                                }
                                // Regular option
                                return option.test_name;
                              }}
                              renderOption={(option) => (
                                <React.Fragment>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      {option.inputValue ?
                                        <Typography>Add Test</Typography> :
                                        ''
                                      }
                                      <Typography>{option && option.test_name ? option.test_name : option.inputValue}</Typography>
                                      <Typography>Price: {option && option.testpricedetail ? option.testpricedetail.price : 0}</Typography>
                                    </Grid>
                                    <Grid item xs={12}><Divider /></Grid>
                                  </Grid>
                                </React.Fragment>
                              )}
                              options={searchTestList}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                // Suggest the creation of a new value
                                if (params.inputValue !== '') {
                                  filtered.push({
                                    inputValue: params.inputValue,
                                    title: `Add "${params.inputValue}"`,
                                  });
                                }

                                return filtered;
                              }}
                              loading={searchTestList.length === 0 ? false : true}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  margin='dense'
                                  placeholder='Enter Test Name'
                                  className={classes.backgroundColor}
                                  onChange={e => this.handleSearchTest(e.target.name, e.target.value)}
                                  {...params}
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {openTest && searchTestList.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              )}
                            />
                        }

                        {reportTab === 0 && testNameError !== [] ? <div className={classes.errorStyle}>{testNameError}</div> : ''}
                        {/* <TextValidator
                          name='test_name'
                          value={test_name}
                          onChange={this.handleTextChange}
                          fullWidth
                          variant='outlined'
                          margin='dense'
                          placeholder='Enter Test Name'
                          onClick={this.handleTestNameClick}
                          validators={['required']}
                          errorMessages={['']}
                        /> */}
                      </Grid>
                    </Grid>
                  </Grid>
                  {this.handleReportType()}

                  {/* {reportTab === 0 ? this.handleReportType() : (reportTab === 1 && openEditReport ? this.handleReportType() : '')} */}
                  {openSlider === true ? this.handleDialogSlider() : ''}

                </Grid>
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </ValidatorForm>
    );
  }
}

const mapStateToProps = state => ({
  createTestSuccess: state.login.createTestSuccess,
  categoryList: state.login.categoryList,
  testSampleTypesList: state.login.testSampleTypesList,
  departmentsList: state.login.departmentsList,
  searchTestList: state.login.searchTest,
  reportTypeList: state.login.reportTypeList,
  updateTestSuccess: state.login.updateTestSuccess,
  addedTestId: state.login.addedTestId,
  centerList: state.login.getCenterList,
  outsourceCenterList: state.login.outsourceCenterList,

  testNameError: state.login.testErrorMessage.testNameError,
})

export default connect(mapStateToProps, {
  createTest,
  getCategoryList,
  getTestSampleTypesList,
  getAccessionDepartmentsList,
  searchTest,
  getReportTypeList,
  updateTest,
  getCenterList,
  getOutsourceCenterList,
})(AddReport);