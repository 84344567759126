import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import {
  Container,
  Grid,
  Typography,
  Tabs,
  Tab,
  Button,
} from '@material-ui/core';

import PendingReceive from './PendingReceive';
import Active from './Active';
import Received from './Received';
import { getBatchList, profile } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class BatchData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 0,
      from_date: '',
      to_date: '',
      batchData: [],
    }
    this.handleBatchFullList()
  }

  debounce = (func, delay) => { 
    let debounceTimer 
    return function() { 
        const context = this
        const args = arguments 
            clearTimeout(debounceTimer) 
                debounceTimer 
            = setTimeout(() => func.apply(context, args), delay) 
    } 
  }
  
  handleBatchFullList = this.debounce(async() => {
    let status = '';
    const { from_date, to_date } = this.state;
    this.state.type === 0 ? status='Pending' : (this.state.type === 1 ? status='Active' : status='Receive')
    let reqObj = {
      batch_status: status,
      branch_id: this.props.profileData.branch_id,
      from_date,
      to_date,
    }
    await this.props.getBatchList(reqObj);
    this.setState({ batchData: this.props.batchList.list })
  },1000)

  handleTabChange = async(event, newValue) => {
    this.setState({ type: newValue })
    await this.handleBatchFullList();
  }

  handleTabEvent = index => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  handleEvent = (event, picker) => {
    if (event.type === 'apply') {
      this.setState(
        {
          from_date: picker.startDate,
          to_date: picker.endDate
        },
        () => {
          this.handleBatchFullList();
        }
      );
    }
  }

  render() {
    const { type, from_date, to_date, batchData } = this.state;
    return (
      <Container component="main" maxWidth={false} classes={{ root: classes.containerPadding }}>
        <Grid container spacing={1}>
          <Grid item xs={2}><Typography><b>Batch Management</b></Typography></Grid>
          <Grid item xs={10}></Grid>
          <Grid item xs={8}>
            <Tabs
              value={type}
              onChange={this.handleTabChange}
              indicatorColor="primary"
            >
              <Tab className={clsx(classes.buttonText, classes.drawerMainList)} label="Pending Receive" {...this.handleTabEvent(1)} />
              <Tab className={clsx(classes.buttonText, classes.drawerMainList)} label="Active (Sent)" {...this.handleTabEvent(2)} />
              <Tab className={clsx(classes.buttonText, classes.drawerMainList)} label="Received" {...this.handleTabEvent(3)} />
            </Tabs>
          </Grid>
          <Grid item xs={4}>
            <Grid container justify='flex-end'>
              <Button variant='outlined' className={classes.datePickerButton}>
                <DateRangePicker onEvent={this.handleEvent}>
                  {(from_date && to_date) ? <span>{from_date.format('DD-MM-YYYY').toString()} to {to_date.format('DD-MM-YYYY').toString()}</span> :
                    <span className={classes.buttonText}>Select Date Range</span>}
                </DateRangePicker>
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            { type === 0 ? <PendingReceive rows={batchData} reload={this.handleBatchFullList}/> : 
              (type === 1 ? <Active rows={batchData} reload={this.handleBatchFullList}/> : 
              (type === 2 ? <Received rows={batchData} reload={this.handleBatchFullList}/> : null)) }
          </Grid>
        </Grid>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  profileData: state.login.profile,
  batchList: state.login.batchList,
})

export default connect(mapStateToProps, {
  getBatchList,
  profile,
})(BatchData);