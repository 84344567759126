import React from 'react';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
} from '@material-ui/core';

import { activityLog } from '../Store/ActionCreators';
import classes from '../App.module.css';

class HomePage extends React.Component {
  render() {
    return (
      <Container component="main" maxWidth={false} classes={{root: classes.containerPadding}}>
        <Grid container direction="row" alignItems="center">
          <h1>
            HomePage
          </h1>
        </Grid>
      </Container>
    );
  }
}

export default connect(null, { activityLog })(HomePage);