import React from 'react';

import {
  Grid
} from '@material-ui/core';

class PatientDetailsRenderer extends React.Component {

  handlePatientDetails = (data) => {
    return (
      <Grid container>
        <Grid item xs={12}>
          {data.designation}&nbsp;{data.patient_name} ({data.gender.charAt(0)}-{data.age})
        </Grid>
      </Grid>
    );
  }

  render() {
    const data = this.props.node.data
    return (
      <div>
        {this.handlePatientDetails(data)}
      </div>
    );
  }
}

export default PatientDetailsRenderer;