import React from 'react';

import {
  TextField as TextFieldBase,
  Typography as TypographyBase,
} from '@material-ui/core';

import { TextValidator as TextValidatorBase } from 'react-material-ui-form-validator';

class TextField extends React.Component{
  render() {
    const {...rest}= this.props;  
    return (
      <TextFieldBase
        fullWidth
        variant='outlined'
        {...rest}
      />
    );
  }
}

class TextValidator extends React.Component{
  render() {
    const {...rest}= this.props;  
    return (
      <TextValidatorBase
        fullWidth
        variant='outlined'
        {...rest}
      />
    );
  }
}

class Typography extends React.Component{
  render() {
    const {...rest}= this.props;  
    return (
      <TypographyBase
        variant='h2'
        {...rest}
      />
    );
  }
}

export {
  TextField,
  TextValidator,
  Typography
}
