import React from 'react';
import { connect } from 'react-redux';

import {
  Grid,
  Dialog,
  DialogTitle,
  Divider,
  TextField,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';

import {
  Close,
} from '@material-ui/icons';

import { createDepartment, updateDepartment } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class AddEditDepartment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      department_name: '',
      department_code: '',
      departmentref: '',
      department_description: '',
      openAddDepartment: false,
      edit: false,
      duplicateTestWarning: false,
    }
  }

  componentDidMount() {
    const { data, open, edit } = this.props;
    if(edit) {
      this.setState({
        departmentref:data.id,
        department_name: data.department_name,
        department_code: data.department_code,
        department_description: data.department_description,
        openAddDepartment: open,
      })
    } else this.setState({ openAddDepartment: open })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = async() => {
    const { department_name, department_description, departmentref, department_code } = this.state;
    if(!this.props.edit) {
      await this.props.createDepartment({department_name, department_description, department_code})
      if(this.props.addDepartmentSuccess) {
        window.location.reload();
      }
    } else {
      await this.props.updateDepartment({departmentref, department_name, department_description, department_code})
      if(this.props.updateDepartmentSuccess) {
        window.location.reload();
      }
    }
  }

  render() {
    const { openAddDepartment, duplicateTestWarning, department_name, department_code, department_description } = this.state;
    const { handleCloseDialog, edit, departmentNameError, departmentCodeError, departmentDescriptionError } = this.props;
    return (
      <Grid container>
        <Dialog
          open={openAddDepartment}
          maxWidth='md'
          fullWidth
          onClose={handleCloseDialog}
        >
          <DialogTitle id="alert-dialog-slide-title">
            <Grid container>
              <Grid item xs={11}>
                { !edit ? 'Add Department' : 'Edit Department' }
              </Grid>
              <Grid item xs={1}>
                <Grid container justify='flex-end'>
                  <IconButton onClick={handleCloseDialog} >
                    <Close fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </DialogTitle>
          <Divider/>
          <Grid
            container
            classes={{root: classes.containerPadding}} 
            className={classes.type}
            justify='center'
            alignItems='center'
          >
            <Grid item xs={12}>
              {
                duplicateTestWarning === true ?
                  <Grid container alignItems='center' justify='center' spacing={1} className={classes.redWarningStyles}>
                    <Grid item xs={11} className={classes.type}>
                      <Typography variant='subtitle2'><b>Error!</b>Department already exists.</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton onClick={this.handleTestDuplicateWarningClose}>
                        <Close fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>
                : ''
              }
            </Grid>
            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> Department Name : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <TextField
                name='department_name'
                value={department_name}
                onChange={this.handleChange}
                fullWidth
                margin='dense'
                variant='outlined'
                placeholder='Enter the Department Name'
              />
              {departmentNameError !== undefined && departmentNameError.length !== 0 ? <div className={classes.error}>{departmentNameError}</div> : ''}
            </Grid>
  
            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> Department Code : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <TextField
                name='department_code'
                value={department_code}
                onChange={this.handleChange}
                fullWidth
                margin='dense'
                variant='outlined'
                placeholder='Enter the Department Code'
              />
              {departmentCodeError !== undefined && departmentCodeError.length !== 0 ? <div className={classes.error}>{departmentCodeError}</div> : ''}
            </Grid>

            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography> Department Description : </Typography>&nbsp;&nbsp;&nbsp;
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <TextField
                name='department_description'
                value={department_description}
                onChange={this.handleChange}
                fullWidth
                margin='dense'
                variant='outlined'
                placeholder='Enter the Department Code'
              />
              {departmentDescriptionError !== undefined && departmentDescriptionError.length !== 0 ? <div className={classes.error}>{departmentDescriptionError}</div> : ''}
            </Grid>
          </Grid>
          <Divider/>
  
          <Grid container justify='center' className={classes.type}>
            <Button
              color='primary'
              variant='contained'
              className={classes.buttonText}
              onClick={this.handleSubmit}
            >
              {edit ? 'Update' : 'Submit'}
            </Button>
          </Grid>
        </Dialog>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => ({
  addDepartmentSuccess: state.login.addDepartmentSuccess,
  updateDepartmentSuccess: state.login.updateDepartmentSuccess,
  departmentNameError: state.login.errorMessagesDepartment.departmentNameError,
  departmentCodeError: state.login.errorMessagesDepartment.departmentCodeError,
  departmentDescriptionError: state.login.errorMessagesDepartment.departmentDescriptionError,
})

export default connect(mapStateToProps, {
  createDepartment,
  updateDepartment,
})(AddEditDepartment);