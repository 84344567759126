import React from 'react';
import clsx from 'clsx';

import AppBar from '../AppBar';
import Drawer from '../Drawer';
import Aux from './auxiliary';

import classes from '../App.module.css';

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openDrawer: false,
    }
  }

  handleToggleDrawer = () => {
    this.setState({ openDrawer: !this.state.openDrawer })
  }

  render() {
    const { openDrawer } = this.state;
    return (
      <Aux>
        <div>
          <AppBar
            openDrawer={openDrawer}
            toggleDrawer={this.handleToggleDrawer}
          />
          <Drawer
            openDrawer={openDrawer}
            className={classes.drawer}
            variant="persistent"
          />
        </div>

        <main className={clsx(classes.content, {
          [classes.appBarShift]: openDrawer
        })}>
          {/* <Container component="main" spacing={2}>
            <Grid container direction="row" alignItems="center"> */}
              {this.props.children}
            {/* </Grid>
          </Container> */}
        </main>
      </Aux>
    )
  }
}

export default Layout;