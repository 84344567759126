import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import {
  Container,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  MenuItem,
  Menu,
  TablePagination,
  Divider,
  Button,
  Tabs,
  Tab,
  Select,
  CircularProgress,
  Typography,
  Snackbar,
  IconButton,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Autocomplete from '@material-ui/lab/Autocomplete';

import DialogBox from '../DialogBox';
import TableHeadSort from '../../Common/TableHeadSort';
import { getOperationTests, getTestResults } from '../../Store/ActionCreators'

import classes from '../../App.module.css';

class TestWiseWaitingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      page: 0,
      rowsPerPage: 15,
      order: 'asc',
      orderBy: 'organisation_name',
      openSearch: false,
      anchorEl: [],
      editList: [],
      data: [],
      openDialog: false,
      openAsyncSearch: false,
      currentTestStatus: undefined,
      waitingListTab: 1,
      department: 0,
      from_date: '',
      to_date: '',
      openSnackBar: false,
    }
  }

  componentDidMount() {
    this.handleData(0, 15)
  }


  handleData = async (start = 0, length = 15) => {
    var objToSend = {
      department: this.state.department,
      from_date: this.state.from_date,
      to_date: this.state.to_date,
      start,
      length
    }
    if (window.location.pathname === '/test-waitinglist/total') {
      objToSend.test_status = this.state.currentTestStatus;
      await this.props.getOperationTests(objToSend)
    } else if (window.location.pathname === '/test-waitinglist/pending') {
      this.setState({ currentTestStatus: 0 });
      await this.props.getOperationTests(objToSend)
    } else if (window.location.pathname === '/test-waitinglist/partial') {
      this.setState({ currentTestStatus: 1 });
      objToSend.test_status = 1;
      await this.props.getOperationTests(objToSend);
    } else if (window.location.pathname === '/test-waitinglist/completed') {
      this.setState({ currentTestStatus: 2 });
      objToSend.test_status = 2;
      await this.props.getOperationTests(objToSend)
    } else if (window.location.pathname === '/test-waitinglist/signed') {
      this.setState({ currentTestStatus: 3 });
      objToSend.test_status = 3;
      await this.props.getOperationTests(objToSend)
    } else if (window.location.pathname === '/test-waitinglist/emergency') {
      objToSend.is_emergency = true;
      await this.props.getOperationTests(objToSend)
    } else if (window.location.pathname === '/test-waitinglist/tat-exceeded') {
      objToSend.tat_exceeded = true;
      await this.props.getOperationTests(objToSend);
    } else if (window.location.pathname === '/test-waitinglist/outsourced') {
      objToSend.outsourced = true;
      await this.props.getOperationTests(objToSend);
    }
    let countsObj = {
      paritallycompleted: this.props.operationTests.paritallycompleted,
      completed: this.props.operationTests.completed,
      incomplete: this.props.operationTests.incomplete,
      signed: this.props.operationTests.signed
    };
    this.setState({ rows: this.props.operationTests.list, recordsCounts: countsObj });
  }

  handleTabChange = (event, newValue) => {
    this.setState({ waitingListTab: newValue }, () => {
      const { currentTestStatus } = this.state;
      if(currentTestStatus === 0 ) {
        window.location.replace('/patient-waitinglist/pending')
      } else if(currentTestStatus === 1 ) {
        window.location.replace('/patient-waitinglist/partial')
      } else if(currentTestStatus === 2 ) {
        window.location.replace('/patient-waitinglist/completed')
      } else if(currentTestStatus === 3 ) {
        window.location.replace('/patient-waitinglist/signed')
      } else if(currentTestStatus === 4 ) {
        window.location.replace('/patient-waitinglist/total')
      } else if(window.location.pathname === '/test-waitinglist/emergency') {
        window.location.replace('/patient-waitinglist/emergency')
      } else if(window.location.pathname === '/test-waitinglist/tat-exceeded') {
        window.location.replace('/patient-waitinglist/tat-exceeded')
      } else if(window.location.pathname === '/test-waitinglist/outsourced') {
        window.location.replace('/patient-waitinglist/outsourced')
      } else {
        window.location.replace('/patient-waitinglist/total')
      }
    })
  }

  handleTabEvent = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleDepartmentChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => this.handleData(0, 15))
  }

  handleEvent = (event, picker) => {
    if (event.type === 'apply') {
      this.setState(
        {
          from_date: picker.startDate,
          to_date: picker.endDate
        }, () => this.handleData(0, 15)
      );
    }
  }

  handleSearchWaitingList = async (name, value) => {
    if (!value) {
      this.handleData()
    } else {
      await this.props.getOperationTests({ search: value, test_status: this.state.currentTestStatus, start: 0, length: this.state.rowsPerPage })
      this.setState({ rows: this.props.operationTests.list })
    }
  }

  handleRequestSort = (e, property) => {                                              //Sorting
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  }

  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  handleMenuOpen = (id, event) => {
    let { anchorEl } = this.state;
    anchorEl[id] = event.target;
    this.setState({ anchorEl });
  }

  handleOptionsClose = () => {
    this.setState({ anchorEl: [] })
  }

  handleIconButtonClick = () => {
    this.setState({ openSearch: !this.state.openSearch })
  }

  handlePageChange = (event, newPage) => {                                 //Pagination
    this.setState({ page: newPage })
    if (!newPage) {
      this.handleData(0, this.state.rowsPerPage);
    } else {
      let start = newPage * this.state.rowsPerPage;
      let length = start + this.state.rowsPerPage;
      this.handleData(start, length);
    }
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
    this.handleData(0, event.target.value);
  }

  handleSearchInline = async (name, value) => {
    if (value !== '') {
      await this.props.getOperationTests({ [name]: value, test_status: this.state.currentTestStatus, start: 0, length: this.state.rowsPerPage })
      this.setState({ rows: this.props.operationTests.list })
    } else {
      this.handleData();
      this.setState({ rows: this.props.operationTests.list })
    }
  }

  handleTest = async(e) => {
    if (e.target.value !== '') {
      await this.props.getOperationTests({ test_name: e.target.value })
      this.setState({ rows: this.props.operationTests.list })
    } else {
      this.setState({ rows: this.props.operationTests.list })
    }
  }

  handleTestStatus = (e) => {
    var updatedList = this.state.rows;

    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.test_status.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1;
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.operationTests.list })
    }
  }

  handlePatientDetails = (e) => {
    var updatedList = this.state.rows;

    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.patient_name.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1;
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.operationTests.list })
    }
  }

  handleReferral = async(e) => {
    if (e.target.value !== '') {
      await this.props.getOperationTests({ referrer_name: e.target.value })
      this.setState({ rows: this.props.operationTests.list })
    } else {
      this.setState({ rows: this.props.operationTests.list })
    }
  }

  handleOrganization = async(e) => {
    if (e.target.value !== '') {
      await this.props.getOperationTests({ organisation_name: e.target.value })
      this.setState({ rows: this.props.operationTests.list })
    } else {
      this.setState({ rows: this.props.operationTests.list })
    }
  }

  handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnackBar: false })
  }

  handleDialog = async(row) => {
    if(row.test_status !== 'Partially Completed') {
      this.setState({ openSnackBar: true })
    } else {
      await this.props.getTestResults({ patient_test_id: row.patient_test_id })
      this.setState({ data: row, openDialog: !this.state.openDialog })
    }
  }

  render() {
    const { rows, page, rowsPerPage, order, orderBy, openSearch, openAsyncSearch, openDialog,
      data, waitingListTab, department, from_date, to_date, openSnackBar
    } = this.state;
    const { operationTests, testResults } = this.props
    const headCells = [
      { id: 'bill_id', label: 'Bill Id' },
      { id: 'test', label: 'Test' },
      { id: 'patientdetails', label: 'Patient Details' },
      { id: 'teststatus', label: 'Test Status' },
      { id: 'accessiondate', label: 'Accession Date' },
      { id: 'referral', label: 'Referral' },
      { id: 'organization', label: 'Organization' },
    ];

    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    return (
      <Grid className={clsx(classes.expansionPanelColor, classes.type)}>
        <Container maxWidth={false} classes={{root: classes.containerPadding}}>
          <Grid container>
            <Grid item xs={7}>
              <Tabs
                value={waitingListTab}
                onChange={this.handleTabChange}
                indicatorColor="primary"
              >
                <Tab className={classes.buttonText} label="Patients Waiting List"  {...this.handleTabEvent(1)} />
                <Tab className={classes.buttonText} label="Test-wise Waiting List"  {...this.handleTabEvent(2)} />
              </Tabs>
            </Grid>
            <Grid item xs={3}>
              <Select
                name='department'
                value={department}
                onChange={this.handleDepartmentChange}
                fullWidth
                variant='outlined'
                margin='dense'
              >
                <MenuItem key={1} value={1}>All Department</MenuItem>
                <MenuItem key={2} value={2}>Emergency</MenuItem>
                <MenuItem key={3} value={3}>Pathology</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={2}>
              <Grid container justify='flex-end'>
                <Button margin='dense' variant='outlined' className={classes.datePickerButton}>
                  <DateRangePicker onEvent={this.handleEvent}>
                    {(from_date && to_date) ? <span>{from_date.format('DD-MM-YYYY').toString()} to {to_date.format('DD-MM-YYYY').toString()}</span> :
                      <span>Select Date Range</span>}
                  </DateRangePicker>
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                id="asynchronous-search"
                open={openAsyncSearch}
                onOpen={() => {
                  this.setState({ openAsyncSearch: true })
                }}
                onClose={() => {
                  this.setState({ openAsyncSearch: false });
                }}
                onChange={(e, newValue) => this.handleDialog(newValue)}
                getOptionLabel={(option) => option.patient_name}
                renderOption={(option) => (
                  <React.Fragment>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant='body2'>{option.patient_name},{option.age},{option.gender}</Typography>
                      </Grid>
                      <Grid item xs={6}><Typography variant='body2'>#{option.patient_id}</Typography></Grid>
                      {/* <Grid item xs={6}><Typography variant='body2'><Grid container justify='flex-end'>Registered on:</Grid></Typography></Grid> */}
                      <Grid item xs={12}><Divider /></Grid>
                    </Grid>
                  </React.Fragment>
                )}
                options={operationTests.list}
                loading={(!operationTests.list || !operationTests.list.length) ? false : true}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    margin='dense'
                    placeholder='Select by Patient Id / Patient Name / Accession Number / Bill Id / Order Id'
                    className={classes.backgroundColor}
                    onChange={e => this.handleSearchWaitingList(e.target.name, e.target.value)}
                    {...params}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {openAsyncSearch && ((!operationTests.list || !operationTests.list.length)) ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Snackbar
                open={openSnackBar}
                autoHideDuration={6000}
                onClose={this.handleCloseSnackBar}
                message='Unable to get report related to this patient'
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                action={
                  <React.Fragment>
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      onClick={this.handleCloseSnackBar}
                    >
                      <CloseIcon />
                    </IconButton>
                  </React.Fragment>
                }
              /> 
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Table aria-label="simple table">
                    <TableHead style={{ minWidth: 650 }}>
                      <TableRow>
                        <TableHeadSort
                          enableIcon
                          headCells={headCells}
                          order={order}
                          orderBy={orderBy}
                          openSearch={openSearch}
                          onRequestSort={this.handleRequestSort}
                          handleButtonClick={this.handleIconButtonClick}
                        />
                      </TableRow>
                      {openSearch ?
                        <TableRow>
                          <TableCell align="center">
                            <TextField
                              margin='dense'
                              variant='outlined'
                              placeholder='Bill Id'
                              onChange={e => this.handleSearchInline('bill_id', e.target.value)}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              margin='dense'
                              variant='outlined'
                              placeholder='Test'
                              onChange={e => this.handleSearchInline('test_name', e.target.value)}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              margin='dense'
                              variant='outlined'
                              placeholder='Patient Details'
                              onChange={e => this.handleSearchInline('patient_name', e.target.value)}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              margin='dense'
                              variant='outlined'
                              placeholder='Test Status'
                              onChange={e => this.handleSearchInline('test_status', e.target.value)}
                            />
                          </TableCell>
                          <TableCell align="center">
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              margin='dense'
                              variant='outlined'
                              placeholder='Referral'
                              onChange={e => this.handleSearchInline('referrer_name', e.target.value)}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              margin='dense'
                              variant='outlined'
                              placeholder='Organization'
                              onChange={e => this.handleSearchInline('organisation_name', e.target.value)}
                            />
                          </TableCell>
                        </TableRow>
                        : null
                      }
                    </TableHead>
                    <TableBody>
                      {(rows || []).map((row, index) => {
                          const backgroundStyle = row.test_status === 'Incomplete' || row.test_status === 'Partially Completed' ? classes.backgroundWhite : classes.backgroundLightGrey

                          return (
                            <React.Fragment key={index}>
                              <TableRow className={backgroundStyle} id='demo' onClick={e => row.test_status === 'Partially Completed' ? this.handleDialog(row) : null}>
                                <TableCell component="th" scope="row" style={{ borderBottom: 'unset' }}>{row.bill_id}</TableCell>
                                <TableCell align="left" className={classes.tableCell}>{row.test_name}</TableCell>
                                <TableCell align="left" className={classes.tableCell}>{row.patient_name}
                                  <Table>
                                    <TableBody>
                                      <TableRow>
                                        <TableCell align="left" className={classes.tableCell}>({row.age}-{row.gender.charAt(0)})</TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableCell>
                                <TableCell align="center" className={classes.tableCell}>{row.test_status}</TableCell>
                                <TableCell align="center" className={classes.tableCell}>
                                  {row.bill_date}
                                </TableCell>
                                <TableCell align="center" className={classes.tableCell}>{row.referrer_name}</TableCell>
                                <TableCell align="center" className={classes.tableCell}>{row.organisation_name}</TableCell>
                                {row.test_status === 'Partially Completed' ?
                                  <React.Fragment>
                                    <TableCell align="center" className={classes.tableCell}><Button disabled variant='contained' style={{ backgroundColor: '#87ceeb' }} className={classes.buttonAccession}>Preview</Button></TableCell>
                                    <TableCell align="center" className={classes.tableCell}>
                                      <Button disabled variant='contained' className={classes.buttonAccession} color='primary'>Sign</Button>
                                    </TableCell>
                                  </React.Fragment>
                                  : ''
                                }
                                {/* <TableCell align="right" className={classes.tableCell}>
                                  {
                                    row.test_status === 'Incomplete' ?
                                      <React.Fragment>
                                        <MoreVertIcon onClick={e => this.handleMenuOpen(row.id, e)} />
                                        <Menu
                                          id={row.id}
                                          anchorEl={anchorEl[row.id]}
                                          keepMounted
                                          open={Boolean(anchorEl[row.id])}
                                          onClose={this.handleOptionsClose}
                                        >
                                          <MenuItem>Attach File</MenuItem>
                                            <MenuItem>Convert to file report</MenuItem>
                                            <Divider/>
                                            <MenuItem>Dismiss Report</MenuItem>
                                            <MenuItem>Un-receive Sample</MenuItem>
                                            <MenuItem>Redraw Sample</MenuItem>
                                            <MenuItem>Mark as Done</MenuItem>
                                            <Divider/>
                                            <MenuItem>Update Report Info</MenuItem>
                                            <Divider/>
                                            <MenuItem>Print Bill Receipt</MenuItem>
                                            <MenuItem>Print Sample ID Barcode</MenuItem>
                                            <Divider/>
                                        </Menu>
                                      </React.Fragment>
                                      : ''
                                  }
                                </TableCell> */}
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.tableCell}>
                                  {row.det}
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          )
                        })}
                      {/* {emptyRows > 0 && emptyRows === 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )} */}
                    </TableBody>
                  </Table>
                  <TablePagination
                    component="div"
                    count={operationTests.totalRecords || 0}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handlePageChange}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </Grid>
                <Grid item xs={12}>
                  {openDialog && testResults !== [] ? 
                    <DialogBox 
                      data={data}
                      openDialog={openDialog}
                      partialList={operationTests.list}
                      testResult={this.props.testResults}
                      listRefresh={this.handleData}
                    /> : ''}
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Container>
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  operationTests: state.login.operationTests,
  testResults: state.login.testResults,
})

export default connect(mapStateToProps, {
  getOperationTests,
  getTestResults,
})(TestWiseWaitingList);