import React from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  Dialog,
  IconButton,
  Typography,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import classes from '../../App.module.css';

class UploadImageDialog extends React.Component {
  static propTypes = {
    openDialog: PropTypes.bool.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
  }

  render() {
    const { openDialog, handleCloseDialog } = this.props;
    return (
      <Dialog
        open={openDialog}
        fullWidth maxWidth='md'
        classes={{ paper: classes.dialogHeight }}
      >
        <Grid container alignItems='center'>
          <Grid item xs={12}>
            <Grid container justify='flex-end'>
              <IconButton onClick={handleCloseDialog}>
                <CloseIcon fontSize="small" />
              </IconButton>

            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Select an Image.
            </Typography>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

export default UploadImageDialog;