import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import {
  Container,
  Grid,
  TextField,
  Divider,
  Typography,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';

import classes from '../../App.module.css';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  searchoutsourcecenter, getList, updateList
} from '../../Store/ActionCreators';

class AssignListsToOutsource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: {},
      openTest: false,
      selectedOutsourceCenter: {}
    }
    this.handleGetList();
  }

  componentDidMount() {
    // this.getOutsourceCenters();
  }

  handleGetList = async () => {
    await this.props.getList({ list_type: 4});
  }

  handleSelectChange = (e) => {
    this.setState(oldValues => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  }

  handleSearchOutsource  = async(name, value) =>{
    if (!value) {
      this.setState({ [name]: value })
    } else {
      await this.props.searchoutsourcecenter({"search": value});
    }
  }

  setOutsourceCenters = async(name, value) => {
    this.setState({ selectedOutsourceCenter : value ? value : {}, openTest: false });
    document.body.click();

  }

  setList = async(name, value) => {
    this.setState({ list: value });
    document.body.click();
  }

  assignList = async () => {
    var Obj = {
      "listref": this.state.list.id,
      "list_name": this.state.list.list_name,
      "list_type": "4",
      "outsource_ids": this.state.selectedOutsourceCenter.id
    }
    await this.props.updateList(Obj);
    if (this.props.updateListSuccess) {
      window.location.reload();
    }
  }

  render() {
    const { list, openTest, selectedOutsourceCenter } = this.state;
    const { outsourceCenterSearchList, getListNames } = this.props;
    const filter = createFilterOptions();
    return (
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <Container maxWidth='md'>
            <Grid container direction='row' alignItems='center' spacing={1}>
              <Grid item xs={3}>
                <Grid container justify='flex-end'>
                  Outsource Centre
                </Grid>
              </Grid>
              <Grid item xs={9}>
                {/* <TextField
                  fullWidth
                  variant='outlined'
                  margin='dense'
                  placeholder='Select outsource centre'
                /> */}
                <Autocomplete
                  id="outSourceCenter"
                  name='outSourceCenter'
                  open={openTest}
                  freeSolo
                  onOpen={() => {
                    this.setState({ openTest: true })
                  }}
                  onClose={() => {
                    this.setState({ openTest: false });
                  }}
                  onChange={(e, newValue) => {
                    this.setOutsourceCenters(e, newValue);
                  }}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.outsourcecenter_name;
                  }}
                  renderOption={(option) => (
                    <React.Fragment>
                      <Grid container>
                        <Grid item xs={12}>
                          
                          {/* <Typography>{option && option.test_name ? option.test_name : option.inputValue}</Typography>
                          <Typography>Price: {option && option.testpricedetail ? option.testpricedetail.price : 0}</Typography> */}
                          <Typography>{option.outsourcecenter_name}</Typography>
                        </Grid>
                        <Grid item xs={12}><Divider /></Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                  options={outsourceCenterSearchList}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        title: `Add "${params.inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  loading={outsourceCenterSearchList.length === 0 ? false : true}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      margin='dense'
                      placeholder='Enter Outsource Name'
                      className={classes.backgroundColor}
                      onChange={e => this.handleSearchOutsource(e.target.name, e.target.value)}
                      {...params}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {openTest && outsourceCenterSearchList.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Container>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Container maxWidth='md'>
            <Grid container direction='row' alignItems='center' spacing={1}>
              <Grid item xs={3}>
                <Grid container justify='flex-end'>
                  List
                </Grid>
              </Grid>
              <Grid item xs={9}>
                {/* <Select
                  fullWidth
                  label='Select list'
                  variant='outlined'
                  margin='dense'
                  name='list'
                  value={list}
                  onChange={this.handleSelectChange}
                >
                  <MenuItem value={10}>Select...</MenuItem>
                  <MenuItem value={20}>Ten</MenuItem>
                  <MenuItem value={30}>Twenty</MenuItem>
                  <MenuItem value={40}>Thirty</MenuItem>
                </Select> */}
                <Autocomplete
                  id="combo-box-demo"
                  options={getListNames}
                  onChange={(e, newValue) => {
                    this.setList(e, newValue);
                  }}
                  getOptionLabel={(option) => option.list_name}
                  renderInput={(params) => <TextField {...params} fullWidth
                    margin='dense' label="search List" placeholder='search List'
                    className={classes.backgroundColor} variant="outlined"
                     />}
                />
              </Grid>
            </Grid>
          </Container>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        {
          (selectedOutsourceCenter && Object.keys(selectedOutsourceCenter).length && !selectedOutsourceCenter.listdetails )?
            <Grid item xs={12}>
              <Grid container justify='center'>
                <Typography>No Lists assigned yet.</Typography>
              </Grid>
            </Grid>  : ''
        }
        {
          (Object.keys(selectedOutsourceCenter).length && selectedOutsourceCenter.listdetails) ?
            <Grid item xs={12}>
              <Grid container justify='center'>
                {/* <Grid item xs={3}></Grid> */}
                <Grid item xs={12}>
                  <Grid container justify='center'>
                    <Typography variant="h4">Assigned Lists</Typography>
                  </Grid>
                </Grid>
                {/* <Grid item xs={3}></Grid> */}
                <Grid item xs={3}></Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">1. {selectedOutsourceCenter.listdetails.list_name}</Typography>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
            </Grid>:
          ''
        }

         {
          (Object.keys(selectedOutsourceCenter).length && Object.keys(list).length )?
            <Grid item xs={12}>
              <Grid container justify='center'>
                <Grid item>
                  <Button
                    type="submit"
                    color="primary"
                    size="medium"
                    variant="contained"
                    className={clsx(classes.buttonText, classes.submitButton)}
                    onClick = {this.assignList}
                  >
                    Assign List
                    </Button>
                </Grid>
              </Grid>
            </Grid> : ''
        } 
      </Grid>
    )
  }
}


const mapStateToProps = state => ({
  outsourceCenterSearchList: state.login.outsourceCenterSearchList,
  getListNames: state.login.getListNames,
  updateListSuccess: state.login.updateListSuccess,
})

export default connect(mapStateToProps, {
  searchoutsourcecenter,
  getList,
  updateList
})(AssignListsToOutsource);