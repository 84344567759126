import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  Divider,
  IconButton,
  Typography,
  LinearProgress,
  Box,
  Link,
} from '@material-ui/core';

import {
  Close,
} from '@material-ui/icons';

import PDFHelper from '../../../Utilities/PDFHelper';
import { getBarcodesData, updateSampleToReceive, updateSampleToReject } from '../../../Store/ActionCreators';

import classes from '../../../App.module.css';

class Active extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      openActivityDetails: false,
      selectedBatchData: [],
    }
    this.reset = this.state;
  }

  handleOpenDialog = (item) => {
    this.setState({ openDialog: true, selectedBatchData: item })
  }

  handleCloseDialog = () => {
    this.setState({ openDialog: false, openActivityDetails: false })
  }

  handleViewActivityDetails = () => {
    this.setState({ openActivityDetails: !this.state.openActivityDetails })
  }

  handlePrintBarCode = async (printref) => {
    await this.props.getBarcodesData({ testref: [printref] });
    if (this.props.barCode.length > 0) {
      let printableData = {
        printable: this.props.barCode,
        type: 'pdf',
        base64: true,
      }
      PDFHelper.printPDF(printableData);
    }
  }

  handleSampleReceive = async (item, batchId) => {
    let arrayForReceivedSamples = [];
    arrayForReceivedSamples = [...arrayForReceivedSamples, item.printref.toString()]
    let reqObj = {
      batch_id: batchId.toString(),
      received_sample_ids: arrayForReceivedSamples,
    }
    await this.props.updateSampleToReceive(reqObj);
    if(this.props.updateSampleToReceiveStatus) {
      this.setState(this.reset)
      this.props.reload();
    }
  }

  handleSampleReject = async (item,batchId) => {
    let arrayForRejectedSamples = [];
    arrayForRejectedSamples = [...arrayForRejectedSamples, item.printref.toString()]
    let reqObj = {
      batch_id: batchId.toString(),
      rejected_sample_ids: arrayForRejectedSamples,
    }
    await this.props.updateSampleToReject(reqObj);
    if(this.props.updateSampleToRejectStatus) {
      this.setState(this.reset)
      this.props.reload();
    }
  }

  handleReceiveAll = async() => {
    const { selectedBatchData } = this.state;
    let arrayForReceivedSamples = [];
    selectedBatchData.batchtestdetails.map(item => arrayForReceivedSamples = [...arrayForReceivedSamples, item.patient_test_id])
    let reqObj = {
      batch_id: selectedBatchData.id.toString(),
      received_sample_ids: arrayForReceivedSamples,
    }
    await this.props.updateSampleToReceive(reqObj);
    if(this.props.updateSampleToReceiveStatus) {
      this.setState(this.reset)
      this.props.reload();
    }
  }

  handleActiveDialog = () => {
    const { openDialog, openActivityDetails, selectedBatchData } = this.state;
    return (
      <Dialog
        open={openDialog}
        fullWidth
        maxWidth='md'
        classes={{ paper: classes.dialogHeight }}
      >
        <Grid container classes={{ root: classes.type }} className={classes.expansionPanelColor}>
          <Grid item xs={11} className={classes.type}>
            <Typography variant='h6'><b>Batch Details</b></Typography>
          </Grid>
          <Grid item xs={1}>
            <Grid container justify='flex-end'>
              <IconButton onClick={this.handleCloseDialog} >
                <Close fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>

          <Grid item xs={3} className={classes.type}>
            <Typography variant='subtitle2'><b>Sent {selectedBatchData.patient_tests.length} sample{true ? 's' : ''}</b></Typography>
          </Grid>
          <Grid item xs={9} className={classes.type}>
            <LinearProgress variant="determinate" value={100}
              classes={{
                root: classes.bar,
                bar: classes.bar,
              }}
            />
          </Grid>

          <Grid item xs={12} className={clsx(classes.type, classes.backgroundWhite)}>
            <Grid container spacing={1}>
              <Grid item xs={3}><Typography variant='subtitle2' className={classes.fontColor}>Sample ID</Typography></Grid>
              <Grid item xs={4}><Typography variant='subtitle2' className={classes.fontColor}>Patient Name</Typography></Grid>
              <Grid item xs={3}><Typography variant='subtitle2' className={classes.fontColor}>Sample Type(Container)</Typography></Grid>
              <Grid item xs={2}><Typography></Typography></Grid>

              <Grid item xs={12}>
                <Box border={1} borderColor='grey.500' className={clsx(classes.heightFix, classes.type)}>
                  {
                    selectedBatchData.patient_tests.map((item, index) => {
                      return (
                        <Grid container justify='flex-start' key={item.printref} spacing={1}>
                          <Grid item xs={3}><Typography>{item.test_barcode}</Typography></Grid>
                          <Grid item xs={4}><Typography>{item.patient_name}({item.gender.charAt(0)}-{item.age})&nbsp;
                            {item.batch_test_status[item.printref] === '2' ? <span className={clsx(classes.fontSmallSize, classes.textColor,classes.backgroundGreen)}>Received</span> : 
                            (item.batch_test_status[item.printref] === '3' ? <span className={clsx(classes.fontSmallSize, classes.textColor,classes.buttonColor)}>Rejected</span> : '')}</Typography>
                          </Grid>
                          <Grid item xs={2}><Typography>
                            <span style={{ backgroundColor: '#' + item.container_color }} className={clsx(classes.marginTop, classes.testSampleCircle)}></span>&nbsp;
                            {item.sample_name}</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Button
                              variant='outlined'
                              className={clsx(classes.buttonText, classes.paddingButton)}
                              onClick={e => this.handlePrintBarCode(item.printref)}
                            >
                              Print
                            </Button>&nbsp;
                            {item.batch_test_status[item.printref] === '1' ?
                              <React.Fragment>
                                <Button
                                  variant='outlined'
                                  className={clsx(classes.buttonText, classes.paddingButton)}
                                  onClick={e => this.handleSampleReceive(item, selectedBatchData.id)}
                                >
                                  Receive
                                </Button>&nbsp;
                                  <Button
                                  variant='outlined'
                                  className={clsx(classes.buttonText, classes.paddingButton)}
                                  onClick={e => this.handleSampleReject(item, selectedBatchData.id)}
                                >
                                  Reject
                                </Button>
                              </React.Fragment> : ''
                            }

                          </Grid>
                          <Grid item xs={12}><Divider /></Grid>
                        </Grid>
                      )
                    })}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.fontColor}>Vacutainer Summary</Typography>
              </Grid>
              <Grid item xs={12}><Divider /></Grid>
              <Grid item xs={2} className={classes.fontColor}>{selectedBatchData.urine_container_count}</Grid>
              <Grid item xs={2} className={classes.fontColor}>1</Grid>
              <Grid item xs={8}></Grid>
              <Grid item xs={2} className={classes.fontColor}><Typography variant='subtitle2' className={classes.fontColor}>Urine Container</Typography></Grid>
              <Grid item xs={2} className={classes.fontColor}><Typography variant='subtitle2' className={classes.fontColor}>withoutContainer</Typography></Grid>
              <Grid item xs={8}></Grid>

              <Grid item xs={12}>
                <Typography className={classes.fontColor}>Logistic Summary</Typography>
              </Grid>
              <Grid item xs={12}><Divider /></Grid>

              <Grid container className={classes.type} spacing={1}>
                <Grid item xs={3}><Typography variant='subtitle2' className={classes.fontColor}>From</Typography></Grid>
                <Grid item xs={3}><Typography variant='subtitle2' className={classes.fontColor}>To</Typography></Grid>
                <Grid item xs={3}><Typography variant='subtitle2' className={classes.fontColor}>Created By</Typography></Grid>
                <Grid item xs={3}><Typography variant='subtitle2' className={classes.fontColor}>Temperature</Typography></Grid>

                <Grid item xs={3}><Typography variant='subtitle2' className={classes.fontColor}>{selectedBatchData.from_branch_name}</Typography></Grid>
                <Grid item xs={3}><Typography variant='subtitle2' className={classes.fontColor}>{selectedBatchData.to_branch_name}</Typography></Grid>
                <Grid item xs={3}><Typography variant='subtitle2' className={classes.fontColor}>{selectedBatchData.created_by}</Typography></Grid>
                <Grid item xs={3}><Typography variant='subtitle2' className={classes.fontColor}>{selectedBatchData.batch_temp}</Typography></Grid>

                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={12}><Typography variant='subtitle2' className={classes.fontColor}>Collected By</Typography></Grid>
                <Grid item xs={12}><Typography variant='subtitle2' className={classes.fontColor}>{selectedBatchData.collect_person}</Typography></Grid>
                <Grid item xs={12}><Divider /></Grid>
              </Grid>

              <Grid item xs={12}>
                <Link onClick={this.handleViewActivityDetails}>View Batch Activity Details</Link>
              </Grid>

              <Grid item xs={12}>
                {
                  openActivityDetails ? (
                    <Box border={1} borderColor='grey.500' className={clsx(classes.heightFix, classes.type)}>
                      <Grid container spacing={1}>
                        <Grid item xs={3}><Typography variant='subtitle2' className={classes.fontColor}>Date</Typography></Grid>
                        <Grid item xs={6}><Typography variant='subtitle2' className={classes.fontColor}>Sample Details</Typography></Grid>
                        <Grid item xs={3}><Typography variant='subtitle2' className={classes.fontColor}>Operation Performed By</Typography></Grid>

                        {selectedBatchData.patient_tests.map((item, index) => {
                          return (
                            <React.Fragment>
                              <Grid item xs={3}><Typography variant='subtitle2' className={classes.fontColor}>{selectedBatchData.created_at}</Typography></Grid>
                              <Grid item xs={6}><Typography variant='subtitle2' className={classes.fontColor}>
                                Sample of {item.patient_name} (Sample ID : {item.test_barcode} , Sample Type : {item.sample_name}) has been add in Batch No : {selectedBatchData.batch_ref_number}
                              </Typography></Grid>
                              <Grid item xs={3}><Typography variant='subtitle2' className={classes.fontColor}>{selectedBatchData.from_branch_name}</Typography></Grid>
                            </React.Fragment>
                          )
                        })}
                      </Grid>
                    </Box>
                  ) : ''
                }
              </Grid>
              <Grid item xs={9}>
                <Grid container className={classes.fontColor}>
                  <Grid item xs={3}>
                    <Typography>
                      {selectedBatchData.rejectedsample_count}<br />
                      Rejected Sample
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {selectedBatchData.acceptedsample_count}<br />
                      Accepted Sample
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {selectedBatchData.total_samples}<br />
                      Total Sample
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={3}>
                <Button
                  variant='outlined'
                  className={classes.buttonText}
                  onClick={this.handleCloseDialog}
                >
                  Close
                </Button> &nbsp;&nbsp;
                <Button
                  variant='outlined'
                  className={classes.buttonText}
                  onClick={this.handleReceiveAll}
                >
                  Receive All
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    )
  }

  render() {
    const { openDialog } = this.state;
    const { rows } = this.props;

    return (
      <Container component="main" maxWidth={false} classes={{ root: classes.containerPadding }}>
        <Grid container>
          <Grid item xs={12}>
            <Table aria-label="simple table">
              <TableHead style={{ minWidth: 650 }} >
                <TableRow>
                  <TableCell style={{ fontWeight: '600' }}>Reference No.</TableCell>
                  <TableCell style={{ fontWeight: '600', width: '30%' }} align='left'>From</TableCell>
                  <TableCell style={{ fontWeight: '600' }} align='left'>Batch Date</TableCell>
                  <TableCell style={{ fontWeight: '600' }} align='left'></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={index} className={classes.pointer}
                    onClick={e => this.handleOpenDialog(row)}
                  >
                    <TableCell component="th" scope="row">
                      <Grid container direction='column'>
                        <Grid item xs={12}>{row.batch_ref_number}</Grid>
                        <Grid item xs={12}><i className={classes.fontSmallSize}>Comments: {row.batch_comments}</i></Grid>
                      </Grid>
                    </TableCell>
                    <TableCell align="left">{row.from_branch_name}</TableCell>
                    <TableCell align="left">{row.created_at}</TableCell>
                    <TableCell align="right">
                      <Button
                        color='primary'
                        variant='contained'
                        className={classes.buttonText}
                      >
                        View and Receive
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
                }
              </TableBody>
            </Table>
          </Grid>

          <Grid item xs={12}>
            {openDialog ? this.handleActiveDialog() : ''}
          </Grid>

        </Grid>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  barCode: state.login.barCode,
  updateSampleToRejectStatus: state.login.updateSampleToRejectStatus,
  updateSampleToReceiveStatus: state.login.updateSampleToReceiveStatus,
})

export default connect(mapStateToProps, {
  getBarcodesData,
  updateSampleToReceive,
  updateSampleToReject,
})(Active);
