import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { ValidatorForm } from 'react-material-ui-form-validator';

import {
  Grid,
  Paper,
  Select,
  MenuItem,
  TextField,
  Container,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Button,
  Typography,
  OutlinedInput,
  FormControl,
  InputAdornment,
  Divider,
  IconButton,
  Snackbar,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import UploadImageDialogue from '../../Common/UploadImageDialog';
import flags from '../../Data/flags.json';
// import { TextValidator } from '../../Material UI';
import { addOrganization, editOrganization } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

class OrganizationInfo extends React.Component {
  static propTypes = {
    handleCloseDialog: PropTypes.func.isRequired,
    organizationTab: PropTypes.number.isRequired,
    handleOrgShiftTab: PropTypes.func.isRequired,
    handleLoginShiftTab: PropTypes.func.isRequired,
    handleLoginPrevious: PropTypes.func.isRequired,
    handleHeaderPrevious: PropTypes.func.isRequired,

    listUpdate: PropTypes.func.isRequired,
    // editList: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.state = {
      organisation_name: this.props.editList.organisation_name !== undefined && this.props.editList.organisation_name !== null ? this.props.editList.organisation_name : '',
      contact_country_code: this.props.editList.contact_country_code !== undefined && this.props.editList.contact_country_code !== null ? this.props.editList.contact_country_code : '+91',
      contact_number: this.props.editList.contact_number !== undefined && this.props.editList.contact_number !== null ? this.props.editList.contact_number : '',
      alternative_contact_country_code: this.props.editList.alternative_contact_country_code !== undefined && this.props.editList.alternative_contact_country_code !== null ? this.props.editList.alternative_contact_country_code : '+91',
      alternative_contact_number: this.props.editList.alternative_contact_number !== undefined && this.props.editList.alternative_contact_number !== null ? this.props.editList.alternative_contact_number : '',
      email_address: this.props.editList.email_address !== undefined && this.props.editList.email_address !== null ? this.props.editList.email_address : '',
      organisation_source: this.props.editList.organisation_source !== undefined && this.props.editList.organisation_source !== null ? this.props.editList.organisation_source : 0,
      organisation_type: this.props.editList.organisation_type !== undefined && this.props.editList.organisation_type !== null ? this.props.editList.organisation_type : 0,
      marketing_person_user_id: this.props.editList.marketing_person_user_id !== undefined && this.props.editList.marketing_person_user_id !== null ? this.props.editList.marketing_person_user_id : 0,
      payment_mode: this.props.editList.payment_mode !== undefined && this.props.editList.payment_mode !== null ? this.props.editList.payment_mode : '',
      address: this.props.editList.address !== undefined && this.props.editList.address !== null ? this.props.editList.address : '',
      city: this.props.editList.city !== undefined && this.props.editList.city !== null ? this.props.editList.city : '',
      pincode: this.props.editList.pincode !== undefined && this.props.editList.pincode !== null ? this.props.editList.pincode : '',
      area: this.props.editList.area !== undefined && this.props.editList.area !== null ? this.props.editList.area : '',
      organisation_code: this.props.editList.organisation_code !== undefined && this.props.editList.organisation_code !== null ? this.props.editList.organisation_code : '',
      payment_type: this.props.editList.payment_type !== undefined && this.props.editList.payment_type !== null ? this.props.editList.payment_type : '0',
      note: this.props.editList.note !== undefined && this.props.editList.note !== null ? this.props.editList.note : '',
      remark: this.props.editList.remark !== undefined && this.props.editList.remark !== null ? this.props.editList.remark : '',
      revenue_target: this.props.editList.revenue_target !== undefined && this.props.editList.revenue_target !== null ? this.props.editList.revenue_target : 0,
      is_sms_on_report_ready_enable: this.props.editList.is_sms_on_report_ready_enable !== undefined && this.props.editList.is_sms_on_report_ready_enable !== null ? this.props.editList.is_sms_on_report_ready_enable : '0',
      is_email_on_report_ready_enable: this.props.editList.is_email_on_report_ready_enable !== undefined && this.props.editList.is_email_on_report_ready_enable !== null ? this.props.editList.is_email_on_report_ready_enable : '0',
      is_sms_sample_receive_enable: this.props.editList.is_sms_sample_receive_enable !== undefined && this.props.editList.is_sms_sample_receive_enable !== null ? this.props.editList.is_sms_sample_receive_enable : '0',
      is_bill_sms_to_patient_enable: this.props.editList.is_bill_sms_to_patient_enable !== undefined && this.props.editList.is_bill_sms_to_patient_enable !== null ? this.props.editList.is_bill_sms_to_patient_enable : '0',
      is_mrp_receipt_enable: this.props.editList.is_mrp_receipt_enable !== undefined && this.props.editList.is_mrp_receipt_enable !== null ? this.props.editList.is_mrp_receipt_enable : '0',
      is_secondary_unit_enable: this.props.editList.is_secondary_unit_enable !== undefined && this.props.editList.is_secondary_unit_enable !== null ? this.props.editList.is_secondary_unit_enable : '0',
      report_bill_status: this.props.editList.report_bill_status !== undefined && this.props.editList.report_bill_status !== null ? this.props.editList.report_bill_status : 0,
      report_type: this.props.editList.report_type !== undefined && this.props.editList.report_type !== null ? this.props.editList.report_type : '0',

      username: this.props.editList.username !== undefined && this.props.editList.username !== null ? this.props.editList.username : '',
      password: this.props.editList.password !== undefined && this.props.editList.password !== null ? this.props.editList.password : '',
      is_collection_center_enable: this.props.editList.is_collection_center_enable !== undefined && this.props.editList.is_collection_center_enable !== null ? this.props.editList.is_collection_center_enable : '0',
      is_link_with_existing_org_enable: this.props.editList.is_link_with_existing_org_enable !== undefined && this.props.editList.is_link_with_existing_org_enable !== null ? this.props.editList.is_link_with_existing_org_enable : '0',
      is_archives_enable: this.props.editList.is_archives_enable !== undefined && this.props.editList.is_archives_enable !== null ? this.props.editList.is_archives_enable : '0',
      is_finance_manangement_enable: this.props.editList.is_finance_manangement_enable !== undefined && this.props.editList.is_finance_manangement_enable !== null ? this.props.editList.is_finance_manangement_enable : '0',

      pdfHeader: [],
      pdfFooter: [],
      organisationref: this.props.editList.id !== undefined && this.props.editList.id !== null ? this.props.editList.id : null,
      user_id: this.props.editList.user_id !== undefined && this.props.editList.user_id !== null ? this.props.editList.user_id : null,

      showPassword: false,
      errors: false,
      openSnackBar: true,
      paymentModeError: false,
    }
    this.reset = this.state;
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSelectChange = (e) => {
    this.setState(oldValues => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  }

  handleCheckBox = (e) => {
    this.setState({ [e.target.name]: e.target.checked === true ? '1' : '0' })
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnackBar: false })
  }

  handleFileChange = async (e, name) => {
    let file = e.currentTarget.files[0];
    if (file) {
      this.setState({ [name]: await toBase64(file) })
      if (name === 'pdf_header_image') {
        this.setState({ pdfHeader: file.name })
      } else if (name === 'pdf_footer_image') this.setState({ pdfFooter: file.name })
    }
  }

  handleOrganizationInfo = () => {
    const { organisation_name, contact_country_code, contact_number, alternative_contact_country_code,
      alternative_contact_number, email_address, organisation_source, organisation_type, marketing_person_user_id,
      payment_mode, address, city, pincode, area, organisation_code, payment_type,
      note, remark, revenue_target, is_sms_on_report_ready_enable, is_email_on_report_ready_enable,
      is_sms_sample_receive_enable, is_bill_sms_to_patient_enable, is_mrp_receipt_enable, is_secondary_unit_enable,
      report_bill_status, report_type, errors, paymentModeError,
    } = this.state;

    const { addOrganizationSuccess, organizationNameError, editOrganizationSuccess, organizationUpdateNameError, handleCloseDialog, handleOrgShiftTab, editList } = this.props;
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper classes={{ root: classes.paperHeight }}>
            <Container maxWidth={false} classes={{ root: classes.containerPadding }}>
              <Grid container direction='row' alignItems='center' spacing={1} className={classes.textAlign}>
                <Grid item xs={12}>
                  Organization Name *
                  <Grid container direction='row'>
                    <Grid item xs={12}>
                      <TextField
                        name='organisation_name'
                        value={organisation_name}
                        fullWidth
                        variant='outlined'
                        placeholder='Organization Name'
                        onChange={this.handleChange}
                      // validators={['required']}
                      // errorMessages={['This field is required']}
                      />
                    </Grid>

                    {errors && organisation_name === '' ? <div className={classes.error}>This field is required</div> : ''}
                    {addOrganizationSuccess === false ? <div className={classes.error}>{organizationNameError}</div> : ''}
                    {editOrganizationSuccess === false ? <div className={classes.error}>{organizationUpdateNameError}</div> : ''}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container direction='row' justify='flex-end'>
                    <Grid item>
                      Contact
                    </Grid>
                  </Grid>
                  <Grid container direction='row'>
                    <Grid item xs={2}>
                      <Select
                        fullWidth
                        variant='outlined'
                        name='contact_country_code'
                        value={contact_country_code}
                        onChange={this.handleSelectChange}
                      >
                        {flags.map(flag =>
                          <MenuItem value={flag.dial_code} key={flag.code}>
                            {flag.flag} {flag.name} {flag.dial_code}
                          </MenuItem>
                        )}
                      </Select>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        name='contact_number'
                        value={contact_number}
                        fullWidth
                        variant='outlined'
                        placeholder='Mobile Number'
                        onChange={this.handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container direction='row' justify='flex-end'>
                    <Grid item>
                      AlternateContact
                  </Grid>
                  </Grid>
                  <Grid container direction='row'>
                    <Grid item xs={2}>
                      <Select
                        fullWidth
                        variant='outlined'
                        name='alternative_contact_country_code'
                        value={alternative_contact_country_code}
                        onChange={this.handleSelectChange}
                      >
                        {flags.map(flag =>
                          <MenuItem value={flag.dial_code} key={flag.code}>
                            {flag.flag} {flag.name} {flag.dial_code}
                          </MenuItem>
                        )}
                      </Select>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        name='alternative_contact_number'
                        value={alternative_contact_number}
                        fullWidth
                        variant='outlined'
                        placeholder='Alternative Mobile Number'
                        onChange={this.handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  Email ID
                  <TextField
                    name='email_address'
                    value={email_address}
                    fullWidth
                    variant='outlined'
                    placeholder='Email ID'
                    onChange={this.handleChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  Source
                  <Select
                    name='organisation_source'
                    value={organisation_source}
                    fullWidth
                    variant='outlined'
                    onChange={this.handleSelectChange}
                  >
                    <MenuItem value={0}>None(Default)</MenuItem>
                    <MenuItem value={1}>Ten</MenuItem>
                    <MenuItem value={2}>Twenty</MenuItem>
                    <MenuItem value={3}>Thirty</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={12}>
                  Type
                  <Select
                    name='organisation_type'
                    value={organisation_type}
                    fullWidth
                    variant='outlined'
                    onChange={this.handleSelectChange}
                  >
                    <MenuItem value={0}>Select Type</MenuItem>
                    <MenuItem value={1}>Ten</MenuItem>
                    <MenuItem value={2}>Twenty</MenuItem>
                    <MenuItem value={3}>Thirty</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={12}>
                  Select Marketing Person :
                <Select
                    name='marketing_person_user_id'
                    value={marketing_person_user_id}
                    fullWidth
                    variant='outlined'
                    onChange={this.handleSelectChange}
                  >
                    <MenuItem value={0}>Select Marketing Person</MenuItem>
                    <MenuItem value={1}>Ten</MenuItem>
                    <MenuItem value={2}>Twenty</MenuItem>
                    <MenuItem value={3}>Thirty</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={12}>
                  Payment Mode :
                  <Select
                    name='payment_mode'
                    value={payment_mode}
                    fullWidth
                    variant='outlined'
                    onChange={this.handleSelectChange}
                  >
                    {
                      this.props.paymentModes.list.map((item, index) => 
                        <MenuItem key={index} value={item.id}>{item.payment_mode_name}</MenuItem>
                      )
                    }
                  </Select>
                  {paymentModeError && payment_mode === '' ? <div className={classes.error}>This field is required</div> : ''}
                </Grid>

                <Grid item xs={12}>
                  Address
                <TextField
                    name='address'
                    value={address}
                    fullWidth
                    variant='outlined'
                    placeholder='Organization Address'
                    onChange={this.handleChange}
                  />
                </Grid>

                <Grid item xs={6}>
                  City
                <TextField
                    name='city'
                    value={city}
                    fullWidth
                    variant='outlined'
                    placeholder='City Name'
                    onChange={this.handleChange}
                  />
                </Grid>

                <Grid item xs={6}>
                  Pin Code
                <TextField
                    name='pincode'
                    value={pincode}
                    fullWidth
                    variant='outlined'
                    placeholder='Pin Code'
                    onChange={this.handleChange}
                  />
                </Grid>

                <Grid item xs={6}>
                  Area
                <TextField
                    name='area'
                    value={area}
                    fullWidth
                    variant='outlined'
                    placeholder='Area'
                    onChange={this.handleChange}
                  />
                </Grid>

                <Grid item xs={6}>
                  Organization Code
                <TextField
                    name='organisation_code'
                    value={organisation_code}
                    fullWidth
                    variant='outlined'
                    placeholder='Organization Code'
                    onChange={this.handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography>
                    Payment Type
                </Typography>
                </Grid>

                <Grid>
                  <RadioGroup
                    name='payment_type'
                    value={payment_type}
                    row
                    aria-label='payment_type'
                    onChange={this.handleChange}
                  >
                    <FormControlLabel
                      value='0'
                      checked={payment_type === '0'}
                      control={<Radio color='primary' />}
                      label='Walk-in'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      value='1'
                      checked={payment_type === '1'}
                      control={<Radio color='primary' />}
                      label='Prepaid'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      value='2'
                      checked={payment_type === '2'}
                      control={<Radio color='primary' />}
                      label='Postpaid (Credit)'
                      labelPlacement='end'
                    />
                  </RadioGroup>
                </Grid>

                <Grid item xs={12}>
                  Notes
                <TextField
                    name='note'
                    value={note}
                    fullWidth
                    variant='outlined'
                    placeholder='Notes'
                    onChange={this.handleChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  Remark
                  <TextField
                    name='remark'
                    value={remark}
                    fullWidth
                    variant='outlined'
                    placeholder='Remark'
                    onChange={this.handleChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  Revenue Target
                <TextField
                    name='revenue_target'
                    value={revenue_target}
                    fullWidth
                    variant='outlined'
                    onChange={this.handleChange}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid>
                  <Typography>
                    Organization Configuration:
                </Typography>
                  <Typography variant='body2'>
                    <i>*Please note that each sms will be charged</i>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    name='is_sms_on_report_ready_enable'
                    value={is_sms_on_report_ready_enable}
                    checked={true ? is_sms_on_report_ready_enable === '1' : false}
                    control={<Checkbox color='primary' />}
                    label='SMS On Report Ready'
                    labelPlacement='end'
                    onClick={this.handleCheckBox}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    name='is_email_on_report_ready_enable'
                    value={is_email_on_report_ready_enable}
                    checked={true ? is_email_on_report_ready_enable === '1' : false}
                    control={<Checkbox color='primary' />}
                    label='Email On Report Ready'
                    labelPlacement='end'
                    onClick={this.handleCheckBox}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    name='is_sms_sample_receive_enable'
                    value={is_sms_sample_receive_enable}
                    checked={true ? is_sms_sample_receive_enable === '1' : false}
                    control={<Checkbox color='primary' />}
                    label='SMS on Sample Receive'
                    labelPlacement='end'
                    onClick={this.handleCheckBox}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    name='is_bill_sms_to_patient_enable'
                    value={is_bill_sms_to_patient_enable}
                    checked={true ? is_bill_sms_to_patient_enable === '1' : false}
                    control={<Checkbox color='primary' />}
                    label='Billing SMS To Patient'
                    labelPlacement='end'
                    onClick={this.handleCheckBox}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    name='is_mrp_receipt_enable'
                    value={is_mrp_receipt_enable}
                    checked={true ? is_mrp_receipt_enable === '1' : false}
                    control={<Checkbox color='primary' />}
                    label='MRP Receipt'
                    labelPlacement='end'
                    onClick={this.handleCheckBox}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    name='is_secondary_unit_enable'
                    value={is_secondary_unit_enable}
                    checked={true ? is_secondary_unit_enable === '1' : false}
                    control={<Checkbox color='primary' />}
                    label='Secondary Unit'
                    labelPlacement='end'
                    onClick={this.handleCheckBox}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Typography>
                    Report and Bill Status :
                </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Select
                    fullWidth
                    variant='outlined'
                    name='report_bill_status'
                    value={report_bill_status}
                    onChange={this.handleSelectChange}
                  >
                    <MenuItem value={0}>Show Reports and Bills To Patient</MenuItem>
                    <MenuItem value={1}>Do not show Reports and Bills To Patient</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={2}>
                  <Typography>
                    Report Type :
                </Typography>
                </Grid>

                <Grid>
                  <RadioGroup
                    row
                    aria-label='report_type'
                    name='report_type'
                    value={report_type}
                    onChange={this.handleChange}
                  >
                    <FormControlLabel
                      value='0'
                      checked={report_type === '0'}
                      control={<Radio color='primary' />}
                      label='Paid Reports Only'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      value='1'
                      checked={report_type === '1'}
                      control={<Radio color='primary' />}
                      label='All Reports'
                      labelPlacement='end'
                    />
                  </RadioGroup>
                </Grid>

              </Grid>
            </Container>
          </Paper>
        </Grid>

        <Grid item xs={1}>
          <Button
            variant='outlined'
            color='primary'
            className={classes.buttonAccession}
            onClick={handleCloseDialog}
          >
            Cancel
        </Button>
        </Grid>

        <Grid item xs={11}>
          <Grid container justify='flex-end'>
            {
              editList.length !== 0 ?
                <Button
                  type='submit'
                  variant='outlined'
                  color='primary'
                  className={classes.buttonAccession}
                  onSubmit={this.handleAddOrganization}
                >
                  Update Organization
              </Button> :
                <Button
                  type='submit'
                  variant='outlined'
                  color='primary'
                  className={classes.buttonAccession}
                  onSubmit={this.handleAddOrganization}
                >
                  Add Organization
              </Button>
            }
            &nbsp;&nbsp;
            <Button
              variant='contained'
              color='primary'
              className={classes.buttonAccession}
              onClick={handleOrgShiftTab}
            >
              Next
          </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  handleLoginAndAccess = () => {
    const { username, password, is_collection_center_enable, is_link_with_existing_org_enable, is_archives_enable,
      is_finance_manangement_enable, showPassword
    } = this.state;
    const { handleCloseDialog, handleLoginShiftTab, handleLoginPrevious, editList, addOrganizationSuccess, editOrganizationSuccess, userNameError,
      userNameUpdateError,
    } = this.props;

    return (
      <Grid container direction='row' spacing={1}>
        <Grid item xs={12}>
          <Paper classes={{ root: classes.paperHeight }}>
            <Container maxWidth={false} classes={{ root: classes.containerPadding }}>
              <Grid container direction='row' alignItems='center' spacing={1} className={classes.textAlign}>
                <Grid item xs={12}>
                  Your current active logins are 0, and your maximum login limit is 3
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction='row' justify='flex-end'>
                    <Grid item>
                      Username
                    </Grid>
                  </Grid>
                  <Grid container direction='row'>
                    <Grid item xs={2}>
                      <TextField
                        disabled
                        variant='outlined'
                        fullWidth
                        margin='dense'
                        className={clsx(classes.search, classes.expansionPanelColor)}
                        placeholder='lucidatta'
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        name='username'
                        value={username}
                        onChange={this.handleChange}
                        inputProps={{
                          // autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        variant='outlined'
                        fullWidth
                        margin='dense'
                        className={classes.search}
                        placeholder='Username'
                      // validators={['required']}
                      // errorMessages={['This field is required']}
                      />
                      {addOrganizationSuccess === false && userNameError !== [] ? <div className={classes.error}>{userNameError}</div> : ''}
                      {editOrganizationSuccess === false && userNameUpdateError !== [] ? <div className={classes.error}>{userNameUpdateError}</div> : ''}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  Password*
                  <FormControl fullWidth>
                    <OutlinedInput
                      type={showPassword ? 'text' : 'password'}
                      name='password'
                      value={password}
                      margin='dense'
                      className={classes.search}
                      onChange={this.handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  * Password should contain at least one  small letter, one capital letter,
                  one digit,one special character & greater than 8 characters length
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    name='is_collection_center_enable'
                    value={is_collection_center_enable}
                    checked={true ? is_collection_center_enable === '1' : false}
                    onClick={this.handleCheckBox}
                    control={<Checkbox color='primary' />}
                    label='Collection Center'
                    labelPlacement='end'
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    name='is_link_with_existing_org_enable'
                    value={is_link_with_existing_org_enable}
                    checked={true ? is_link_with_existing_org_enable === '1' : false}
                    onClick={this.handleCheckBox}
                    control={<Checkbox color='primary' />}
                    label='Link with existing organization'
                    labelPlacement='end'
                  />
                </Grid>

                <Grid>
                  <Typography>
                    Privileges :
                  </Typography>
                  <Typography variant='body2'>
                    <i>*Please select features you want to enable for this Organization</i>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    name='is_archives_enable'
                    value={is_archives_enable}
                    checked={true ? is_archives_enable === '1' : false}
                    onClick={this.handleCheckBox}
                    control={<Checkbox color='primary' />}
                    label='Archives'
                    labelPlacement='end'
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    name='is_finance_manangement_enable'
                    value={is_finance_manangement_enable}
                    checked={true ? is_finance_manangement_enable === '1' : false}
                    onClick={this.handleCheckBox}
                    control={<Checkbox color='primary' />}
                    label='Finance Management'
                    labelPlacement='end'
                  />
                </Grid>

              </Grid>
            </Container>
          </Paper>
        </Grid>

        <Grid item xs={1}>
          <Button
            variant='outlined'
            color='primary'
            className={classes.buttonAccession}
            onClick={handleCloseDialog}
          >
            Cancel
            </Button>
        </Grid>

        <Grid item xs={1}>
          <Button
            variant='contained'
            color='primary'
            className={classes.buttonAccession}
            onClick={handleLoginPrevious}
          >
            Previous
          </Button>
        </Grid>

        <Grid item xs={10}>
          <Grid container justify='flex-end'>
            {
              editList.length !== 0 ?
                <Button
                  type='submit'
                  variant='outlined'
                  color='primary'
                  className={classes.buttonAccession}
                  onSubmit={this.handleAddOrganization}
                >
                  Update Organization
              </Button> :
                <Button
                  type='submit'
                  variant='outlined'
                  color='primary'
                  className={classes.buttonAccession}
                  onSubmit={this.handleAddOrganization}
                >
                  Add Organization
              </Button>
            }
              &nbsp;&nbsp;
            <Button
              variant='contained'
              color='primary'
              className={classes.buttonAccession}
              onClick={handleLoginShiftTab}
            >
              Next
              </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  handleHeaderAndFooter = () => {
    const { openHeaderDialog, openFooterDialog, pdfHeader, pdfFooter } = this.state;
    const { handleHeaderPrevious, handleCloseDialog, editList } = this.props;

    return (
      <Grid container direction='row' spacing={1}>
        <Grid item xs={12}>
          <Paper classes={{ root: classes.paperHeight }}>
            <Container maxWidth={false} classes={{ root: classes.containerPadding }}>
              <Grid container direction='row' spacing={1} alignItems='flex-start'>
                <Grid item xs={12}>
                  <Typography className={classes.payableAmount}>
                    Upload PDF Header
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <IconButton>
                    <CloudUploadIcon
                      fontSize='large'
                      classes={{ fontSizeLarge: classes.fontSizeIcon }}
                    />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Upload your file here
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <label htmlFor="upload-header">
                    <input
                      style={{ display: 'none' }}
                      id="upload-header"
                      name="upload-header"
                      type="file"
                      accept="application/pdf, image/jpeg, image/jpg, image/png"
                      onChange={e => this.handleFileChange(e, 'pdf_header_image')}
                    />

                    <Button color="primary" variant="outlined" component="span" className={classes.buttonAccession}
                    >
                      Upload Image
                    </Button>
                  </label>
                </Grid>

                <Grid item xs={10}>
                  <h3>{pdfHeader}</h3>
                </Grid>

                <Grid item xs={12}>
                  <Typography>
                    Only .png, .jpeg and .jpg file are supported
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Divider component='hr' variant='fullWidth' />
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.payableAmount}>
                    Upload PDF Footer
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <IconButton>
                    <CloudUploadIcon
                      fontSize='large'
                      classes={{ fontSizeLarge: classes.fontSizeIcon }}
                    />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Upload your file here
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <label htmlFor="upload-footer">
                    <input
                      style={{ display: 'none' }}
                      id="upload-footer"
                      name="upload-footer"
                      type="file"
                      accept="application/pdf, image/jpeg, image/jpg, image/png"
                      onChange={e => this.handleFileChange(e, 'pdf_footer_image')}
                    />

                    <Button color="primary" variant="outlined" component="span" className={classes.buttonAccession}
                    >
                      Upload Image
                    </Button>
                  </label>
                </Grid>

                <Grid item xs={10}>
                  <h3>{pdfFooter}</h3>
                </Grid>

                <Grid item xs={12}>
                  <Typography>
                    Only .png, .jpeg and .jpg file are supported
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  {openHeaderDialog ? this.handleUploadImage(openHeaderDialog, this.handleCloseUploadHeaderImage)
                    : openFooterDialog ? this.handleUploadImage(openFooterDialog, this.handleCloseUploadFooterImage)
                      : ''
                  }
                </Grid>

              </Grid>
            </Container>
          </Paper>
        </Grid>

        <Grid item xs={1}>
          <Button
            variant='outlined'
            color='primary'
            className={classes.buttonAccession}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item xs={1}>
          <Button
            variant='contained'
            color='primary'
            className={classes.buttonAccession}
            onClick={handleHeaderPrevious}
          >
            Previous
          </Button>
        </Grid>

        <Grid item xs={10}>
          <Grid container justify='flex-end'>
            {
              editList.length !== 0 ?
                <Button
                  type='submit'
                  variant='outlined'
                  color='primary'
                  className={classes.buttonAccession}
                  onSubmit={this.handleAddOrganization}
                >
                  Update Organization
              </Button> :
                <Button
                  type='submit'
                  variant='outlined'
                  color='primary'
                  className={classes.buttonAccession}
                  onSubmit={this.handleAddOrganization}
                >
                  Add Organization
              </Button>
            }
          </Grid>
        </Grid>
      </Grid>
    )
  }

  handleUploadImage = (openDialog, handleCloseDialog) => {
    return (
      <UploadImageDialogue
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
      />
    )
  }

  handleOpenUploadHeaderImage = () => {
    this.setState({ openHeaderDialog: true })
  }

  handleCloseUploadHeaderImage = () => {
    this.setState({ openHeaderDialog: false })
  }

  handleOpenUploadFooterImage = () => {
    this.setState({ openFooterDialog: true })
  }

  handleCloseUploadFooterImage = () => {
    this.setState({ openFooterDialog: false })
  }

  handleAddOrganization = async (e) => {
    const { organisation_name, payment_mode } = this.state;
    const { editList, handleCloseDialog } = this.props;
    e.preventDefault();
    this.setState({ errors: false, openSnackBar: true, paymentModeError: false })
    if ( payment_mode !== '' ) {
      if (organisation_name === '') {
        this.setState({ errors: true })
      } else if (editList.length !== 0) {
        await this.props.editOrganization(this.state);
        if (this.props.editOrganizationSuccess) {
          this.props.listUpdate();
          handleCloseDialog();
        }
      } else {
        await this.props.addOrganization(this.state);
        if (this.props.addOrganizationSuccess) {
          this.props.listUpdate();
          this.setState(this.reset)
          this.props.handleCloseDialog();
        }
      }
    } else this.setState({ paymentModeError: true })
  }

  render() {
    const { errors, organisation_name, openSnackBar } = this.state;
    const { organizationTab } = this.props;
    return (
      <div>
        <form onSubmit={this.handleAddOrganization}>
          {
            organizationTab === 0 ? this.handleOrganizationInfo() :
              (organizationTab === 1 ? this.handleLoginAndAccess() :
                (organizationTab === 2 ? this.handleHeaderAndFooter() : ''))
          }
        </form>
        <div>
          {errors && organisation_name === '' ?
            <Snackbar
              open={openSnackBar}
              autoHideDuration={6000}
              onClose={this.handleCloseSnackBar}
              message='Please fill all mandatory fields'
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              action={
                <React.Fragment>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleCloseSnackBar}
                  >
                    <CloseIcon />
                  </IconButton>
                </React.Fragment>
              }
            /> : ''
          }

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  addOrganizationSuccess: state.login.addOrganizationSuccess,
  organizationNameError: state.login.errorMessagesOrganization.organizationNameError,
  editOrganizationSuccess: state.login.editOrganizationSuccess,

  userNameError: state.login.errorMessagesOrganization.userNameError,
  userNameUpdateError: state.login.errorMessagesUpdateOrganization.userNameError,
  organizationUpdateNameError: state.login.errorMessagesUpdateOrganization.organizationNameError,
})

export default connect(mapStateToProps, {
  addOrganization,
  editOrganization,
})(OrganizationInfo);