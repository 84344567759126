import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import {
  Container,
  Grid,
  Typography,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
} from '@material-ui/core';

import {
  FilterList,
  Refresh,
  ArrowDropDown,
} from '@material-ui/icons';

import TableHeadSort from '../../Common/TableHeadSort';
import { getOperationTests } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class CancelledReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from_date: null,
      to_date: null,
      department: 0,
      rows: [],
      page: 0,
      rowsPerPage: 15,
      order: 'asc',
      orderBy: 'organisation_name',
      openSearch: false,
    }
    this.handleCancelledReports();
  }

  handleCancelledReports = async() => {
    await this.props.getOperationTests({test_status: 6, department: this.state.department, from_date: this.state.from_date, to_date: this.state.to_date})
    this.setState({ rows: this.props.operationTests.list })
    console.log(this.props.operationTests)
  }

  handleEvent = async(event, picker) => {
    if (event.type === 'apply') {
      this.setState({
        from_date: picker.startDate,
        to_date: picker.endDate
      })
    }
    await this.props.getOperationTests({
      from_date: picker.startDate._d,
      to_date: picker.endDate._d,
      test_status: 6,
      department: this.state.department,
    })
    this.setState({ rows: this.props.operationTests.list })
  }

  handleRequestSort = (e, property) => {                                              //Sorting
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  }

  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  handleMenuOpen = (id, event) => {
    let { anchorEl } = this.state;
    anchorEl[id] = event.target;
    this.setState({ anchorEl });
  }

  handleOptionsClose = () => {
    this.setState({ anchorEl: [] })
  }

  handleIconButtonClick = () => {
    this.setState({ openSearch: !this.state.openSearch })
  }

  handlePageChange = (event, newPage) => {                                 //Pagination
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 })
  }

  handleBillId = (e) => {                                                   //Individual Search
    var updatedList = this.state.rows;

    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.bill_id.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1;
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.operationTests.list })
    }
  }

  handleTest = async(e) => {
    if (e.target.value !== '') {
      await this.props.getOperationTests({ test_name: e.target.value })
      this.setState({ rows: this.props.operationTests.list })
    } else {
      this.setState({ rows: this.props.operationTests.list })
    }
  }

  handleTestStatus = (e) => {
    var updatedList = this.state.rows;

    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.test_status.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1;
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.operationTests.list })
    }
  }

  handlePatientDetails = (e) => {
    var updatedList = this.state.rows;

    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.patient_name.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1;
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.operationTests.list })
    }
  }

  render() {
    const { from_date, to_date, rows, page, rowsPerPage, order, orderBy, openSearch } = this.state;
    const headCells = [
      { id: 'patient_id', label: 'Patient Id' },
      { id: 'patientdetails', label: 'Patient Details' },
      { id: 'referral', label: 'Referral' },
      { id: 'testname', label: 'Test Name' },
      { id: 'date', label: 'Date' },
      { id: 'status', label: 'Status' },
    ];
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
      <Container component="main" maxWidth={false} classes={{ root: classes.containerPadding }}>
        <Grid container alignItems='center' spacing={2}>
          <Grid item xs={5}>
            <Typography>
              Cancelled Reports&nbsp;
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Grid container justify='flex-end' alignItems='center' alignContent='center' spacing={1}>
              <Grid item>
                {
                  openSearch ? 
                    <Button variant='outlined' className={classes.buttonText} onClick={this.handleIconButtonClick}>
                      Clear Filter&nbsp;<Refresh fontSize='small' className={classes.buttonShadow} />
                    </Button>
                  :
                    <Button variant='outlined' className={classes.buttonText} onClick={this.handleIconButtonClick}>
                      Filter Rows&nbsp;<FilterList fontSize='small' className={classes.buttonShadow} />
                    </Button>
                }
              </Grid>
              <Grid item>
                <Button variant='outlined' fullWidth className={classes.datePickerButton}>
                  <DateRangePicker onEvent={this.handleEvent} opens='center'>
                    {(from_date && to_date) ? <span>{from_date.format('DD-MM-YYYY').toString()} to {to_date.format('DD-MM-YYYY').toString()}</span> :
                      <span className={classes.buttonText}>Select Date Range</span>}
                  </DateRangePicker>
                </Button>
              </Grid>
              <Grid item>
                {/* <Button variant='outlined' className={classes.buttonText}>
                  Export<ArrowDropDown fontSize='small' className={classes.buttonShadow} />
                </Button> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Table aria-label="simple table">
              <TableHead style={{ minWidth: 650 }}>
                <TableRow>
                  <TableHeadSort
                    headCells={headCells}
                    order={order}
                    orderBy={orderBy}
                    openSearch={openSearch}
                    onRequestSort={this.handleRequestSort}
                    handleButtonClick={this.handleIconButtonClick}
                  />
                </TableRow>
                {openSearch ?
                  <TableRow>
                    <TableCell align="center">
                      <TextField
                        margin='dense'
                        variant='outlined'
                        placeholder='Patient Id'
                        onChange={this.handleBillId}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        margin='dense'
                        variant='outlined'
                        placeholder='Patient Details'
                        onChange={this.handleTest}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        margin='dense'
                        variant='outlined'
                        placeholder='Referral'
                        onChange={this.handleReferral}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        margin='dense'
                        variant='outlined'
                        placeholder='Test Name'
                        onChange={this.handleTestStatus}
                      />
                    </TableCell>
                    <TableCell align="center">
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        margin='dense'
                        variant='outlined'
                        placeholder='Status'
                        onChange={this.handleOrganization}
                      />
                    </TableCell>
                  </TableRow>
                  : null
                }
              </TableHead>
              <TableBody>
                {this.stableSort((rowsPerPage > 0
                  ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : rows), this.getComparator(order, orderBy)).map((row, index) => {
                    const backgroundStyle = row.test_status === 'Incomplete' || row.test_status === 'Partially Completed' ? classes.backgroundWhite : classes.backgroundLightGrey
                    return (
                      <React.Fragment key={index}>
                        <TableRow className={backgroundStyle} id='demo' onClick={e => row.test_status === 'Partially Completed' ? this.handleDialog(row) : null}>
                          <TableCell component="th" scope="row" style={{ borderBottom: 'unset' }}>{row.bill_id}</TableCell>
                          <TableCell align="left" className={classes.tableCell}>{row.test_name}</TableCell>
                          <TableCell align="left" className={classes.tableCell}>{row.patient_name}
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell align="left" className={classes.tableCell}>({row.age}-{row.gender.charAt(0)})</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableCell>
                          <TableCell align="center" className={classes.tableCell}>{row.test_status}</TableCell>
                          <TableCell align="center" className={classes.tableCell}>
                            {row.bill_date}
                          </TableCell>
                          <TableCell align="center" className={classes.tableCell}>{row.referrer_name}</TableCell>
                          <TableCell align="center" className={classes.tableCell}>{row.organisation_name}</TableCell>
                          {row.test_status === 'Partially Completed' ?
                            <React.Fragment>
                              <TableCell align="center" className={classes.tableCell}><Button variant='contained' style={{ backgroundColor: '#87ceeb' }} className={classes.buttonAccession}>Preview</Button></TableCell>
                              <TableCell align="center" className={classes.tableCell}>
                                <Button variant='contained' className={classes.buttonAccession} color='primary'>Sign</Button>
                              </TableCell>
                            </React.Fragment>
                            : ''
                          }
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            {row.det}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )
                  })}
                {emptyRows > 0 && emptyRows === 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>

        </Grid>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  operationTests: state.login.operationTests,
})

export default connect(mapStateToProps, {
  getOperationTests,
})(CancelledReports);