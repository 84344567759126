import React from 'react';
// import clsx from 'clsx';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  Typography,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  TablePagination,
} from '@material-ui/core';

import TableHeadSort from '../../Common/TableHeadSort';
import AddEditDepartment from '../AddEditDepartment';
import { getAccessionDepartmentsList, searchDepartmentList } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class DepartmentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      rows: [],
      page: 0,
      rowsPerPage: 100,
      order: 'asc',
      orderBy: 'department_list',
      openSearch: false,
      open: false,
      edit: false,
      editData: [],
    }
    this.handleDepartmentList();
  }

  handleDepartmentList = async() => {
    await this.props.getAccessionDepartmentsList();
    this.setState({ rows: this.props.departmentsList })
  }

  handleSearchDepartment = async(e) => {
    this.setState({ [e.target.name]: e.target.value })
    if(e.target.value !== '') {
      await this.props.searchDepartmentList({search: e.target.value})
      this.setState({ rows: this.props.searchDepartment, page: 0 })
    } else {
      this.setState({ rows: this.props.departmentsList })
      this.handleDepartmentList();
    }
  }

  handleRequestSort = (e, property) => {                                              //Sorting
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  }

  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  handleMenuOpen = (id, event) => {
    let { anchorEl } = this.state;
    anchorEl[id] = event.target;
    this.setState({ anchorEl });
  }

  handleOptionsClose = () => {
    this.setState({ anchorEl: [] })
  }

  handleIconButtonClick = () => {
    this.setState({ openSearch: !this.state.openSearch })
  }

  handlePageChange = (event, newPage) => {                                 //Pagination
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 })
  }

  handleDepartmentNameSearch = (e) => {
    var updatedList = this.state.rows;
    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.department_name.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1;
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.departmentsList })
    }
  }

  handleDepartmentCodeSearch = (e) => {
    var updatedList = this.state.rows;
    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.department_code.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1;
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.departmentsList })
    }
  }

  handleAddDepartment = () => {
    this.setState({ open: true })
  }

  handleCloseDialog = () => {
    this.setState({ open: false, edit: false })
  }

  handleEditDepartment = (row) => {
    this.setState({ open: true, editData: row, edit: true })
  }

  render() {
    const { search, rows, page, rowsPerPage, order, orderBy, openSearch, open, edit, editData } = this.state;
    const headCells = [
      { id: 'no', label: 'No' },
      { id: 'department_name', label: 'Department Name' },
      { id: 'department_code', label: 'Department Code' },
    ];
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
      <Container component="main" maxWidth={false} classes={{ root: classes.containerPadding }}>
        <Grid container alignItems='center' spacing={2}>
          <Grid item xs={6}>
            <Typography variant='h6'><b>Department List</b></Typography>
          </Grid>

          <Grid item xs={6}>
            <Grid container justify='flex-end'>
              <Button
                color='primary'
                variant='outlined'
                className={classes.buttonText}
                onClick={this.handleAddDepartment}
              >
                Add Department
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <TextField
              name='search'
              value={search}
              onChange={this.handleSearchDepartment}
              fullWidth
              variant='outlined'
              margin='dense'
              placeholder='Search Department Name'
            />
          </Grid>

          <Grid item xs={6}>
            <TablePagination
              component="div"
              count={0}
              // count={this.props.testProfileList.totalRecords || 0}
              rowsPerPageOptions={[5, 10, 15, 25, { label: 'All', value: -1 }]}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handlePageChange}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Grid>

          <Grid item xs={12}>
            <Table aria-label="simple table">
              <TableHead style={{ minWidth: 650 }} >
                <TableRow>
                  <TableHeadSort
                    enableIcon
                    headCells={headCells}
                    order={order}
                    orderBy={orderBy}
                    openSearch={openSearch}
                    onRequestSort={this.handleRequestSort}
                    handleButtonClick={this.handleIconButtonClick}
                  />
                </TableRow>
                {openSearch ?
                  <TableRow>
                    <TableCell>
                    </TableCell>
                    <TableCell align='left'>
                      <TextField
                        fullWidth
                        margin='dense'
                        variant='outlined'
                        placeholder='Department Name'
                        onChange={this.handleDepartmentNameSearch}
                      />
                    </TableCell>
                    <TableCell align='left'>
                      <TextField
                        fullWidth
                        margin='dense'
                        variant='outlined'
                        placeholder='Department Code'
                        onChange={this.handleDepartmentCodeSearch}
                      />
                    </TableCell>
                  </TableRow>
                  : null
                }
              </TableHead>
              
              <TableBody>
                {this.stableSort((rowsPerPage > 0 && rows.length > 0
                  ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : rows), this.getComparator(order, orderBy)).map((row, index) => (
                    <TableRow
                      key={index} id={row.id} onClick={e => this.handleEditDepartment(row)}
                      className={classes.pointer}
                    >
                      <TableCell component="th" scope="row">{index+1}</TableCell>
                      <TableCell align="left">{row.department_name}</TableCell>
                      <TableCell align="left">{row.department_code}</TableCell>
                    </TableRow>
                  ))
                }
                {emptyRows > 0 && emptyRows === 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>

          <Grid item xs={12}>
            {
              open ? 
                <AddEditDepartment
                  open={open}
                  edit={edit}
                  handleCloseDialog={this.handleCloseDialog} 
                  data={editData}
                />
              : '' 
            }
          </Grid>
        </Grid>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  departmentsList: state.login.departmentsList,
  searchDepartment: state.login.searchDepartment,
})

export default connect(mapStateToProps, {
  getAccessionDepartmentsList,
  searchDepartmentList,
})(DepartmentList);