import React from 'react';

class AccessionIdRenderer extends React.Component {
  render() {
    let data = this.props.node.data
    return(
      <div>
        {data.test_barcode}
        <div>
          {data.comments}
        </div>
      </div>
    );
  }
}

export default AccessionIdRenderer;