import React from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';

import {
  Grid,
} from '@material-ui/core';

import BillIDRenderer from './BillIDRenderer';
import PatientDetailsRenderer from './PatientDetailsRenderer';
import AccessionIDRenderer from './AccessionIDRenderer';
import SamplesRenderer from './SamplesRenderer';
import StatusRenderer from './StatusRenderer';
import ButtonRenderer from './ButtonRenderer';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

class BillWise extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        { headerName: 'Bill Id', field: 'bill_id', filter: 'agTextColumnFilter', cellRenderer: 'billIdRenderer', width: 200, cellStyle: {lineHeight: '30px'} },
        { headerName: 'Patient Details', field: 'patient_details', filter: 'agTextColumnFilter', cellRenderer: 'patientDetailsRenderer', width: 250, cellStyle: {lineHeight: '30px'} },
        { headerName: 'Accession Id', field: 'accession_id', filter: 'agTextColumnFilter', cellRenderer: 'accessionIdRenderer', width: 200, cellStyle: {marginTop:'5px',lineHeight: '20px'} },
        { headerName: 'Sample', field: 'sample', filter: 'agTextColumnFilter', cellRenderer: 'samplesRenderer', width: 200, cellStyle: {marginTop:'5px',lineHeight: '20px'} },
        { headerName: 'Status', field: 'status', filter: 'agTextColumnFilter', cellRenderer: 'statusRenderer', width: 125, cellStyle: {lineHeight: '30px'} },
        { headerName: 'Billing Date', field: 'billing_date', filter: 'agTextColumnFilter', cellRenderer: 'buttonRenderer', width: 480, cellStyle: {lineHeight: '30px'} },
      ],
      frameworkRenderer: {
        billIdRenderer: BillIDRenderer,
        patientDetailsRenderer: PatientDetailsRenderer,
        accessionIdRenderer: AccessionIDRenderer,
        samplesRenderer: SamplesRenderer,
        statusRenderer: StatusRenderer,
        buttonRenderer: ButtonRenderer,
      }
    }
  }

  rowHeightRenderer = () => {
    return 100;
  }

  render() {
    const { columnDefs } = this.state;
    const { data } = this.props;
    return (
      <Grid className='ag-theme-material' style={{ height: '750px' }} >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={data}
          getRowHeight={this.rowHeightRenderer}
          frameworkComponents={this.state.frameworkRenderer}
        />
      </Grid>
    )
  }
}

export default BillWise;