import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  Tabs,
  Tab,
} from '@material-ui/core';

import AddUpdateUser from './AddUpdateUser';
// import AddUser from './AddUser';
import { getPermissionsList, getUserList, getCenterList } from '../Store/ActionCreators';

import classes from '../App.module.css';

class UserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userTab: 0,
    }
  }

  componentDidMount() {
    this.props.getPermissionsList();
    this.props.getUserList();
    this.props.getCenterList();
  }

  handleTabChange = (event, newValue) => {
    this.setState({ userTab: newValue })
  }

  handleTabEvent = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  render() {
    const { userTab } = this.state;

    return (
      <Container maxWidth={false} classes={{ root: classes.containerPadding }}>
        <Grid container direction='row' justify='center' spacing={2}>
          <Grid item xs={12}>
            <Tabs
              value={userTab}
              onChange={this.handleTabChange}
              indicatorColor='primary'
              variant='fullWidth'
            >
              <Tab className={clsx(classes.buttonText, classes.drawerMainList)} label="Update"  {...this.handleTabEvent(1)} />
              <Tab className={clsx(classes.buttonText, classes.drawerMainList)} label="Add"  {...this.handleTabEvent(2)} />
            </Tabs>
          </Grid>

          <Grid item xs={12}>
            {(userTab === 0 || userTab === 1) && this.props.permissionsList.length !== 0 && this.props.userList.length !== 0
             ? (userTab === 1 ? 
                <AddUpdateUser tab={userTab} 
                  permissionsList={this.props.permissionsList} 
                  userList={this.props.userList}
                  centerList={this.props.centerList}
                /> 
              : 
                <AddUpdateUser tab={userTab} tabChange={true} 
                  permissionsList={this.props.permissionsList} 
                  userList={this.props.userList}
                  centerList={this.props.centerList}
                />)
              : ''}
          </Grid>

        </Grid>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  permissionsList: state.login.permissionsList,
  userList: state.login.userList,
  centerList: state.login.getCenterList,
});

export default connect(mapStateToProps, {
  getPermissionsList,
  getUserList,
  getCenterList,
})(UserManagement);