import React from 'react';

import {
  Grid,
  Tooltip,
} from '@material-ui/core';

class TestsRenderer extends React.Component {

  tests = (tests, scroller) => {
    let testObject = JSON.parse(tests);
    return (
      <div style={scroller} className='scroller'>
        {Object.entries(testObject).map(([testid, testname], index) => {
          let testCount = Object.keys(testObject).length;
          index++;
          return (
            <span key={testid}>{index < 5 ?
              <span><div />{testname.length > 42 ? <Tooltip title={testname}><span>{testname.substring(0, 42)}...</span></Tooltip> : testname}</span> : (testCount > 4 ?
                <span>&nbsp;&nbsp;<Tooltip placement="top" title={<div style={{ maxWidth: 100 }}>{Object.values(testObject).map((item, index) => { return <div key={index}>{item}</div>})}</div>}><span style={{ color: 'blue' }}>more</span></Tooltip></span> : '')}
            </span>
          )
        })}
      </div>
    )
  }

  render() {
    const scroller = {
      maxHeight: "90px",
      overflowY: 'auto'
    };
    return (
      <Grid>
        {this.tests(this.props.node.data.test_name, scroller)}
      </Grid>
    );
  }
}

export default TestsRenderer;