import React from 'react';
import { connect } from 'react-redux';

import { appReset } from '../Store/ActionCreators';

class Reset extends React.Component {
  render() {
    return(
      <div>
        {this.props.appReset()}
      </div>
    );
  }
}

export default connect(null, { appReset })(Reset);