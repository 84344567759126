import React from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';

import {
  Grid,
  Select,
  MenuItem,
  Container,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  Snackbar,
  IconButton,
} from '@material-ui/core';

import {
  Close,
} from '@material-ui/icons';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import BatchManagement from '../../BatchManagement/CreateBatch';
import AccessionIDRenderer from '../PendingSamples/SampleWise/AccessionIDRenderer';
import PatientDetailsRenderer from '../PendingSamples/BillWise/PatientDetailsRenderer';
import TestsRenderer from '../PendingSamples/SampleWise/TestsRenderer';
import SamplesRenderer from '../PendingSamples/SampleWise/SampleRenderer';
import ButtonRenderer from './ButtonsRenderer';

import { getAccessedTests, getAccessionDepartmentsList, validateBatchTests } from '../../Store/ActionCreators';
import classes from '../../App.module.css';

class Accessed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      department: '',
      branch_id: 1,
      outsourced_status: '0',
      openCreateBatchDialog: false,
      gridData: [],
      openCreateBatchButton: true,
      selectAllTests: false,
      selectedTests: [],
      openSnackBar: false,
      allAccessedSamples: false,
      accessedTests: [],

      columnDefs: [
        {
          headerName: 'Accession Id', field: 'accession_id', filter: "agTextColumnFilter", cellRenderer: 'accessionIdRenderer', width: 250, cellStyle: { lineHeight: '30px' },
          checkboxSelection: true
        },
        { headerName: 'Patient Details', field: 'patient_details', filter: "agTextColumnFilter", cellRenderer: 'patientDetailsRenderer', width: 210, cellStyle: { lineHeight: '30px' } },
        { headerName: 'Tests', field: 'tests', filter: "agTextColumnFilter", cellRenderer: 'testsRenderer', width: 310, cellStyle: { marginTop: '5px', lineHeight: '20px' } },
        { headerName: 'Sample', field: 'sample', filter: "agTextColumnFilter", cellRenderer: 'sampleRenderer', width: 150, cellStyle: { lineHeight: '30px' } },
        { headerName: 'Billing Date', field: 'billing_date', filter: "agTextColumnFilter", cellRenderer: 'buttonRenderer', width: 380, cellStyle: { lineHeight: '30px' } },
      ],
      frameWorkRenderer: {
        accessionIdRenderer: AccessionIDRenderer,
        patientDetailsRenderer: PatientDetailsRenderer,
        testsRenderer: TestsRenderer,
        sampleRenderer: SamplesRenderer,
        buttonRenderer: ButtonRenderer,
      },
      rowSelection: 'multiple',
    }
    this.handleAccessedList();
    this.props.getAccessionDepartmentsList();
  }

  handleAccessedList = async() => {
    const { department, branch_id } = this.state;
    await this.props.getAccessedTests({ department, branch_id })
    this.setState({ accessedTests: this.props.accessedTestList })
  }

  handleSelectChange = (e) => {
    const { department, branch_id, outsourced_status } = this.state;
    this.setState(oldValues =>
      ({
        ...oldValues,
        [e.target.name]: e.target.value,
      }), () => (
        this.props.getAccessedTests({ department, branch_id, outsourced_status })
      )
    )
  }

  handleCreateBatchDialog = async() => {
    const { department, branch_id, outsourced_status } = this.state;
    await this.props.getAccessedTests({ department, branch_id, outsourced_status })
    this.setState({ accessedTests: this.props.accessedTestList })
    this.setState({ openCreateBatchButton: false })
  }

  handleCheckBox = (e) => {
    this.setState({ [e.target.name]: e.target.checked },
      () => {
        if (this.state.selectAllTests) {
          this.setState({ selectedTests: this.props.accessedTestList })
          this.state.gridData.selectAll();
        } else {
          this.setState({ selectedTests: [] })
          this.state.gridData.deselectAll();
        }
      }
    )
  }

  handleCancelBatch = () => {
    this.handleAccessedList();
    this.setState({ openCreateBatchButton: true })
  }

  handleAllAccessedSamples = async() => {
    const { accessedTests } = this.state;
    var values = [], obj = {};

    accessedTests.map((item,index) => {
      let variable = item.data !== undefined ? item.data : item;
      values[index] = Object.keys(JSON.parse(variable.test_name))
    })
    obj = {...obj, patient_test_ids: values}
    await this.props.validateBatchTests(obj)

    if(this.props.batchValidationSuccess) {
      if (accessedTests.length !== 0) {
        this.setState({ allAccessedSamples: true, })
      }
    }
  }

  handleNext = async() => {
    const { selectedTests } = this.state;
    var values = [], obj = {};

    selectedTests.map((item,index) => {
      let variable = item.data !== undefined ? item.data : item;
      values[index] = Object.keys(JSON.parse(variable.test_name))
    })
    obj = {...obj, patient_test_ids: values}
    await this.props.validateBatchTests(obj)

    if(this.props.batchValidationSuccess) {
      if (selectedTests.length !== 0) {
        this.setState({ openCreateBatchDialog: true })
      } else this.setState({ openSnackBar: true })
    }
  }

  handleCloseDialog = () => {
    this.setState({ openCreateBatchDialog: false, allAccessedSamples: false })
  }

  handleCloseSnackBar = () => {
    this.setState({ openSnackBar: false })
  }

  onGridReady = (params) => {
    this.setState({ gridData: params.api })
  };

  handleSelectTests = (row) => {
    this.setState({ selectedTests: row.api.getSelectedNodes() })
  }

  setRowHeight = () => {
    return 100;
  }

  handleAccessedGrid = (data) => {
    const { columnDefs, frameWorkRenderer } = this.state;
    return (
      <Grid className="ag-theme-material sw-ag-grid">
        <AgGridReact
          rowData={data}
          columnDefs={columnDefs}
          rowMultiSelectWithClick={true}
          onGridReady={this.onGridReady}
          getRowHeight={this.setRowHeight}
          onRowClicked={this.handleSelectTests}
          rowSelection={this.state.rowSelection}
          frameworkComponents={frameWorkRenderer}
        />
      </Grid>
    )
  }

  setRowHeight = () => {
    return 100;
  }

  render() {
    const { department, openCreateBatchDialog, openCreateBatchButton, selectAllTests, openSnackBar, selectedTests, allAccessedSamples,
      accessedTests,
    } = this.state;
    const { departmentsList } = this.props;
    return (
      <Container component="main" maxWidth={false} classes={{ root: classes.containerPadding }}>
        <Grid container spacing={2} justify="flex-end" alignItems="flex-end">
          <Grid item xs={10}><Typography><b>Accessed</b></Typography></Grid>
          <Grid item xs={2}>
            <Grid container justify='flex-end'>
              {
                openCreateBatchButton ?
                  <Button
                    color='primary'
                    variant='outlined'
                    onClick={this.handleCreateBatchDialog}
                    className={classes.buttonText}
                  >
                    Create Batch
                  </Button>
                  : ''
              }
            </Grid>
          </Grid>

          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <Grid container justify='flex-end'>
              <Select
                name='department'
                value={department}
                onChange={this.handleSelectChange}
                variant='outlined'
                margin="dense"
                MenuProps={{
                  style: {
                    maxHeight: 280,
                    width: 250,
                  }
                }}
              >
                {departmentsList.map((item, index) => (
                  <MenuItem key={index} value={item.id}>{item.department_name}</MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Grid item xs={9}>
            <FormControlLabel
              name='selectAllTests'
              value={selectAllTests}
              control={<Checkbox color='primary' />}
              label='Select All'
              labelPlacement='end'
              onClick={this.handleCheckBox}
            />
          </Grid>
          <Grid item xs={3}>
            {
              !openCreateBatchButton ?
                <Grid container justify='flex-end'>
                  <Button
                    color='primary'
                    variant='outlined'
                    onClick={this.handleCancelBatch}
                    className={classes.buttonText}
                  >
                    Cancel Batch
                  </Button>&nbsp;

                  <Button
                    color='primary'
                    variant='contained'
                    onClick={this.handleNext}
                    className={classes.buttonText}
                  >
                    Next
                  </Button>
                </Grid>
                : ''
            }
          </Grid>

          <Grid item xs={12}>
            <Snackbar
              open={openSnackBar}
              autoHideDuration={6000}
              onClose={this.handleCloseSnackBar}
              message='Please check atleast one checkbox for batch.'
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              action={
                <React.Fragment>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleCloseSnackBar}
                  >
                    <Close />
                  </IconButton>
                </React.Fragment>
              }
            /> 
          </Grid>

          <Grid item xs={12}>
            {this.handleAccessedGrid(accessedTests)}
          </Grid>

          <Grid item xs={12}>
            {
              openCreateBatchDialog ?
                <BatchManagement
                  data={allAccessedSamples ? accessedTests : selectedTests}
                  centersData={this.props.validatedBatchTests}
                  handleClose={this.handleCloseDialog}
                  handleAllAccessedSamples={this.handleAllAccessedSamples}
                  openCreateBatch={openCreateBatchDialog}
                />
                : null
            }
          </Grid>

        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  accessedTestList: state.login.accessedTests,
  departmentsList: state.login.departmentsList,
  batchValidationSuccess: state.login.batchValidationSuccess,
  validatedBatchTests: state.login.validatedBatchTests,
})

export default connect(mapStateToProps, { getAccessedTests, validateBatchTests, getAccessionDepartmentsList })(Accessed);