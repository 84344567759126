import React from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  Link,
  Menu,
  Button,
  MenuItem,
  Drawer,
} from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import AdminList from './DrawerLists/AdminList';
import OperationList from './DrawerLists/OperationList';
import RegistrationList from './DrawerLists/RegistrationList';
import FinanceList from './DrawerLists/FinanceList';
import ReviewerList from './DrawerLists/ReviewerList';
import AccessionList from './DrawerLists/AccessionList';
import InventoryList from './DrawerLists/InventoryList';
import AnalyticsList from './DrawerLists/AnalyticsList';

import listType from '../Data/drawerList.json';
import classes from '../App.module.css';

class SideDrawerLayout extends React.Component {

  static propTypes = {
    openDrawer: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 'admin',
      openDropDown: false,
    }
    this.drawerButtonref = React.createRef();
  }

  handleToggle = () => {
    this.setState({ openDropDown: !this.state.openDropDown })
  }

  handleSelectChange = (e) => {
    this.setState(oldValues => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  }

  handleMenuItemClick = (e, route) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value, selectedIndex: route })
    window.open('/' + route)
  }

  handleRenderComponent = (id) => {
    switch (id) {
      case '1':
        return <AdminList />;
      case '2':
        return <OperationList />;
      case '3':
        return <RegistrationList />;
      case '4':
        return <FinanceList />;
      case '5':
        return <ReviewerList />;
      case '6':
        return <AccessionList />;
      case '7':
        return <InventoryList />;
      case '8':
        return <AnalyticsList />;
      default:
        return <AdminList />;
    }
  }

  render() {
    let dropDownValue;
    const { openDropDown } = this.state;
    return (
      <div>
        <Drawer open={this.props.openDrawer} variant="persistent">
          <Grid container direction='column'>
            <main className={classes.drawer}>
              <Button
                ref={this.drawerButtonref}
                onClick={this.handleToggle}
                onChange={this.handleSelectChange}
                className={classes.headerBtn1}
              >
                {listType.map(item => {
                  if ('/' + item.route === window.location.pathname) {
                    dropDownValue = item.id;
                    return item.list
                  } else if (window.location.pathname === '/' || window.location.pathname === '/home'
                      || window.location.pathname === '/reportlist' || window.location.pathname === '/addreport'
                      || window.location.pathname === '/billsettings' || window.location.pathname === '/invoicesettings'
                      || window.location.pathname === '/doctor-list' || window.location.pathname === '/addoutsource'
                      || window.location.pathname === '/reportsettings' || window.location.pathname === '/referral-list'
                      || window.location.pathname === '/doctor-list' || window.location.pathname === '/organization-list'
                      || window.location.pathname === '/adduser' || window.location.pathname === '/list-management'
                      || window.location.pathname === '/profile-list' || window.location.pathname === '/department-list'
                      || window.location.pathname === '/center-list'
                  ) {
                    if (item.list === 'Admin') {
                      dropDownValue = item.id
                      return item.list
                    }
                  } else if (window.location.pathname === '/patient-waitinglist' + '/total' || window.location.pathname === '/patient-waitinglist' + '/pending'
                      || window.location.pathname === '/patient-waitinglist' + '/partial' || window.location.pathname === '/patient-waitinglist' + '/completed'
                      || window.location.pathname === '/patient-waitinglist' + '/signed'  || window.location.pathname === '/patient-waitinglist' + '/emergency'
                      || window.location.pathname === '/test-waitinglist' + '/total' || window.location.pathname === '/test-waitinglist' + '/pending'
                      || window.location.pathname === '/test-waitinglist' + '/partial' || window.location.pathname === '/test-waitinglist' + '/completed'
                      || window.location.pathname === '/test-waitinglist' + '/signed'  || window.location.pathname === '/test-waitinglist' + '/emergency'
                      || window.location.pathname === '/test-waitinglist' + '/critical' || window.location.pathname === '/patient-waitinglist' + '/critical'
                      || window.location.pathname === '/test-waitinglist' + '/tat-exceeded' || window.location.pathname === '/patient-waitinglist' + '/tat-exceeded'
                      || window.location.pathname === '/test-waitinglist' + '/outsourced' || window.location.pathname === '/patient-waitinglist' + '/outsourced'
                      || window.location.pathname === '/operation' + '/cancelled-reports' || window.location.pathname === '/operation' + '/dispatched'
                      || window.location.pathname === '/center-list' || window.location.pathname === '/operation' + '/settings'
                      || window.location.pathname === '/archives'
                  ) {
                    if (item.list === 'Operation') {
                      dropDownValue = item.id
                      return item.list
                    }
                  } else if (window.location.pathname === '/billing' || window.location.pathname.split('/')[1] === 'bill'
                    || window.location.pathname === '/bill-settlements' || window.location.pathname === '/addtesttobill'
                    || window.location.pathname === '/allbills' || window.location.pathname.split('/')[1] === 'editbill'
                  ) {
                    if (item.list === 'Registration') {
                      dropDownValue = item.id
                      return item.list
                    }
                  } else if (window.location.pathname === '/accessed' || window.location.pathname === '/accession-settings'
                    || window.location.pathname === '/accession-settings' + '/bulk-edit' || window.location.pathname === '/accession-settings' + '/add'
                    || window.location.pathname === '/accession-settings' + '/edit' || window.location.pathname === '/batch-management'
                    || window.location.pathname === '/rejected-samples'
                  ) {
                    if (item.list === 'Accession') {
                      dropDownValue = item.id
                      return item.list
                    }
                  }
                  return false;
                })}
                <ArrowDropDownIcon />
              </Button>

              <Menu
                open={openDropDown}
                anchorEl={this.drawerButtonref.current}
                onClose={this.handleToggle}
              >
                {listType.map((item) => (item.id !== dropDownValue) ?
                  <Link href={item.route} key={item.id} underline="none">
                    <MenuItem
                      key={item.id}
                      onClick={event => this.handleMenuItemClick(event, item.route)}
                      className={classes.menuListHeader}
                    >
                      {item.list}
                    </MenuItem>
                  </Link> : null
                )}
              </Menu>
              <div className={classes.drawerMargin}>
                {this.handleRenderComponent(dropDownValue)}
              </div>

            </main>
            <Drawer open anchor='bottom' variant="persistent" classes={{ paperAnchorDockedBottom: classes.drawer }}>
              <div className={classes.paddingDrawer}>
                <Link target="_blank" href="https://intandemly.com/">
                  Version 1.0
                </Link>
                <p>@ 2019 Intandemly Technologies Pvt.Ltd</p>
              </div>
            </Drawer>
          </Grid>
        </Drawer>
      </div>
    );
  }
}

export default SideDrawerLayout;