import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  Menu,
  MenuItem,
  TextField,
  RadioGroup,
  Radio,
  ExpansionPanel,
  FormControlLabel,
  Typography,
  Checkbox,
  Box,
  Button,
} from '@material-ui/core';


import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import designationData from '../../Data/designation.json';
import flags from '../../Data/flags.json';
import { searchUser, createUser, updateUser } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class AddUpdateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      full_name: '',
      username: '',
      password: '',
      email: '',
      dob: null,
      mobile: '',
      designation: '',
      gender: '',
      branches: [],

      buttonName: 'Select user to update',
      selectedUserData: [],
      department: '',
      day: '',
      month: '',
      year: '',
      country_code_mobile: '+91',
      url: '',
      user: '',
      permissionsList: this.props.permissionsList,
      anchorEl: null,
      tabStatus: true,
      errors: false,
    }
    this.reset = this.state;
  }

  handleTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleCheckBox = (i, j, k, e) => {
    if (j === 0 && k === 0) {
      var stateCopy = Object.assign({}, this.state);
      if (stateCopy.permissionsList[i - 1].is_enable) {
        stateCopy.permissionsList[i - 1].is_enable = false;
      } else stateCopy.permissionsList[i - 1].is_enable = true;
      this.setState(stateCopy);
    } else if (k === 0) {
      var stateCopy2 = Object.assign({}, this.state);
      if (stateCopy2.permissionsList[i - 1].permissions[j - 1].is_enable) {
        stateCopy2.permissionsList[i - 1].permissions[j - 1].is_enable = false;
      } else stateCopy2.permissionsList[i - 1].permissions[j - 1].is_enable = true;
      this.setState(stateCopy2);
    } else {
      var stateCopy3 = Object.assign({}, this.state);
      if (stateCopy3.permissionsList[i - 1].permissions[j - 1].permissions[k - 1].is_enable) {
        stateCopy3.permissionsList[i - 1].permissions[j - 1].permissions[k - 1].is_enable = false;
      } else stateCopy3.permissionsList[i - 1].permissions[j - 1].permissions[k - 1].is_enable = true;
      this.setState(stateCopy3);
    }
  }

  handleUserData = (userData) => {
    if (userData !== null) {
      this.setState({
        selectedUserData: userData, anchorEl: null, buttonName: userData.username,
        branches: userData.branches,
        userref: userData.user_id,
        designation: userData.designation,
        username: userData.username,
        full_name: userData.full_name,
        gender: userData.gender,
        password: userData.password,
        email: userData.email,
        dob: userData.dob,
        mobile: userData.mobile,
        country_code_mobile: userData.country_code_mobile,
        permissionsList: userData.permissions !== null ? JSON.parse(userData.permissions) : this.props.permissionsList,
      })
    } else this.setState({ selectedUserData: [], anchorEl: null, buttonName: 'Select user to update' })
  }

  handleSelectChange = (e) => {
    this.setState(oldValues => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }))
  }

  handleDateChange = (date) => {
    if (date !== undefined) {
      this.setState({ dob: date })
    } else return '';
  }

  handleRadioButton = (e) => {
    if (e.target !== undefined && e.target.value !== undefined) {
      this.setState({ gender: e.target.value })
    } else this.setState({ gender: '' })
  }

  handleReset = () => {
    if (this.props.tab === 1) {
      this.setState(this.reset)
      this.setState({ tabStatus: false })
    } else {
      this.setState({ tabStatus: true })
    }
  }

  handleDepartment = () => {
    return (
      {/* <Grid item xs={12}>
          <Box
            display='flex'
            justifyContent='center'
            border={1}
            borderColor="grey.300"
            borderRadius={3}
          >
            <Grid container direction='row' justify='center' spacing={5}>
              <Grid item xs={2}>
                <Grid container justify='center'>
                  Departments
                </Grid>
              </Grid>

              <Grid item xs={10}>
                <Grid container direction='row' alignItems='center'>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Pathology'
                      labelPlacement='end'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Clinical Biochemistry'
                      labelPlacement='end'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Cardiology'
                      labelPlacement='end'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='CT-Scan'
                      labelPlacement='end'
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Dexa Scan'
                      labelPlacement='end'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Dopplers'
                      labelPlacement='end'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='EEG'
                      labelPlacement='end'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='ENT'
                      labelPlacement='end'
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Gastroentrology'
                      labelPlacement='end'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Gynaec'
                      labelPlacement='end'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Mammography'
                      labelPlacement='end'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='MRI'
                      labelPlacement='end'
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='OPG'
                      labelPlacement='end'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Paediatrics'
                      labelPlacement='end'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Physiotheraphy'
                      labelPlacement='end'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Ultra Sound'
                      labelPlacement='end'
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='X Ray'
                      labelPlacement='end'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Clinical Pathology'
                      labelPlacement='end'
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Cytology'
                      labelPlacement='end'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Hematology'
                      labelPlacement='end'
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Histopathology'
                      labelPlacement='end'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Microbiology'
                      labelPlacement='end'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Molecular & Genetics'
                      labelPlacement='end'
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Immunology'
                      labelPlacement='end'
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Special Biochemistry'
                      labelPlacement='end'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Package'
                      labelPlacement='end'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Radiology'
                      labelPlacement='end'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' />}
                      label='Other'
                      labelPlacement='end'
                    />
                  </Grid>

                </Grid>
              </Grid>

            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            display='flex'
            justifyContent='center'
            border={1}
            borderColor="grey.300"
            borderRadius={3}
          >
            <Grid container direction='row' alignItems='center' className={classes.textAlign}>
              <Grid item xs={2}>
                <Grid container justify='center'>
                  Selected Departments
                </Grid>
              </Grid>

              <Grid item xs={5}>
                <Grid container direction='row' alignItems='center'>
                  <FormControl variant='outlined' margin='dense' fullWidth>
                    <InputLabel id='demo-simple-select-outlined-label'>Select Default Department</InputLabel>
                    <Select
                      labelId='demo-simple-select-outlined-label'
                      id="demo-simple-select-outlined"
                      name='department'
                      value={department}
                      onChange={this.handleSelectChange}
                    >
                      <MenuItem value={10}>Select...</MenuItem>
                      <MenuItem value={20}>Ten</MenuItem>
                      <MenuItem value={30}>Twenty</MenuItem>
                      <MenuItem value={40}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid> */}
    )
  }

  handleExcessCode = () => {
    const { permissionsList } = this.state;
    return (
      <Grid>
        <Grid item xs={12}>
          <Grid container justify='center'>
            <Typography variant='h6'>
              Privileges
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {permissionsList.map((item, i) => {
            return (
              <div key={i}>
                {
                  item.is_parent === true ? (
                    <div>
                      <FormControlLabel
                        name={item.permission_name}
                        checked={item.is_enable}
                        value={item.permission_name}
                        control={<Checkbox color='primary' />}
                        label={item.permission_name}
                        labelPlacement='end'
                        onClick={e => this.handleCheckBox(i + 1, 0, 0, e)}
                      />
                      <div className={classes.containerPadding}>
                        {item.permissions.map((li, j) => (
                          li.is_parent === true ? (
                            <div key={j}>
                              <FormControlLabel
                                value={li.is_enable}
                                control={<Checkbox color='primary' />}
                                label={li.permission_name}
                                labelPlacement='end'
                                onClick={e => this.handleCheckBox(i + 1, j + 1, 0, e)}
                              /><div />
                              {li.permissions.map((ele, k) => (
                                <FormControlLabel
                                  key={k}
                                  value={ele.is_enable}
                                  control={<Checkbox color='primary' />}
                                  label={ele.permission_name}
                                  labelPlacement='end'
                                  className={classes.containerPadding}
                                  onClick={e => this.handleCheckBox(i + 1, j + 1, k + 1, e)}
                                />
                              ))}
                            </div>
                          ) :
                            <div key={j}>
                              <FormControlLabel
                                value={li.is_enable}
                                control={<Checkbox color='primary' />}
                                label={li.permission_name}
                                labelPlacement='end'
                                onClick={e => this.handleCheckBox(i + 1, j + 1, 0, e)}
                              />
                            </div>
                        ))}

                      </div>
                    </div>
                  )
                    :
                    <FormControlLabel
                      value={item.is_enable}
                      control={<Checkbox color='primary' />}
                      label={item.permission_name}
                      labelPlacement='end'
                    />
                }
              </div>
            )
          })}
        </Grid>

        {/* <Grid item xs={12}>
          <Box
            display='flex'
            justifyContent='flex-start'
            border={1}
            borderColor="grey.300"
            borderRadius={3}
          >&nbsp;&nbsp;
            <FormControlLabel
              value='end'
              control={<Checkbox color='primary' />}
              label='Collecting Person'
              labelPlacement='end'
            />
          </Box>
        </Grid> */}
        {/* {this.handleDepartment()} */}
      </Grid>
    )
  }

  handleSubmit = async () => {
    const { branches, full_name, username, designation, email, gender, country_code_mobile, mobile, permissionsList, userref, password } = this.state;
    let reqObjUpdate = {
      branches, full_name, username, password, designation, email, gender, country_code_mobile, mobile, permissions: permissionsList, userref 
    }
    let reqObj = {
      branches, full_name, username, password, designation, email, gender, country_code_mobile, mobile, permissions: permissionsList, userref 
    }
    if(full_name === '' || designation === '' || email === '' || gender === '' || mobile === '' || country_code_mobile === ''
      || branches.length === 0 || username === '' || password === '' ) {
      this.setState({ errors: true })
    } else
     if(this.props.tab === 0) {
      await this.props.updateUser(reqObjUpdate);
      if(this.props.updateUserSuccess) {
        window.location.reload();
      }
    } else {
      await this.props.createUser(reqObj);
      if(this.props.createUserSuccess) {
        window.location.reload();
      }
    }
  }

  render() {
    const { full_name, username, password, email, designation, gender, mobile, dob, country_code_mobile, selectedUserData,
      anchorEl, buttonName, tabStatus, errors, branches
    } = this.state;
    const { tab, userList, fullNameError, emailError, designationError, mobileError, genderError, branchesError, userNameError,
      passwordError, countryCodeError, centerList,
    } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>

        <Grid container direction='row' justify='center' spacing={2}>
          {
            tab === 0 ?
              <Grid item xs={3}>
                <Grid container justify='center'>
                  <Typography>Select user</Typography>
                </Grid>
              </Grid>
              : ''
          }

          {
            tab === 1 && tabStatus ? this.handleReset() : (tab === 0 && !tabStatus ? this.handleReset() : '')
          }

          {
            tab === 0 ?
              <Grid item xs={5}>
                <Button aria-controls="simple-menu" aria-haspopup="true" variant="outlined" fullWidth
                  onClick={e => this.setState({ anchorEl: e.currentTarget })} style={{ textTransform: 'none' }}
                >
                  {buttonName} <ArrowDropDownIcon></ArrowDropDownIcon>
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={e => this.setState({ anchorEl: null })}
                >
                  <MenuItem value={null} onClick={e => this.handleUserData(null)}>Select user to update</MenuItem>
                  {
                    userList.map((item, index) =>
                      <MenuItem value={item.user_id} key={index} onClick={e => this.handleUserData(item)}>
                        {item.username}
                      </MenuItem>
                    )
                  }
                </Menu>
              </Grid>
              : ''
          }

          {/* <Grid item xs={12}>
          <AddUser isUpdate={this.props.tab === 0 ? false : true}/>
        </Grid> */}
          <Grid item xs={12}>
            <Grid container direction='row' alignItems='center' spacing={2}>
              {/* <Grid item xs={12}>
              <Grid container justify='center'>
                <Grid item xs={8} className={clsx(classes.expansionPanelColor, classes.textAlign)}>
                  Your current active logins are <b>1</b>, and your maximum login limit is <b>5</b>
                </Grid>
                <Grid item xs={2} className={clsx(classes.expansionPanelColor, classes.textAlign)}>
                  <ListItem button>
                    <Link underline='none' color='primary'>
                      Buy more logins
                    </Link>
                  </ListItem>
                </Grid>
              </Grid>
            </Grid> */}

              {
                selectedUserData.length !== 0 || tab === 1 ?
                  <React.Fragment>
                    <Grid item xs={3}>
                      <Grid container justify='flex-end'>
                        <Typography>Name</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      <Select
                        fullWidth
                        margin='dense'
                        placeholder='Designation'
                        variant='outlined'
                        name='designation'
                        value={designation}
                        onChange={this.handleSelectChange}
                      >
                        {designationData.map(item => <MenuItem value={item.title} key={item.id}> {item.title} </MenuItem>)}
                      </Select>
                      {errors && designation === '' ? <div className={classes.error}>This field is required</div> : ''}
                      {designationError !== [] ? <div className={classes.error}>{designationError}</div> : ''}
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        name='full_name'
                        value={full_name}
                        onChange={this.handleTextChange}
                        fullWidth
                        variant='outlined'
                        margin='dense'
                        label='Enter Name'
                      />
                      {errors && full_name === '' ? <div className={classes.error}>This field is required</div> : ''}
                      {fullNameError !== [] ? <div className={classes.error}>{fullNameError}</div> : ''}
                    </Grid>

                    <Grid item xs={3}>
                      <Grid container justify='flex-end'>
                        <Typography>Username</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={7}>
                      <Grid container direction='row'>
                        <Grid item xs={2}>
                          <TextField
                            disabled
                            variant='outlined'
                            fullWidth
                            margin='dense'
                            inputProps={{
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                            className={clsx(classes.search, classes.expansionPanelColor)}
                            label='lucidatta'
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <TextField
                            name='username'
                            value={username}
                            onChange={this.handleTextChange}
                            variant='outlined'
                            fullWidth
                            margin='dense'
                            className={classes.search}
                            label='Enter preferred username'
                          />
                          {errors && username === '' ? <div className={classes.error}>This field is required</div> : ''}
                          {userNameError !== [] ? <div className={classes.error}>{userNameError}</div> : ''}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={3}>
                      <Grid container justify='flex-end'>
                        <Typography>Password</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={7}>
                      <TextField
                        name='password'
                        value={password}
                        onChange={this.handleTextChange}
                        type='password'
                        variant='outlined'
                        fullWidth
                        inputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        margin='dense'
                        label='Enter preferred password'
                      />
                      <Typography variant='body2'>* Password should contain at least one small letter, one capital letter,
                      one digit,one special character & greater than 8 characters length
                      </Typography>
                      {errors && password === '' ? <div className={classes.error}>This field is required</div> : ''}
                      {passwordError !== [] ? <div className={classes.error}>{passwordError}</div> : ''}
                    </Grid>

                    <Grid item xs={3}>
                      <Grid container justify='flex-end'>
                        <Typography>Email</Typography>
                    </Grid>
                    </Grid>
                    <Grid item xs={7}>
                      <TextField
                        name='email'
                        value={email}
                        onChange={this.handleTextChange}
                        variant='outlined'
                        fullWidth
                        margin='dense'
                        label='Enter email address'
                      />
                      {errors && email === '' ? <div className={classes.error}>This field is required</div> : ''}
                      {emailError !== [] ? <div className={classes.error}>{emailError}</div> : ''}
                    </Grid>

                    <Grid item xs={3}>
                      <Grid container justify='flex-end'>
                        <Typography>Gender</Typography>
                      </Grid>
                    </Grid>
                  <Grid item xs={7}>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="gender"
                      value={gender}
                      onChange={this.handleRadioButton}
                    >
                      <FormControlLabel
                        value='MALE'
                        checked={gender === 'MALE'}
                        control={<Radio color='primary' />}
                        label='Male'
                        labelPlacement='end'
                      />
                      <FormControlLabel
                        value='FEMALE'
                        checked={gender === 'FEMALE'}
                        control={<Radio color='primary' />}
                        label='Female'
                        labelPlacement='end'
                      />
                      <FormControlLabel
                        value='OTHER'
                        checked={gender === 'OTHER'}
                        control={<Radio color='primary' />}
                        label='Other'
                        labelPlacement='end'
                      />
                    </RadioGroup>
                    {errors && gender === '' ? <div className={classes.error}>This field is required</div> : ''}
                    {genderError !== [] ? <div className={classes.error}>{genderError}</div> : ''}
                    </Grid>

                    <Grid item xs={3}>
                      <Grid container justify='flex-end'>
                        <Typography>DOB</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={7}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          fullWidth
                          margin='dense'
                          inputVariant='outlined'
                          id="date-picker-dialog"
                          placeholder="Date picker"
                          format="dd/MM/yyyy"
                          value={dob}
                          onChange={this.handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={3}>
                      <Grid container justify='flex-end'>
                        <Typography>Contact Number</Typography>
                    </Grid>
                    </Grid>

                    <Grid item xs={7}>
                      <Grid container>
                        <Grid item xs={1}>
                          <FormControl variant='outlined' margin='dense' fullWidth>
                            <Select
                              fullWidth
                              variant='outlined'
                              margin='dense'
                              name='country_code_mobile'
                              value={country_code_mobile}
                              onChange={this.handleSelectChange}
                            >
                              {flags.map(flag =>
                                <MenuItem value={flag.dial_code} key={flag.code}>
                                  {flag.flag} {flag.name} {flag.dial_code}
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>
                          {errors && country_code_mobile === '' ? <div className={classes.error}>This field is required</div> : ''}
                          {countryCodeError !== [] ? <div className={classes.error}>{countryCodeError}</div> : ''}
                        </Grid>
                        <Grid item xs={11}>
                          <TextField
                            name='mobile'
                            value={mobile}
                            onChange={this.handleTextChange}
                            fullWidth
                            variant='outlined'
                            label='Enter contact Number'
                            margin='dense'
                          />
                          {errors && mobile === '' ? <div className={classes.error}>This field is required</div> : ''}
                          {mobileError !== [] ? <div className={classes.error}>{mobileError}</div> : ''}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={3}>
                      <Grid container justify='flex-end'>
                        <Typography>Select Branch</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={7}>
                      <Select
                        name='branches'
                        value={branches}
                        onChange={this.handleSelectChange}
                        multiple
                        fullWidth
                        margin='dense'
                        variant='outlined'
                      >
                        {
                          centerList.list.map(item => {
                            return (
                              item.parent_branch !== null && item.parent_branch > 0 ?
                              <MenuItem key={item.id} value={item.id}>
                                {item.branch_name}
                              </MenuItem> : null
                            )
                          })
                        }
                      </Select>
                      {errors && branches.length === 0 ? <div className={classes.error}>This field is required</div> : ''}
                      {branchesError !== [] ? <div className={classes.error}>{branchesError}</div> : ''}
                    </Grid>
                  </React.Fragment>
                  : ''
              }
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {this.handleExcessCode()}
          </Grid>
          <Grid item xs={12}>
            {this.props.tab === 0 ?

              <Grid container direction='row' justify='center' spacing={1}>
                <Grid item xs={2}>
                  <Button
                    variant='contained'
                    color='primary'
                    className={classes.buttonAccession}
                    fullWidth
                    onClick={this.handleSubmit}
                  >
                    Update
                </Button>
                </Grid>

                {/* <Grid item xs={1}>
                <Button
                  variant='contained'
                  color='secondary'
                  className={classes.buttonAccession}
                >
                  Delete
              </Button>
              </Grid> */}
              </Grid>
              :
              <Grid container direction='row' justify='center' spacing={1}>
                <Grid item xs={2}>
                  <Button
                    variant='contained'
                    color='primary'
                    className={classes.buttonAccession}
                    fullWidth
                    onClick={this.handleSubmit}
                  >
                    Create
                </Button>
                </Grid>

                {/* <Grid item xs={1}>
                <Button
                  variant='contained'
                  color='secondary'
                  className={classes.buttonAccession}
                >
                  Delete
            </Button>
              </Grid> */}
              </Grid>
            }
          </Grid>
        </Grid>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  createUserSuccess: state.login.createUserSuccess,
  updateUserSuccess: state.login.updateUserSuccess,
  userSearchList: state.login.userSearchList,
  fullNameError: state.login.errorMessageUser.fullNameError,
  designationError: state.login.errorMessageUser.designationError,
  emailError: state.login.errorMessageUser.emailError,
  mobileError: state.login.errorMessageUser.mobileError,
  genderError: state.login.errorMessageUser.genderError,
  countryCodeError: state.login.errorMessageUser.countryCodeError,
  userNameError: state.login.errorMessageUser.userNameError,
  passwordError: state.login.errorMessageUser.passwordError,
  branchesError: state.login.errorMessageUser.branchesError,
});

export default connect(mapStateToProps, {
  createUser,
  updateUser,
  searchUser,
})(AddUpdateUser);
