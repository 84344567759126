import React from 'react';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  Typography,
  Button,
  TextField,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Dialog,
  Tabs,
  Tab,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import OrganizationInfo from '../OrganizationInfo';
import TableHeadSort from '../../Common/TableHeadSort';

import { organizationList, searchOrganization, getPaymentModes } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class OrganizationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      branch_id: 1,
      organizationTab: 0,
      organizationDetailsTab: 0,
      dialog: false,
      rows: [],
      organisation: '',
      page: 0,
      rowsPerPage: 15,
      order: 'asc',
      orderBy: 'organisation_name',
      openSearch: false,
      anchorEl: [],
      editList: [],
    }
    this.handleOrganizationlist();
  }

  debounce = (func, delay) => { 
    let debounceTimer 
    return function() { 
        const context = this
        const args = arguments 
            clearTimeout(debounceTimer) 
                debounceTimer 
            = setTimeout(() => func.apply(context, args), delay) 
    } 
  }  

  handleOrganizationlist = async () => {
    await this.props.organizationList(this.state.branch_id)
    await this.props.getPaymentModes();
    this.setState({ rows: this.props.organization })
  }

  handleTextChange = (e) => {
      this.handleSearch( e.target.value)
      this.setState({ [e.target.name]: e.target.value });
  }

  handleSearch = this.debounce(async (value) => {
    if (value !== '') {
        await this.props.searchOrganization({ organisation: value })
        this.setState({ rows: this.props.searchList })
    } else {
      this.handleOrganizationlist();
    }
  }, 1500)

  handleTabChange = (event, newValue) => {
    this.setState({ organizationDetailsTab: newValue })
  }

  handleTabEvent = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  handleOrganizationTabChange = (event, newValue) => {
    this.setState({ organizationTab: newValue })
  }

  handleOrganizationTabEvent = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  handleAddOrganization = () => {
    const { organizationDetailsTab, dialog, editList } = this.state;
    return (
      <Dialog open={dialog} fullWidth maxWidth='md' classes={{ paper: classes.dialogHeight }}
      >
        <Container className={classes.textAlign} style={{ backgroundColor: '#eff0f1' }} maxWidth={false} classes={{root: classes.containerPadding}}>
          <Grid container direction='row' justify='center' alignItems='center'>
            <Grid item xs={10}>
              <Typography>Organization List</Typography>
            </Grid>
            <Grid>
              Current Due :-
            </Grid>
            <Grid item>
              <Grid container justify='flex-end'>
                <IconButton onClick={this.handleCloseDialog}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Tabs
                    value={organizationDetailsTab}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                  >
                    <Tab className={classes.buttonText} label="Organization Info" {...this.handleTabEvent(1)} />
                    <Tab className={classes.buttonText} label="Login & Access"  {...this.handleTabEvent(2)} />
                    <Tab className={classes.buttonText} label="Header & Footer"  {...this.handleTabEvent(3)} />
                  </Tabs>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {organizationDetailsTab === 0 || organizationDetailsTab === 1 || organizationDetailsTab === 2 ?
                <OrganizationInfo
                  editList={editList}
                  paymentModes={this.props.paymentModes}
                  handleCloseDialog={this.handleCloseDialog}
                  organizationTab={organizationDetailsTab}
                  handleOrgShiftTab= {e => this.handleTabChange(e, 1)}
                  handleLoginShiftTab= {e => this.handleTabChange(e, 2)}
                  handleLoginPrevious= {e => this.handleTabChange(e, 0)}
                  handleHeaderPrevious= {e => this.handleTabChange(e, 1)}
                  listUpdate = {this.handleOrganizationlist}
                /> : ''
              }
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    );
  }

  handleOpenDialog = () => {
    this.setState({ dialog: true, editList: [] })
  }

  handleCloseDialog = () => {
    this.setState({ dialog: false, organizationDetailsTab: 0 })
  }

  handleRequestSort = (e, property) => {                                                //Searching
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  }

  handleIconButtonClick = () => {
    this.setState({ openSearch: !this.state.openSearch })
  }

  handleOrgNameChange = (e) => {
    var updatedList = this.state.rows;

    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.organisation_name.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1;
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.organization })
    }
  }

  handleContactChange = (e) => {
    var updatedList = this.state.rows;

    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.contact_number !== null ? item.contact_number.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1 : '';
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.organization })
    }
  }

  handleRequestSort = (e, property) => {                                              //Sorting
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  }

  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  handleMenuOpen = (id, event) => {
    let { anchorEl } = this.state;
    anchorEl[id] = event.target;
    this.setState({ anchorEl });
  }

  handleOptionsClose = () => {
    this.setState({ anchorEl: [] })
  }

  handleEditDoctor = (row) => {
    this.setState({ dialog: true, editList: row, anchorEl: [] })
  }

  handleTable = () => {
    const { rows, page, rowsPerPage, order, orderBy, openSearch, anchorEl } = this.state;

    const headCells = [
      { id: "organisation_name", label: "Organization Name" },
      { id: "contact_number", label: "Contact Details" },
      { id: "bussinessSource", label: "Bussiness Source" },
      // { id: "label", label: "" },
      { id: "assignedList", label: "Assigned List" },
      { id: "ledgerBalance", label: "Ledger Balance" },
      { id: "currentDue", label: "Current Due" },
      { id: "all", label: "" }
    ];

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
      <Table aria-label="simple table">
        <TableHead style={{ minWidth: 650 }}>
          <TableRow>
            <TableHeadSort
              enableIcon
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              openSearch={openSearch}
              onRequestSort={this.handleRequestSort}
              handleButtonClick={this.handleIconButtonClick}
            />
          </TableRow>
          {openSearch ?
            <TableRow>
              <TableCell align="center">
                <TextField
                  margin='dense'
                  variant='outlined'
                  placeholder='Organization Name'
                  onChange={this.handleOrgNameChange}
                />
              </TableCell>
              <TableCell align="center">
                <TextField
                  margin='dense'
                  variant='outlined'
                  placeholder='Contact Details'
                  onChange={this.handleContactChange}
                />
              </TableCell>
              <TableCell align="center">
                <TextField
                  margin='dense'
                  variant='outlined'
                  placeholder='Bussiness Source'
                  onChange={this.handleBussinessChange}
                />
              </TableCell>
              {/* <TableCell align="center">
                <TextField
                  margin='dense'
                  variant='outlined'
                  placeholder=''
                  onChange={this.handleChange}
                />
              </TableCell> */}
              <TableCell align="center">
                <TextField
                  margin='dense'
                  variant='outlined'
                  placeholder='Assigned List'
                  onChange={this.handleAssignedListChange}
                />
              </TableCell>
              <TableCell align="center">
                <TextField
                  margin='dense'
                  variant='outlined'
                  placeholder='Ledger Balance'
                  onChange={this.handleLedgerBalanceChange}
                />
              </TableCell>
              <TableCell align="center">
                <TextField
                  margin='dense'
                  variant='outlined'
                  placeholder='Current Due'
                  onChange={this.handleCurrentDueChange}
                />
              </TableCell>
              <TableCell align="center">
                <TextField
                  margin='dense'
                  variant='outlined'
                  placeholder='All'
                  onChange={this.handleAllChange}
                />
              </TableCell>
            </TableRow>
            : null
          }
        </TableHead>
        <TableBody>
          {this.stableSort((rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows), this.getComparator(order, orderBy)).map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">{row.organisation_name}</TableCell>
                <TableCell align="center">{row.contact_number}</TableCell>
                <TableCell align="center"></TableCell>
                {/* <TableCell align="center"></TableCell> */}
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  <MoreVertIcon onClick={e => this.handleMenuOpen(row.id, e)}/>
                    <Menu
                      id={row.id}
                      anchorEl={anchorEl[row.id]}
                      keepMounted
                      open={Boolean(anchorEl[row.id])}
                      onClose={this.handleOptionsClose}
                    >
                      <MenuItem onClick={e => this.handleEditDoctor(row)}>Edit</MenuItem>
                      <MenuItem disabled>Disable</MenuItem>
                    </Menu>
                </TableCell>
              </TableRow>
            ))}
          {emptyRows > 0 && emptyRows === 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  }

  handlePageChange = (event, newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 })
  };

  render() {
    const { organizationTab, organisation, rows, page, rowsPerPage } = this.state;

    return (
      <Container maxWidth={false} classes={{root: classes.containerPadding}}>
        <Grid container direction='row' spacing={1}>
          <Grid item xs={6}>
            <Typography>
              Organization List
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container direction='row' justify='flex-end'>
              <Button variant='outlined' disabled color='primary' className={classes.buttonAccession}>
                Disabled Organizations
              </Button>&nbsp;&nbsp;
              <Button variant='outlined' disabled color='primary' className={classes.buttonAccession}>
                Export
              </Button>&nbsp;&nbsp;
              <Button
                variant='contained'
                color='primary'
                className={classes.buttonAccession}
                onClick={this.handleOpenDialog}
              >
                Add Organization
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name='organisation'
              value={organisation}
              onChange={this.handleTextChange}
              variant='outlined'
              fullWidth
              margin='dense'
              className={classes.search}
              placeholder="Search Organization Name/Contact Number..."
              inputProps={{ 'aria-label': 'search google maps' }}
            ><SearchIcon /></TextField>
          </Grid>
          <Grid item xs={6}>
            <TablePagination
              component="div"
              count={rows.length}
              rowsPerPageOptions={[5, 10, 15, 20]}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handlePageChange}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Grid>

          {/* <Grid item xs={12}>
            <Tabs
              value={organizationTab}
              onChange={this.handleOrganizationTabChange}
              indicatorColor="primary"
            >
              <Tab className={classes.buttonText} label="Walk-In"  {...this.handleOrganizationTabEvent(1)} />
              <Tab className={classes.buttonText} label="Prepaid"  {...this.handleOrganizationTabEvent(2)} />
              <Tab className={classes.buttonText} label="Postpaid(Credit)"  {...this.handleOrganizationTabEvent(3)} />
            </Tabs>
          </Grid> */}

          <Grid item xs={12}>
            {organizationTab === 0 ? this.handleTable() :
              (organizationTab === 1 ? this.handleTable() :
                (organizationTab === 2 ? this.handleTable() : ''))
            }
          </Grid>
          <Grid>
            {this.state.dialog ? this.handleAddOrganization() : ''}
          </Grid>

        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  organization: state.login.organizationList,
  searchList: state.login.searchList,
  paymentModes: state.login.paymentModes,
})

export default connect(mapStateToProps, {
  organizationList,
  searchOrganization,
  getPaymentModes,
})(OrganizationList);