import { FastForward } from '@material-ui/icons';
import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  errorMessage: '',               //Registration
  loading: false,
  error: false,
  activitylog: [],
  patientCount: '',
  profile: [],
  patientType: [],
  patientMobile: [],
  patientCurrentLocation: [],
  patientOtherLocation: [],
  organisation: [],
  referral: [],
  registerError: false,
  registerErrorMessage: [],
  errorMessages: {
    ageError: [],
    altMobileError: [],
    altCountryCodeError: [],
    mobileError: [],
    mobileCountryCodeError: [],
    designationError: [],
    emailError: [],
    genderError: [],
    organisationError: [],
    patientNameError: [],
    patientTypeError: [],
    referralError: []
  },

  patientBillingResponse: [],            //Billing
  patientIdBillingResponse: [],

  patientBillingSearch: [],
  testsData: [],

  pendingSamples: [],
  start: 0,                              //Accession
  receiveStatus: false,
  barCode: '',                           //Barcode Accession
  rejectedSamplesList: [],
  
  departmentsList: [],                   //Department Management
  searchDepartment: [],
  addDepartmentSuccess: false,
  updateDepartmentSuccess: false,
  errorMessagesDepartment: {
    departmentNameError: [],
    departmentCodeError: [],
    departmentDescriptionError: [],
  },

  billWiseTests: [],                     //BillWise Accession
  receiveAllStatus: false,

  accessedTests: [],

  createTestSuccess: false,              //Test Management
  testErrorMessage: {
    testNameError: [],
  },
  doctorList: [],                        //Doctor Management
  searchDoctorList: [],
  errorMessagesDoctor: {
    doctorNameError: [],
    contactNumberError: [],
    departmentError: [],
    doctorSpecialityError: [],
    userNameError: [],
  },
  addDoctorSuccess: false,
  editDoctorSuccess: false,
  errorMessagesUpdateDoctor: {
    userNameError: [],
  },

  organizationList: [],                  //Organization
  searchList: [],
  addOrganizationSuccess: false,
  errorMessagesOrganization: {
    organizationNameError: [],
    userNameError: [],
  },
  editOrganizationSuccess: [],
  errorMessagesUpdateOrganization: {
    organizationNameError: [],
    userNameError: [],
  },

  operationTests: [],                     //Operation
  testResults: [],
  getOperationSettings: [],
  updateOperationSettings: false,
  updateTestResultStatus: false,
  searchLists: [],                        //List Management
  addTestList: [],
  searchTest: [],
  createListSuccess: false,
  listNameError: [],
  getListNames: [],
  updateListSuccess: false,
  updateListErrors: {
    updateListNameError: [],
  },
  listPrices: [],
  updateListPricesSuccess: false,
  deleteListSuccess: false,

  referralSearchList: [],                  //Referral Management
  referralList: [],
  searchReferralList: [],
  addReferralSuccess: false,
  errorMessagesReferral: {
    referralNameError: [],
    referralSpecialityError: [],
    referralFinanceError: [],
    usernameError:[],
  },
  editReferralSuccess: [],

  testList: [],                            //Test
  addTestSuccess: false,
  addedTestId: 0,
  errorMessagesTest: {
    testNameError: [],
  },
  editTestSuccess: [],
  categoryList: [],
  testSampleTypesList: [],
  reportTypeList: [],
  createTestParamSuccess: false,
  errorCreateTestParam: {
    errors: [],
  },
  errorMessagesUpdateReferral: {
    userNameError: [],
  },

  updatePasswordSuccess: false,
  updatePasswordError: {
    currentPasswordError: [],
  },

  createCenterSuccess: false,                // Center Management
  createCenterError: {
    branchTypeError: [],
    branchNameError: [],
    emailError: [],
    mobileError: [],
    countryCodeError: [],
  },
  updateCenterSuccess: false,
  updateCenterError: {
    branchTypeError: [],
    branchNameError: [],
    emailError: [],
    mobileError: [],
    countryCodeError: [],
  },
  searchCenterList: [],
  getCenterList: false,
  
  billSettings: [],
  updatebillSettingsSuccess: false,

  createTestProfileSuccess: false,                // Profile Management 
  testProfileList: [],
  updateTestProfileSuccess: false,
  searchTestProfileList: [],
  profileErrorMessages: {
    profileNameError: [],
  },
  billList: [],                             // Bill Settlement
  bill: [],
  searchBillList: [],
  addTestToBillSuccess: false,
  searchBillByBillId: [],
  patientWiseSearchList: [],
  patientNameWiseSearchList: [],
  accessionWiseSearchList: [],
  updateBillPaymentCompletedSuccess: false,
  updateBillSuccess: false,
  cancelBillSuccess: false,
  billsPaymentSuccess: false,
  
  testSampleTypeList: [],                    // Sample Management
  addTestSampleSuccess: false,
  sampleTypeList: [],
  sampleContainerList: [],
  errorMessageCreateSample: {
    sampleNameError: [],
    containerIDError: [],
  },
  updateTestSampleSuccess: false,
  errorMessageUpdateSample: {
    sampleNameError: [],
    sampleIDError: [],
    containerIDError: [],
    testBarcodeError: [],
  },
  deleteTestSampleSuccess: false,

  createOutSourceCenterSuccess: false,                // out source Management
  outsourceList: [],                          //Outsource Management
  permissionsList: [],
  createUserSuccess: false,
  updateUserSuccess: false,
  userSearchList: [],
  userList: [],
  errorMessageUser: {
    designationError: [],
    fullNameError: [],
    emailError: [],
    mobileError: [],
    genderError: [],
    branchesError: [],
    userNameError: [],
    passwordError: [],
    countryCodeError: [],
  },
  
  createBatchSuccess: false,                     //Batch-Management
  errorMessageCreateBatch: {
    batchRefNumberError: [],
    collectPersonError: [],
  },
  batchValidationSuccess: false,
  validatedBatchTests: [],
  batchList: [],
  updateSampleToRejectStatus: false,
  updateSampleToReceiveStatus: false,
  batchLogistic: [],
  outsourceCenterList: [],
  outsourceCenterSearchList : [],

  paymentModes: [],
}

export default function (state = initialState, action) {
  const { type, payload } = action;

  let payloadinfo = {}
  let response = {}
  if (typeof payload !== 'undefined' && typeof payload.data !== 'undefined') {
    payloadinfo = payload.data;
    if (typeof payload.data.data !== 'undefined') {
      response = payloadinfo.data;
    }
  }

  switch (type) {
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        errorMessage: payloadinfo.message,
        error: (!payloadinfo.status),
      }

    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
      }

    case actionTypes.LOGINCONFIRM_SUCCESS:
      return {
        ...state,
      }

    case actionTypes.ACTIVITYLOG_SUCCESS:
      return {
        ...state,
        activitylog: response
      }

    case actionTypes.PROFILE_SUCCESS:
      return {
        ...state,
        profile: response,
      }

    case actionTypes.PATIENT_COUNT_SUCCESS:
      return {
        ...state,
        patientCount: response,
      }

    case actionTypes.PATIENT_TYPE_SUCCESS:
      return {
        ...state,
        patientType: response
      }

    case actionTypes.PATIENT_MOBILE_SUCCESS:
      return {
        ...state,
        patientMobile: response
      }

    case actionTypes.PATIENT_NAME_SUCCESS:
      return {
        ...state,
        patientCurrentLocation: response.current,
        patientOtherLocation: response.other,
      }

    case actionTypes.PATIENT_ORGANISATION_SUCCESS:
      return {
        ...state,
        organisation: response
      }

    case actionTypes.PATIENT_REFERRAL_SUCCESS:
      return {
        ...state,
        referral: response
      }

    case actionTypes.REGISTRATION_SUCCESS:
      return {
        ...state,
        registerError: !payloadinfo.status,
        registerErrorMessage: payloadinfo.message,
        errorMessages: {
          ageError: response.age,
          altMobileError: response.alternative_mobile,
          altCountryCodeError: response.country_code_alt_mobile,
          mobileError: response.mobile,
          mobileCountryCodeError: response.country_code_mobile,
          designationError: response.designation,
          emailError: response.email,
          genderError: response.gender,
          organisationError: response.organisation,
          patientNameError: response.patient_name,
          patientTypeError: response.patient_type,
          referralError: response.referral,
        }
      }

    case actionTypes.UPDATION_SUCCESS:
      return {
        ...state,
        registerError: !payloadinfo.status,
        registerErrorMessage: payloadinfo.message,
        errorMessages: {
          ageError: response.age,
          altMobileError: response.alternative_mobile,
          altCountryCodeError: response.country_code_alt_mobile,
          mobileError: response.mobile,
          mobileCountryCodeError: response.country_code_mobile,
          designationError: response.designation,
          emailError: response.email,
          genderError: response.gender,
          organisationError: response.organisation,
          patientNameError: response.patient_name,
          patientTypeError: response.patient_type,
          referralError: response.referral,
        }
      }

    case actionTypes.PATIENT_MOBILE_BILLING_SUCCESS:
      return {
        ...state,
        patientBillingResponse: response,
      }

    case actionTypes.PATIENT_ID_BILLING_SUCCESS:
      return {
        ...state,
        patientIdBillingResponse: response,
      }

    case actionTypes.GET_PATIENT_BILLING_SEARCH_SUCCESS:
      return {
        ...state,
        patientBillingSearch: response,
      }

    case actionTypes.GET_TEST_PRICES:
      return {
        ...state,
        testsData: response,
      }

    case actionTypes.PATIENT_BILLING_SUCCESS:
      return {
        ...state,
      };

    case actionTypes.GET_PENDING_SAMPLES:
      var duplicateObject = [];
      if (state.pendingSamples && state.pendingSamples.length && state.start !== 0) {
        state.pendingSamples.forEach(sample => {
          duplicateObject.push(sample);
        })
        response.forEach(res => {
          duplicateObject.push(res);
        });
      } else {
        duplicateObject = response;
      }
      return {
        ...state,
        pendingSamples: duplicateObject
      }

    case actionTypes.GET_REJECTED_SAMPLES:
      return {
        ...state,
        rejectedSamplesList: response
      }

    case actionTypes.GET_DEPARTMENTS_LIST:
      return {
        ...state,
        departmentsList: response,
      }

    case actionTypes.SEARCH_DEPARTMENT_LIST:
      return {
        ...state,
        searchDepartment: response,
      }

    case actionTypes.ADD_DEPARTMENT_SUCCESS:
      return {
        ...state,
        addDepartmentSuccess: payloadinfo.status,
        errorMessagesDepartment: {
          departmentNameError: response.department_name,
          departmentCodeError: response.department_code,
          departmentDescriptionError: response.department_description,
        }
      }

    case actionTypes.UPDATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        updateDepartmentSuccess: payloadinfo.status,
      }

    case actionTypes.BAR_CODE_SUCCESS:
      return {
        ...state,
        barCode: response,
      }

    case actionTypes.UPDATE_TEST_RECEIVE:
      return {
        ...state,
        receiveStatus: payloadinfo.status,
      }

    case actionTypes.GET_BILLWISE_TESTS:
      return {
        ...state,
        billWiseTests: response,
      }

    case actionTypes.UPDATE_BILLWISE_RECEIVE_ALL:
      return {
        ...state,
        receiveAllStatus: response,
      }

    case actionTypes.GET_ACCESSED_TESTS:
      return {
        ...state,
        accessedTests: response,
      }

    case actionTypes.TEST_CREATION_SUCCESS:
      return {
        ...state,
        createTestSuccess: payloadinfo.status,
        addedTestId: payloadinfo.data ? payloadinfo.data.testref : 0,
        testErrorMessage: {
          testNameError: response.test_name
        }
      }
      
    case actionTypes.ADD_ORGANIZATION_SUCCESS:
      console.log(response)
      return {
        ...state,
        addOrganizationSuccess: payload.data.status,
        errorMessagesOrganization: {
          organizationNameError: response.organisation_name,
          userNameError: response.username,
        }
      }

    case actionTypes.ORGANIZATION_LIST_SUCCESS:
      return {
        ...state,
        organizationList: response,
      }

    case actionTypes.SEARCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        searchList: response,
      }

    case actionTypes.EDIT_ORGANIZATION_SUCCESS:
      return {
        ...state,
        editOrganizationSuccess: payload.data.status,
        errorMessagesUpdateOrganization: {
          organizationNameError: response.organisation_name,
          userNameError: response.username,
        }
      }

    case actionTypes.GET_OPERATION_TESTS_SUCCESS:
      return {
        ...state,
        operationTests: response,
      }

    case actionTypes.GET_OPERATION_SETTINGS:
      return {
        ...state,
        getOperationSettings: response,
      }

    case actionTypes.UPDATE_OPERATION_SETTINGS:
      return {
        ...state,
        updateOperationSettings: payloadinfo.status,
      }
    
    case actionTypes.DOCTOR_LIST_SUCCESS:
      return {
        ...state,
        doctorList: response,
      }

    case actionTypes.GET_TEST_RESULTS:
      return {
        ...state,
        testResults: response,
      }

    case actionTypes.UPDATE_TEST_RESULT:
      return {
        ...state,
        updateTestResultStatus: payloadinfo.status,
      }

    case actionTypes.GET_BILL_SETTINGS:
      return {
        ...state,
        billSettings: response,
      }

    case actionTypes.UPDATE_BILL_SETTINGS:
      return {
        ...state,
        updatebillSettingsSuccess: payloadinfo.status,
      }
      
    case actionTypes.SEARCH_LIST_SUCCESS:
      return {
        ...state,
        searchLists: response,
      }

    case actionTypes.CREATE_LIST_SUCCESS:
      return {
        ...state,
        createListSuccess: payloadinfo.status,
        listNameError: response.list_name
      }

    case actionTypes.GET_TEST_LIST_SUCCESS:
      return {
        ...state,
        addTestList: response
      }

    case actionTypes.SEARCH_TEST_SUCCESS:
      return {
        ...state,
        searchTest: response
      }

    case actionTypes.UPDATE_LIST_PRICES_SUCCESS:
      return {
        ...state,
        updateListPricesSuccess: payloadinfo.status,
      }

    case actionTypes.UPDATE_LIST_SUCCESS:
      return {
        ...state,
        updateListSuccess: payloadinfo.status,
        updateListErrors: {
          updateListNameError: response.list_name,
        }
      }

    case actionTypes.DELETE_LIST_SUCCESS:
      return {
        ...state,
        deleteListSuccess: payloadinfo.status,
      }

    case actionTypes.GET_LIST_SUCCESS:
      return {
        ...state,
        getListNames: response,
      }

    case actionTypes.GET_LIST_PRICES_SUCCESS:
      return {
        ...state,
        listPrices: response,
      }

    case actionTypes.ADD_REFERRAL_SUCCESS:
      return {
        ...state,
        addReferralSuccess: payloadinfo.status,
        errorMessagesReferral: {
          referralNameError: response.referrer_name,
          referralSpecialityError: response.referral_speciality,
          referralFinanceError: response.is_finance_manangement_enable,
          usernameError: response.username,
        }
      }

    case actionTypes.REFERRAL_LIST_SUCCESS:
      return {
        ...state,
        referralList: response,
      }

    case actionTypes.SEARCH_REFERRAL_SUCCESS:
      return {
        ...state,
        searchReferralList: response,
      }

    case actionTypes.EDIT_REFERRAL_SUCCESS:
      return {
        ...state,
        editReferralSuccess: payload.data.status,
        errorMessagesUpdateReferral: {
          userNameError: response.username,
        }
      }

    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfileSuccess: payloadinfo.status
      }
    
    case actionTypes.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        updatePasswordSuccess: payloadinfo.status,
        updatePasswordError: {
          currentPasswordError: response.current_password,
        }
      }

    case actionTypes.SEARCH_DOCTOR_SUCCESS:
      return {
        ...state,
        searchDoctorList: response,
      }

    case actionTypes.ADD_DOCTOR_SUCCESS:
      return {
        ...state,
        addDoctorSuccess: payload.data.status,
        errorMessagesDoctor: {
          doctorNameError: response.doctor_name,
          contactNumberError: response.contact_number,
          departmentError: response.department,
          doctorSpecialityError: response.doctor_speciality,
          userNameError: response.username,
        }
      }

    case actionTypes.EDIT_DOCTOR_SUCCESS:
      return {
        ...state,
        editDoctorSuccess: payload.data.status,
        errorMessagesUpdateDoctor: {
          userNameError: response.username,
        }
      }

    case actionTypes.ADD_TEST_SUCCESS:
      return {
        ...state,
        addTestSuccess: payload.data.status,
        errorMessagesTest: {
          testNameError: response.test_name,
        }
      }

    case actionTypes.EDIT_TEST_SUCCESS:
      return {
        ...state,
        editTESTSuccess: payload.data.status,
      }
    
    case actionTypes.GET_CATEGORY_LIST:
      return {
        ...state,
        categoryList: response,
      }

    case actionTypes.GET_TEST_SAMPLE_TYPES_LIST:
      return {
        ...state,
        testSampleTypesList: response,
      }

    case actionTypes.GET_REPORT_TYPE_LIST:
      return {
        ...state,
        reportTypeList: response,
      }
    
    case actionTypes.CREATE_TEST_PARAM_SUCCESS:
      return{
        ...state,
        createTestParamSuccess: payload.data.status,
        errorCreateTestParam: {
          errors: response,
        }
      }
    
    case actionTypes.GET_BILL_LIST_SUCCESS:
      return {
        ...state,
        billList: response,
      }

    case actionTypes.GET_BILL_SUCCESS:
      return {
        ...state,
        bill: response,
      }

    case actionTypes.SEARCH_BILL_ID_SUCCESS:
      return {
        ...state,
        searchBillList: response,
      }

    case actionTypes.ADD_TEST_TO_BILL:
      return {
        ...state,
        addTestToBillSuccess: response,
      }

    case actionTypes.SEARCH_BILL_BY_BILL_ID:
      return {
        ...state,
        searchBillByBillId: response
      }

    case actionTypes.GET_BILL_BY_PATIENT_ID:
      return {
        ...state,
        patientWiseSearchList: response,
      }

    case actionTypes.SEARCH_BILL_BY_PATIENT_NAME:
      return {
        ...state,
        patientNameWiseSearchList: response,
      }

    case actionTypes.SEARCH_BILL_BY_ACCESSION:
      return {
        ...state,
        accessionWiseSearchList: response,
      }

    case actionTypes.UPDATE_BILL_PAYMENT_COMPLETED:
      return {
        ...state,
        updateBillPaymentCompletedSuccess: response,
      }

    case actionTypes.UPDATE_BILL_SUCCESS:
      return {
        ...state,
        updateBillSuccess: response,
      }

    case actionTypes.CANCEL_BILL_SUCCESS:
      return {
        ...state,
        cancelBillSuccess: response,
      }

    case actionTypes.UPDATE_BILLS_PAYMENT:
      return {
        ...state,
        billsPaymentSuccess: response,
      }
    
    case actionTypes.ADD_TEST_PROFILE_SUCCESS:
      return {
        ...state,
        createTestProfileSuccess: response,
      }

    case actionTypes.GET_TEST_PROFILE_LIST:
      return {
        ...state,
        testProfileList: response,
      }

    case actionTypes.UPDATE_TEST_PROFILE_SUCCESS:
      return {
        ...state,
        updateTestProfileSuccess: response,
        profileErrorMessages: {
          profileNameError: response.test_name !== undefined ? response.test_name : [],
        }
      }

    case actionTypes.SEARCH_TEST_PROFILE_SUCCESS:
      return {
        ...state,
        searchTestProfileList: response,
      }

    case actionTypes.GET_TEST_SAMPLE_TYPE_LIST:
      return {
        ...state,
        testSampleTypeList: response,
      }

    case actionTypes.CREATE_TEST_SAMPLE_TYPE:
      return {
        ...state,
        addTestSampleSuccess: payload.data.status,
        errorMessageCreateSample: {
          sampleNameError: response.sample_name,
          containerIDError: response.container_id,
        }
      }

    case actionTypes.UPDATE_TEST_SAMPLE_TYPE:
      return {
        ...state,
        updateTestSampleSuccess: payload.data.status,
        errorMessageUpdateSample: {
          sampleNameError: response.test_sample_name,
          sampleIDError: response.sample_id,
          containerIDError: response.container_id,
          testBarcodeError: response.test_barcode_gen_format_type,
        }
      }

    case actionTypes.DELETE_TEST_SAMPLE_TYPE:
      return {
        ...state,
        deleteTestSampleSuccess: payload.data.status,
      }
      
    case actionTypes.GET_SAMPLE_TYPE_LIST:
      return {
        ...state,
        sampleTypeList: response,
      }

    case actionTypes.GET_SAMPLE_CONTAINER_LIST:
      return {
        ...state,
        sampleContainerList: response,
      }

    case actionTypes.CREATE_BRANCH_SUCCESS:
      return {
        ...state,
        createCenterSuccess: payload.data.status,
        createCenterError: {
          branchTypeError: response.branch_type,
          branchNameError: response.branch_name,
          emailError: response.email,
          mobileError: response.mobile,
          countryCodeError: response.country_code_mobile,
        }
      }

    case actionTypes.GET_BRANCH_LIST:
      return {
        ...state,
        getCenterList: response,
      }

    case actionTypes.UPDATE_BRANCH_SUCCESS:
      return {
        ...state,
        updateCenterSuccess: payload.data.status,
        updateCenterError: {
          branchTypeError: response.branch_type,
          branchNameError: response.branch_name,
          emailError: response.email,
          mobileError: response.mobile,
          countryCodeError: response.country_code_mobile,
        }
      }
      
    case actionTypes.SEARCH_BRANCH_SUCCESS:
      return {
        ...state,
        searchCenterList: response,
      }
    
    case actionTypes.CREATE_OUTSOURCE_CENTER:
      return {
        ...state,
        createOutSourceCenterSuccess: payload.data.status
      }

    case actionTypes.GET_OUTSOURCE_LIST:
      return {
        ...state,
        outsourceList: response
      }

    case actionTypes.SEARCH_OUTSOURCE_CENTER:
      return {
        ...state,
        outsourceCenterSearchList: response
      }
      
    case actionTypes.GET_PERMISSIONS_LIST:
      return {
        ...state,
        permissionsList: response
      }

    case actionTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserSuccess: payload.data.status,
        errorMessageUser: {
          fullNameError: response.full_name,
          designationError: response.designation,
          emailError: response.email,
          mobileError: response.mobile,
          genderError: response.gender,
          countryCodeError: response.country_code_mobile,
          userNameError: response.username,
          passwordError: response.password,
          branchesError: response.branches,
        }
      }

    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserSuccess: payload.data.status,
        errorMessageUser: {
          fullNameError: response.full_name,
          designationError: response.designation,
          emailError: response.email,
          mobileError: response.mobile,
          genderError: response.gender,
          countryCodeError: response.country_code_mobile,
          userNameError: response.username,
          passwordError: response.password,
          branchesError: response.branches,
        }
      }

    case actionTypes.SEARCH_USER_SUCCESS:
      return {
        ...state,
        userSearchList: response,
      }

    case actionTypes.GET_USER_LIST:
      return {
        ...state,
        userList: response,
      }
      
    case actionTypes.GET_OUTSOURCE_CENTER_LIST:
      return {
        ...state,
        outsourceCenterList: response,
      }
      
    case actionTypes.CREATE_BATCH_SUCCESS:
      return {
        ...state,
        createBatchSuccess: payload.data.status,
        errorMessageCreateBatch: {
          batchRefNumberError: response.batch_ref_number,
          collectPersonError: response.collect_person,
        }
      }

    case actionTypes.VALIDATE_BATCH_TESTS:
      return {
        ...state,
        validatedBatchTests: response,
        batchValidationSuccess: payload.data.status,
      }

    case actionTypes.GET_BATCH_LIST:
      return {
        ...state,
        batchList: response,
      }

    case actionTypes.UPDATE_SAMPLE_TO_REJECT:
      return {
        ...state,
        updateSampleToRejectStatus: payload.data.status,
      }

    case actionTypes.UPDATE_SAMPLE_TO_RECEIVE:
      return {
        ...state,
        updateSampleToReceiveStatus: payload.data.status,
      }

    case actionTypes.GET_BATCH_LOGISTICS:
      return {
        ...state,
        batchLogistic: response,
      }

    case actionTypes.GET_PAYMENT_MODES:
      return {
        ...state,
        paymentModes: response,
      }

    default:
      return state;
  }
}