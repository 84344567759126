import React from 'react';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Divider,
  MenuItem,
  Button,
} from '@material-ui/core';

import { ValidatorForm } from 'react-material-ui-form-validator';
import { createtestparameter } from '../../../Store/ActionCreators';
import classes from '../../../App.module.css';

class TestListValues extends React.Component {
  constructor (props) {
    super(props); 
    this.state = {
      parameter_name: '',
      parameterCode: '',
      parameter_unit: '',
      method: '',
      integrationCode: '',
      positionPdf: 1,
      male_min_range: '',
      male_max_range: '',
      descriptive_male_range: '',
      female_min_range: '',
      female_max_range: '',
      descriptive_female_range: '',
      values: '',
      is_highlight: '0',
      added_values: 1,
      dictionary: '',
      added_values_list: [],
      is_sms_doctor: '0',
      is_email_doctor: '0',
      is_highlight_value: '0',
      is_underline_value: '0',
      is_non_editable_field: '0',
      is_optional_field: '0',
      is_break_line: '0',
      is_impressions: '0',
      report_name: 'Test_With_List_Values',
      report_type: this.props.reportType,
      createAnother: false,
      test_id: this.props.testRef
    }
    this.reset = this.state;
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleValueChange = (e) => {
    this.setState({ [e.target.name]: e.target.value ,values: e.target.value })
  }

  handleSaveChanges = async(e) => {
    var obj = {
      test_id: this.state.test_id,
      parameter_info: {}
    }
    Object.keys(this.state).forEach(item => {
      if(item !== 'test_id' && item !== 'createAnother' && item !== 'added_values'){
        obj.parameter_info[item] = this.state[item];
      }
    })
    await this.props.createtestparameter(obj);
    if(this.props.createTestParamSuccess){
      if(this.state.createAnother){
        this.setState(this.reset);
      } else {
        this.props.history.push({pathname: '/reportlist'})
      }
    }
  }

  handleCheckBox = (e) => {
    this.setState({ [e.target.name]: e.target.checked === true ? '1' : '0' })
  }

  handleAddValue = () => {
    let values = this.state.added_values_list;
    values.push(this.state.values)
    this.setState({added_values_list: values, values: ''});
  }

  handleDeleteValue = () => {
    if(this.state.values){
      let index = this.state.added_values_list.indexOf(this.state.values);
      if( index > -1 && this.state.added_values_list !== 1){
        let values = this.state.added_values_list;
        values.splice(index, 1);
        this.setState({added_values: 1});
        this.setState({added_values_list: values, values: ''})
      }
    }
  }

  render() {
    const { parameter_name, parameterCode, method, integrationCode, positionPdf, male_min_range, male_max_range, descriptive_male_range,
      female_min_range, female_max_range, descriptive_female_range, values, is_highlight, added_values, dictionary, is_sms_doctor, is_email_doctor,
      is_highlight_value, is_underline_value, is_non_editable_field, is_optional_field, is_break_line, is_impressions, parameter_unit, createAnother,
      added_values_list
    } = this.state;
    return (
      <ValidatorForm onSubmit={this.handleSaveChanges}>
         <Container>
            <Grid container direction='row' justify='center' alignItems='center' spacing={1}>
            <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                    <Typography variant="h4">Test with List of Values</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>Name:</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      name='parameter_name'
                      value={parameter_name}
                      onChange={this.handleChange}
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      placeholder="Enter the value name"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>Unit:</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      name='parameter_unit'
                      value={parameter_unit}
                      onChange={this.handleChange}
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      placeholder="Enter the Primary value unit"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>Method:</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      name='method'
                      value={method}
                      onChange={this.handleChange}
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      placeholder="Enter the method by which test is conducted"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>Code:</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      name='parameterCode'
                      value={parameterCode}
                      onChange={this.handleChange}
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      placeholder="Enter the Primary Code"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>Integration Code:</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      name='integrationCode'
                      value={integrationCode}
                      onChange={this.handleChange}
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      placeholder="Integration Code"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>Position in PDF:</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      name='positionPdf'
                      value={positionPdf}
                      onChange={this.handleChange}
                      fullWidth
                      placeholder='Position in PDF'
                      variant='outlined'
                      margin='dense'
                    >
                      <MenuItem value={1} key={1}> {1} </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider/>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>Male Range:</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name='male_min_range'
                      value={male_min_range}
                      onChange={this.handleChange}
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      placeholder="Enter lower range (Male)"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Grid container justify='center'>
                      <Typography>-</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name='male_max_range'
                      value={male_max_range}
                      onChange={this.handleChange}
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      placeholder="Enter upper range (Male)"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>Descriptive Male Range:</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      multiline
                      name='descriptive_male_range'
                      value={descriptive_male_range}
                      onChange={this.handleChange}
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      placeholder="Enter descriptive male range"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>Female Range:</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name='female_min_range'
                      value={female_min_range}
                      onChange={this.handleChange}
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      placeholder="Enter lower range (Female)"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Grid container justify='center'>
                      <Typography>-</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name='female_max_range'
                      value={female_max_range}
                      onChange={this.handleChange}
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      placeholder="Enter upper range (Female)"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>Descriptive Female Range:</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      multiline
                      name='descriptive_female_range'
                      value={descriptive_female_range}
                      onChange={this.handleChange}
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      placeholder="Enter descriptive female range"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>Enter Values:</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      multiline
                      name='values'
                      value={values}
                      onChange={this.handleChange}
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      placeholder="Enter value and click add value till all values are entered (Entering # not allowed)"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Grid container justify='center'>
                      <FormControlLabel
                        name='is_highlight'
                        value={is_highlight}
                        checked={true ?  is_highlight === '1' : false}
                        control={<Checkbox color='primary' />}
                        label='Highlight'
                        labelPlacement='end'
                        onClick={this.handleCheckBox}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container justify='center'>
                  <Button
                    color='primary'
                    variant='contained'
                    className={classes.buttonText}
                    onClick={e => this.handleAddValue()}
                  >
                    Add Value
                  </Button>&nbsp;&nbsp;
                  <Button
                    color='secondary'
                    variant='contained'
                    className={classes.buttonText}
                    onClick={e => this.handleDeleteValue()}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
              
              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      select
                      name='added_values'
                      value={added_values}
                      onChange={this.handleValueChange}
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      placeholder='Enter Width of Graph'
                    >
                      <MenuItem value={1}>Added list values come here</MenuItem>
                      {
                        added_values_list.map((item, index) => {
                          return(<MenuItem value={item} key={index}>{item}</MenuItem>);
                        })
                      }
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>*Note:</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography>The list of values will not be saved until 'Save Value' button is clicked (bottom right-side)</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>Enter Dictionary:</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      name='dictionary'
                      value={dictionary}
                      onChange={this.handleChange}
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      placeholder='Enter Dictionary Name'
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider/>
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  name='is_sms_doctor'
                  value={is_sms_doctor}
                  checked={true ?  is_sms_doctor === '1' : false}
                  control={<Checkbox color='primary' />}
                  label='SMS to doctor'
                  labelPlacement='end'
                  onClick={this.handleCheckBox}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  name='is_email_doctor'
                  value={is_email_doctor}
                  checked={true ? is_email_doctor === '1' : false}
                  control={<Checkbox color='primary' />}
                  label='Email to doctor'
                  labelPlacement='end'
                  onClick={this.handleCheckBox}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  name='is_highlight_value'
                  value={is_highlight_value}
                  checked={true ? is_highlight_value === '1' : false}
                  control={<Checkbox color='primary' />}
                  label='Highlight this value'
                  labelPlacement='end'
                  onClick={this.handleCheckBox}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  name='is_underline_value'
                  value={is_underline_value}
                  checked={true ? is_underline_value === '1' : false}
                  control={<Checkbox color='primary' />}
                  label='Underline this value'
                  labelPlacement='end'
                  onClick={this.handleCheckBox}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  name='is_non_editable_field'
                  value={is_non_editable_field}
                  checked={true ? is_non_editable_field === '1' : false}
                  control={<Checkbox color='primary' />}
                  label='Non-editable field'
                  labelPlacement='end'
                  onClick={this.handleCheckBox}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  name='is_optional_field'
                  value={is_optional_field}
                  checked={true ? is_optional_field === '1' : false}
                  control={<Checkbox color='primary' />}
                  label='Optional field'
                  labelPlacement='end'
                  onClick={this.handleCheckBox}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  name='is_break_line'
                  value={is_break_line}
                  checked={true ? is_break_line === '1' : false}
                  control={<Checkbox color='primary' />}
                  label='Break line'
                  labelPlacement='end'
                  onClick={this.handleCheckBox}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  name='is_impressions'
                  value={is_impressions}
                  checked={true ? is_impressions === '1' : false}
                  control={<Checkbox color='primary' />}
                  label='Has Impressions'
                  labelPlacement='end'
                  onClick={this.handleCheckBox}
                />
              </Grid>
              <Grid item xs={12}>
              <Grid container direction='row' justify="flex-end" alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <FormControlLabel
                    name='createAnother'
                    value={createAnother}
                    checked={createAnother ? true : false}
                    control={<Checkbox color='primary' />}
                    label='Create Another'
                    labelPlacement='end'
                    onClick={this.handleCheckBox}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    type="submit"
                    color="primary"
                    size="medium"
                    variant="contained"
                    className={classes.buttonText}
                    onSubmit={this.handleSaveChanges}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            </Grid>
          </Container>  
   
      </ValidatorForm>
      )
  }
}

const mapStateToProps = state => ({
  createTestParamSuccess: state.login.createTestParamSuccess
})

export default connect(mapStateToProps, {
  createtestparameter
})(TestListValues);